import React, { useState, useEffect } from "react";
import { ReactTyped } from "react-typed";
import PopUp from "../components/PopUp";
import BrandListCompact from "../components/BrandListCompact";
import { tieBreaker, searchPageGen } from "../custom/functions";
import { IngredientEyeSVG, LinkSVG } from "../custom/svg";
import podiumGlyph from "../images/podiumglyph.png";
import { LiveIndicator } from "../custom/animations";
import { Helmet } from "react-helmet-async";
import AdPopUp from "../components/AdPopUp";
import {
  BackDiv,
  BackButton,
  BackText,
  ResponseDiv,
  ResponseTitle,
  ResponseSubtitle,
  SvgWrapper,
  PodiumDivMobile,
  FilterButtonDiv,
  ShowMoreButton,
  PodiumGlyphImg,
  TabBar,
} from "../css/examplePage";
import FanPopUp from "../components/FanPopUp";

const CreatineExample = (props) => {
  const [exampleLoading, setExampleLoading] = useState(true);
  const [exampleOne, setExampleOne] = useState([]);
  const [exampleTwo, setExampleTwo] = useState([]);
  const [exampleThree, setExampleThree] = useState([]);
  const [exampleFour, setExampleFour] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupIngredients, setPopupIngredients] = useState(null);
  const [popupIngredientList, setPopupIngredientList] = useState(null);

  const closePopup = () => setPopupOpen(false);

  const handlePopUp = (ingredients, ingredientList) => {
    setPopupOpen(true);
    setPopupIngredients(ingredients);
    setPopupIngredientList(ingredientList);
  };

  useEffect(() => {
    if (!props.userCountry) return;

    const fetchExampleData = (url) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.ok) {
            const data = await response.json();
            resolve(data);
          } else {
            reject(new Error(`HTTP error! status: ${response.status}`));
          }
        } catch (error) {
          reject(error);
        }
      });
    };

    const fetchAllExamples = (userCountry) => {
      let fetchOne;
      let fetchTwo;
      let fetchThree;
      let fetchFour;

      if (userCountry === "GB") {
        fetchOne = fetchExampleData(
          `/api/creatine?filter=micronized&region=` + userCountry
        );
        fetchTwo = fetchExampleData(
          `/api/creatine?filter=monohydrate&region=` + userCountry
        );
        fetchThree = fetchExampleData(
          `/api/creatine?patent=true&region=` + userCountry
        );
      } else {
        fetchOne = fetchExampleData(
          `/api/creatine?filter=micronized&region=` + userCountry
        );
        fetchTwo = fetchExampleData(
          `/api/creatine?filter=monohydrate&region=` + userCountry
        );
        fetchThree = fetchExampleData(
          `/api/creatine?patent=true&region=` + userCountry
        );
        fetchFour = fetchExampleData(
          `/api/creatine?filter=hcl&region=` + userCountry
        );
      }

      setExampleLoading(true);

      Promise.all([fetchOne, fetchTwo, fetchThree, fetchFour])
        .then(([dataOne, dataTwo, dataThree, dataFour]) => {
          setExampleOne(dataOne);
          setExampleTwo(dataTwo);
          setExampleThree(dataThree);
          setExampleFour(dataFour);
          setExampleLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchAllExamples(props.userCountry);
  }, [props.userCountry]);

  return (
    <center>
      <Helmet>
        <title>Creatine Ranking Examples</title>
        <meta
          name="description"
          content="Discover the best value Creatine with our value ranking system. Whether you want micronized, hcl, or regular monohydrate, our rankings highlight top Creatine based on value."
        />
        <meta
          name="keywords"
          content="best creatine, creatine rankings, creatine supplement, creatine value, creatine monohydrate, creatine hydrochloride, creatine hcl, micronized creatine, top creatine, effective creatine, affordable creatine, workout supplements, creatine comparison, ingredient-based creatine, value creatine, creatine definition"
        />
        <meta property="og:title" content="Creatine Ranking Examples" />
        <meta
          property="og:description"
          content="Discover the best value Creatine with our value ranking system. Whether you want micronized, hcl, or regular monohydrate, our rankings highlight top Creatine based on value."
        />
      </Helmet>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton to={"/creatine"}>
          <LinkSVG />
          <BackText>Creatine Filter</BackText>
        </BackButton>
        <LiveIndicator />
      </BackDiv>
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Micronized Creatine Monohydrate"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() => handlePopUp(["micronized"], props.ingredients)}
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {"Ranked by Creatine value."}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleOne).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleOne}
                  filterLink={searchPageGen(
                    "creatine",
                    "micronized",
                    null,
                    null,
                    null,
                    [],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setCreatineFilter}
                  setFilterLink={props.setCreatineFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "creatine",
            "micronized",
            null,
            null,
            null,
            [],
            [],
            [],
            props.ingredients
          )}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Creatine Monohydrate"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() => handlePopUp(["monohydrate"], props.ingredients)}
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {"Ranked by Creatine value."}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleTwo).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleTwo}
                  filterLink={searchPageGen(
                    "creatine",
                    "monohydrate",
                    null,
                    null,
                    null,
                    [],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setCreatineFilter}
                  setFilterLink={props.setCreatineFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "creatine",
            "monohydrate",
            null,
            null,
            null,
            [],
            [],
            [],
            props.ingredients
          )}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Patented Creatine"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() => handlePopUp(["monohydrate"], props.ingredients)}
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {"Ranked by Creatine value."}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleThree).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleThree}
                  filterLink={searchPageGen(
                    "creatine",
                    null,
                    null,
                    true,
                    null,
                    [],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setCreatineFilter}
                  setFilterLink={props.setCreatineFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "creatine",
            null,
            null,
            true,
            null,
            [],
            [],
            [],
            props.ingredients
          )}
          style={props.userCountry === "GB" ? { marginBottom: "25px" } : {}}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      {props.userCountry === "US" && (
        <>
          <TabBar />
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["Creatine HCl"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            <ResponseSubtitle
              onClick={() => handlePopUp(["hcl"], props.ingredients)}
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {"Ranked by Creatine value."}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(exampleFour).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      userCountry={props.userCountry}
                      ai={true}
                      key={index}
                      filter={exampleFour}
                      filterLink={searchPageGen(
                        "creatine",
                        "hcl",
                        null,
                        null,
                        null,
                        [],
                        [],
                        [],
                        props.ingredients
                      )}
                      setFilter={props.setCreatineFilter}
                      setFilterLink={props.setCreatineFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "creatine",
                "hcl",
                null,
                null,
                null,
                [],
                [],
                [],
                props.ingredients
              )}
              style={{ marginBottom: "25px" }}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
        </>
      )}

      {/* <LastTabBar /> */}
      <PopUp
        isOpen={isPopupOpen}
        onClose={closePopup}
        ingredients={popupIngredients}
        ingredientList={popupIngredientList}
      />
      <AdPopUp
        canOpen={!isPopupOpen && props.userCountry === "GB"}
        accessories={props.accessories}
      />
      <FanPopUp canOpen={!isPopupOpen} userCountry={props.userCountry} />
    </center>
  );
};

export default CreatineExample;
