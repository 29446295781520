export function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

export const creatineIngredients = [
  {
    value: "hcl",
    label: "Creatine HCl",
    definition:
      "Creatine HCl (Creatine Hydrochloride) is a form of creatine where a hydrochloride group is attached to the creatine molecule, enhancing its water solubility and absorption in the body. This increased solubility means that Creatine HCl is more easily dissolved in liquids, potentially leading to better absorption and reduced gastrointestinal discomfort compared to other forms, such as creatine monohydrate. As a result, lower doses of Creatine HCl are often required to achieve similar effects, such as increased muscle strength, endurance, and recovery, making it a popular choice for athletes and bodybuilders. Creatine HCl is valued for its effectiveness in promoting muscle growth, improving performance in high-intensity workouts, and reducing the likelihood of bloating or water retention, which can sometimes be associated with other forms of creatine supplementation.",
  },
  {
    value: "micronized",
    label: "Micronized Creatine Monohydrate",
    definition:
      "Micronized Creatine Monohydrate is a form of creatine monohydrate that has been processed into smaller particles, or micronized, to enhance its solubility in water and absorption in the body. Creatine is a naturally occurring compound found in small amounts in certain foods and synthesized in the body, primarily stored in muscles, where it helps regenerate adenosine triphosphate (ATP), the primary energy carrier for short bursts of high-intensity activities. Micronized creatine monohydrate is popular among athletes, bodybuilders, and fitness enthusiasts for its potential to increase muscle strength, power, and endurance, as well as support faster recovery after intense workouts. The micronization process makes this form of creatine easier to mix, more stable, and potentially more effective in delivering creatine to muscle cells, enhancing overall performance and muscle growth.",
  },
  {
    value: "monohydrate",
    label: "Creatine Monohydrate",
    definition:
      "Creatine Monohydrate is the most common and well-researched form of creatine, widely used to increase muscle strength, enhance endurance, and support muscle growth. It works by replenishing ATP, the primary energy source for muscle contractions, allowing for improved performance during high-intensity exercise. Creatine Monohydrate is known for its effectiveness in building lean muscle mass and is a staple ingredient in many sports and bodybuilding supplements.",
  },
];

export function titleMakerPW([...excludes], ingredients) {
  const findLabel = (value) => {
    const ingredient = ingredients.find((ing) => ing.value === value);
    return ingredient ? ingredient.label : value;
  };

  const revisedExcludes = excludes
    .filter(
      (exclude) =>
        typeof exclude === "string" &&
        exclude !== "undefined" &&
        exclude !== "null"
    )
    .map((exclude) => {
      return findLabel(exclude);
    });

  if (revisedExcludes.length === 0) {
    return "Pre-Workout";
  } else if (revisedExcludes.length === 1) {
    return revisedExcludes[0] + " Free Pre-Workout";
  } else {
    return (
      revisedExcludes.slice(0, -1).join(", ") +
      " and " +
      revisedExcludes[revisedExcludes.length - 1] +
      " Free Pre-Workout"
    );
  }
}

export function titleMakerIntra([...excludes], ingredients) {
  const findLabel = (value) => {
    const ingredient = ingredients.find((ing) => ing.value === value);
    return ingredient ? ingredient.label : value;
  };

  const removeTrailingS = (word) => {
    return word.endsWith("s") ? word.slice(0, -1) : word;
  };

  const revisedExcludes = excludes
    .filter(
      (exclude) =>
        typeof exclude === "string" &&
        exclude !== "undefined" &&
        exclude !== "null"
    )
    .map((exclude) => {
      return removeTrailingS(findLabel(exclude));
    });

  if (revisedExcludes.length === 0) {
    return "Intra-Workout";
  } else if (revisedExcludes.length === 1) {
    return revisedExcludes[0] + " Free Intra-Workout";
  } else {
    return (
      revisedExcludes.slice(0, -1).join(", ") +
      " and " +
      revisedExcludes[revisedExcludes.length - 1] +
      " Free Intra-Workout"
    );
  }
}

export function titleMakerEnergy([...excludes], ingredients) {
  const findLabel = (value) => {
    const ingredient = ingredients.find((ing) => ing.value === value);
    return ingredient ? ingredient.label : value;
  };

  const revisedExcludes = excludes
    .filter(
      (exclude) =>
        typeof exclude === "string" &&
        exclude !== "undefined" &&
        exclude !== "null"
    )
    .map((exclude) => {
      if (exclude === "calories") return "Calorie";
      return findLabel(exclude);
    });

  if (revisedExcludes.length === 0) {
    return "Energy Drinks";
  } else if (revisedExcludes.length === 1) {
    if (revisedExcludes[0] === "Calorie") return "Zero Calorie Energy Drinks";
    if (revisedExcludes[0] === "Sugar") return "Zero Sugar Energy Drinks";

    return revisedExcludes[0] + " Free Energy Drinks";
  } else {
    return (
      revisedExcludes.slice(0, -1).join(", ") +
      " and " +
      revisedExcludes[revisedExcludes.length - 1] +
      " Free Energy Drinks"
    );
  }
}

export function submaker([...ingredients], rangeData = [], ingredientData) {
  const findLabel = (value) => {
    const ingredient = ingredientData.find((ing) => ing.value === value);
    return ingredient ? ingredient.label : value;
  };

  // Helper function to get the range and unit for an ingredient, if applicable
  const findRangeAndUnit = (value) => {
    const range = rangeData.find((r) => r.ingredient === value && !r.hide);
    const ingredient = ingredientData.find((ing) => ing.value === value);
    const unit = ingredient ? ingredient.unit : ""; // Get unit from preIngredients

    if (range && range.min !== null && range.max !== null) {
      return ` (${range.min}-${range.max}${unit ? `${unit}` : ""})`;
    }
    return ""; // Return empty string if no valid range
  };

  // Filter valid ingredients (non-null, non-empty, etc.)
  const validIngredients = ingredients.filter(
    (ingredient) =>
      typeof ingredient === "string" &&
      ingredient !== "undefined" &&
      ingredient !== "null" &&
      ingredient.trim() !== ""
  );

  // Map ingredients to include their labels and ranges with units
  const revisedIngredients = validIngredients.map((ingredient) => {
    const label = findLabel(ingredient);
    const rangeAndUnit = findRangeAndUnit(ingredient);
    return label + rangeAndUnit;
  });

  // Handle case where no valid ingredients are provided
  if (revisedIngredients.length === 0) {
    return "No valid ingredients provided.";
  }
  // Handle case where there's only one ingredient
  else if (revisedIngredients.length === 1) {
    return "Ranked by " + revisedIngredients[0] + " value.";
  }
  // Handle case where multiple ingredients are present
  else {
    return (
      "Ranked by " +
      revisedIngredients.slice(0, -1).join(", ") +
      " and " +
      revisedIngredients[revisedIngredients.length - 1] +
      " value."
    );
  }
}

export function subMakerProtein(rank) {
  if (rank === "lowcal") {
    return "Ranked by Lowest Calories and Protein value.";
  } else if (rank === "highcal") {
    return "Ranked by Highest Calories and Protein value.";
  }

  return "Ranked by Protein value.";
}

export function tieBreaker(items) {
  let previousValueRank = null;
  let currentRank = 1;
  let previousOldValueRank = null;
  let oldCurrentRank = 1;

  // First, calculate the current ranks while handling ties
  const rankedItems = items.map((item, index) => {
    if (previousValueRank !== null && item.value_rank === previousValueRank) {
      currentRank--;
    }
    const rankToShow = currentRank;
    previousValueRank = item.value_rank;
    currentRank++;
    return { ...item, currentRank: rankToShow };
  });

  // Sort items by old_value_rank
  const sortedByOldValueRank = [...rankedItems].sort(
    (a, b) => a.old_value_rank - b.old_value_rank
  );

  // console.log(sortedByOldValueRank);

  // Calculate old ranks while handling ties
  const rankedOldItems = sortedByOldValueRank.map((item, index) => {
    if (
      previousOldValueRank !== null &&
      item.old_value_rank === previousOldValueRank
    ) {
      oldCurrentRank--;
    }
    const oldRankToShow = oldCurrentRank;
    previousOldValueRank = item.old_value_rank;
    oldCurrentRank++;
    return { ...item, oldRank: oldRankToShow };
  });

  // Merge old ranks back into the original items based on value_rank
  const mergedItems = rankedItems.map((item) => {
    const rankedOldItem = rankedOldItems.find(
      (i) => i.brand === item.brand && i.subtitle === item.subtitle
    );
    return {
      ...item,
      oldRank: rankedOldItem ? rankedOldItem.oldRank : null,
    };
  });

  // console.log(mergedItems);
  return mergedItems.filter((i) => i.currentRank <= 3);
}

export function filterTieBreaker(items) {
  let previousValueRank = null;
  let currentRank = 1;
  let previousOldValueRank = null;
  let oldCurrentRank = 1;

  // First, calculate the current ranks while handling ties
  const rankedItems = items.map((item, index) => {
    if (previousValueRank !== null && item.value_rank === previousValueRank) {
      currentRank--;
    }
    const rankToShow = currentRank;
    previousValueRank = item.value_rank;
    currentRank++;
    return { ...item, currentRank: rankToShow };
  });

  // Sort items by old_value_rank
  const sortedByOldValueRank = [...rankedItems].sort(
    (a, b) => a.old_value_rank - b.old_value_rank
  );

  // console.log(sortedByOldValueRank);

  // Calculate old ranks while handling ties
  const rankedOldItems = sortedByOldValueRank.map((item, index) => {
    if (
      previousOldValueRank !== null &&
      item.old_value_rank === previousOldValueRank
    ) {
      oldCurrentRank--;
    }
    const oldRankToShow = oldCurrentRank;
    previousOldValueRank = item.old_value_rank;
    oldCurrentRank++;
    return { ...item, oldRank: oldRankToShow };
  });

  // Merge old ranks back into the original items based on value_rank
  const mergedItems = rankedItems.map((item) => {
    const rankedOldItem = rankedOldItems.find(
      (i) => i.brand === item.brand && i.subtitle === item.subtitle
    );
    return {
      ...item,
      oldRank: rankedOldItem ? rankedOldItem.oldRank : null,
    };
  });

  // console.log(mergedItems);
  return mergedItems;
}

export function searchPageGen(
  category,
  filter,
  rank,
  patent, // creatine
  type, // protein bars
  [...rankIngredients],
  [...excludeIngredients],
  [...rangeData] = [],
  ingredients
) {
  let link = "/" + category + "?";
  const params = new URLSearchParams();
  ingredients =
    category !== "citrulline" || category !== "betaalanine"
      ? ingredients.filter((i) => i.category === category)
      : ingredients.filter((i) => i.category === "preworkout");

  if (category === "massgainer") {
    return "/massgainer";
  }

  if (
    category === "preworkout" ||
    category === "intraworkout" ||
    category === "energydrink"
  ) {
    rankIngredients.forEach((ingredient, index) => {
      const foundIngredient = ingredients.find((i) => i.value === ingredient);
      const foundData = rangeData.find(
        (item) => item.ingredient === ingredient
      );

      if (foundIngredient) {
        params.append(`ingredient${index + 1}`, foundIngredient.value);

        // Check if the ingredient has a range
        if (foundData) {
          params.append(
            `ingredientRange${index + 1}`,
            `${foundData.min}-${foundData.max}`
          );
        }
      }
    });
    excludeIngredients.forEach((exclude, index) => {
      const value = ingredients.find((i) => i.value === exclude)?.value;
      if (value) {
        params.append(`exclude${index + 1}`, value);
      }
    });
  }

  if (
    category === "omega3" ||
    category === "magnesium" ||
    category === "multivitamin"
  ) {
    rankIngredients.forEach((ingredient, index) => {
      const foundIngredient = ingredients.find((i) => i.value === ingredient);
      const foundData = rangeData.find(
        (item) => item.ingredient === ingredient
      );

      if (foundIngredient) {
        params.append(`ingredient${index + 1}`, foundIngredient.value);

        // Check if the ingredient has a range
        if (foundData) {
          params.append(
            `ingredientRange${index + 1}`,
            `${foundData.min}-${foundData.max}`
          );
        }
      }
    });

    if (filter && filter !== "all") {
      params.append("filter", filter);
    }
  }

  if (category === "proteinpowder") {
    if (filter && filter !== "all" && filter !== "undefined") {
      params.append(`type`, filter);
    }
    if (rank && rank !== "undefined") {
      params.append(`rank`, rank);
    }
  }

  if (category === "proteinbar") {
    if (type && type !== "undefined") {
      params.append(`type`, type);
    }
    if (filter && filter !== "all" && filter !== "undefined") {
      params.append(`filter`, filter);
    }
    if (rank && rank !== "undefined") {
      params.append(`rank`, rank);
    }
  }

  if (category === "electrolyte") {
    rankIngredients.forEach((ingredient, index) => {
      const value = ingredients.find((i) => i.value === ingredient)?.value;
      if (value) {
        params.append(`ingredient${index + 1}`, value);
      }
    });

    if (filter && filter !== "all") {
      params.append("filter", filter);
    }
  }

  if (category === "aminoacid") {
    rankIngredients.forEach((ingredient, index) => {
      const value = ingredients.find((i) => i.value === ingredient)?.value;
      if (value) {
        params.append(`ingredient${index + 1}`, value);
      }
    });

    if (filter && filter !== "all") {
      params.append("filter", filter);
    }
  }

  if (category === "creatine") {
    if (filter && filter !== "all") {
      params.append(`type`, filter);
    }
    if (patent && patent !== "undefined") {
      params.append(`patent`, "true");
    }
  }

  if (
    category === "citrulline" ||
    category === "betaalanine" ||
    category === "turkesterone"
  ) {
    if (filter && filter !== "all") {
      params.append(`type`, filter);
    }
  }

  link += params.toString();

  // console.log(link);
  return link;
}

export const Regions = [
  {
    value: "US",
    label: "United States",
  },
  {
    value: "GB",
    label: "United Kingdom",
  },
];

export const itemFilter = (originalItems, rangeData) => {
  if (!Array.isArray(originalItems) || !Array.isArray(rangeData)) {
    // console.error(
    //   "Invalid input: originalItems and rangeData should be arrays."
    // );
    return [];
  }

  const filtered = originalItems.filter((item) => {
    return rangeData.every((range) => {
      const value = parseFloat(item[range.ingredient]);

      // If any of these conditions are met, the item passes the current range
      if (
        range.min === null ||
        range.max === null ||
        range.max === -Infinity ||
        range.hide
      ) {
        return true;
      }

      // Check if the item's value falls within the specified range
      if (item.twoscoop) {
        return 2 * value >= range.min && 2 * value <= range.max;
      } else {
        return value >= range.min && value <= range.max;
      }
    });
  });

  // Apply the tie-breaker function if provided
  return filterTieBreaker(filtered);
};

export const compareLink = (product) => {
  let link;

  if (
    product.category === "preworkout" ||
    product.category === "massgainer" ||
    product.category === "proteinbar" ||
    product.category === "energydrink" ||
    product.category === "intraworkout"
  ) {
    link =
      "/compare?category1=" +
      product.category +
      "&brand1=" +
      encodeURIComponent(product.brand) +
      "&subtitle1=" +
      encodeURIComponent(product.subtitle) +
      "&servings1=" +
      product.servings +
      "&flavor1=" +
      product.flavor;
  } else if (
    product.category === "proteinpowder" ||
    product.category === "electrolyte" ||
    product.category === "betaalanine" ||
    product.category === "citrulline"
  ) {
    link =
      "/compare?category1=" +
      product.category +
      "&brand1=" +
      encodeURIComponent(product.brand) +
      "&type1=" +
      product.type +
      "&servings1=" +
      product.servings +
      "&flavor1=" +
      product.flavor;
  } else if (product.category === "creatine") {
    link =
      "/compare?category1=" +
      product.category +
      "&brand1=" +
      encodeURIComponent(product.brand) +
      "&type1=" +
      product.type +
      "&servings1=" +
      product.servings +
      "&flavor1=" +
      product.flavor +
      "&patent1=" +
      product.patent;
  } else if (
    product.category === "aminoacid" ||
    product.category === "omega3" ||
    product.category === "magnesium" ||
    product.category === "turkesterone" ||
    product.category === "multivitamin"
  ) {
    link =
      "/compare?category1=" +
      product.category +
      "&brand1=" +
      encodeURIComponent(product.brand) +
      "&subtitle1=" +
      encodeURIComponent(product.subtitle) +
      "&servings1=" +
      product.servings +
      "&flavor1=" +
      product.flavor +
      "&type1=" +
      product.type;
  }

  return link;
};

export function calculateReadingTime(articleContent) {
  const wordsPerMinute = 200; // Average reading speed (words per minute)
  const words = articleContent.split(/\s+/).length; // Split by whitespace to count words
  const readingTime = Math.ceil(words / wordsPerMinute); // Calculate time and round up to the nearest integer
  return readingTime;
}

export function extractSearchParams(apiCall) {
  // Extract the query string (everything after the ?)
  const queryString = apiCall.split("?")[1];
  const params = new URLSearchParams(queryString);

  // Create an object to hold the search param values
  const searchParamsObject = {};

  // Iterate through all the search params and add them to the object
  params.forEach((value, key) => {
    searchParamsObject[key] = value;
  });

  return searchParamsObject;
}

export function titleMakerProtein(type) {
  if (type === "isolate") {
    return "Whey Isolate Protein Powder";
  }
  if (type === "concentrate") {
    return "Whey Concentrate Protein Powder";
  }
  if (type === "clear") {
    return "Clear Whey Isolate Protein Powder";
  }
  if (type === "blend") {
    return "Whey Blend Protein Powder";
  }
  if (type === "vegan") {
    return "Vegan Protein Powder";
  }

  return "All Protein Powder";
}

export function titleMakerAmino(filter, ingredients) {
  let title = "";
  let sortedIngredients = [...ingredients]
    .filter((item) => item !== "undefined")
    .sort();

  // console.log(ingredients);

  // if filter === "all" && ingredients === BCAA -> Branch-Chain Amino Acids
  // else if ingredients === BCAA -> BCAA
  // if filter === "all" && ingredients === EAA -> Essential Amino Acids
  // else if ingredients === EAA -> EAA

  if (
    (!filter || filter === "all") &&
    JSON.stringify(sortedIngredients) ===
      JSON.stringify(["isoleucine", "leucine", "valine"])
  ) {
    title = "Branch-Chain Amino Acids";
  } else if (
    JSON.stringify(sortedIngredients) ===
    JSON.stringify(["isoleucine", "leucine", "valine"])
  ) {
    title = "BCAA";
  } else if (
    (!filter || filter === "all") &&
    JSON.stringify(sortedIngredients) ===
      JSON.stringify([
        "histidine",
        "isoleucine",
        "leucine",
        "lysine",
        "methionine",
        "phenylalanine",
        "threonine",
        "tryptophan",
        "valine",
      ])
  ) {
    title = "Essential Amino Acids";
  } else if (
    JSON.stringify(sortedIngredients) ===
    JSON.stringify([
      "histidine",
      "isoleucine",
      "leucine",
      "lysine",
      "methionine",
      "phenylalanine",
      "threonine",
      "tryptophan",
      "valine",
    ])
  ) {
    title = "EAA";
  } else if (!filter) {
    title = "All Amino Acids";
  } else if (filter) {
    title = "Amino Acid";
  }

  if (filter === "capsule") {
    title += " Capsules";
  } else if (filter === "tablet") {
    title += " Tablets";
  } else if (filter === "powder") {
    title += " Powder";
  }

  return title;
}

export function titleMakerProteinBar(filter, type = null) {
  const Types = [
    { value: "casein-whey-blend", label: "Casein/Whey" },
    { value: "collagen-whey-blend", label: "Collagen/Whey" },
    { value: "milk", label: "Milk" },
    { value: "milk-whey-blend", label: "Milk/Whey" },
    { value: "pea", label: "Pea" },
    { value: "pea-milk-blend", label: "Pea/Milk" },
    { value: "pea-whey-blend", label: "Pea/Whey" },
    { value: "peanut", label: "Peanut" },
    { value: "rice-pea-blend", label: "Rice/Pea" },
    { value: "rice-seed-blend", label: "Rice/Seed" },
    { value: "soy", label: "Soy" },
    { value: "soy-pea-blend", label: "Soy/Pea" },
    { value: "soy-wheat-blend", label: "Soy/Wheat" },
    { value: "soy-whey-blend", label: "Soy/Whey" },
    { value: "wheat-milk-blend", label: "Wheat/Milk" },
    { value: "whey", label: "Whey" },
  ];

  const typeLabel = Types.find((t) => t.value === type)?.label;

  if (filter === "glutenfree") {
    return `Gluten Free${typeLabel ? " " + typeLabel : ""} Protein Bars`;
  }
  if (filter === "vegan") {
    return `Vegan${typeLabel ? " " + typeLabel : ""} Protein Bars`;
  }

  if (!filter && !typeLabel) {
    return "All Protein Bars";
  }

  return `${typeLabel ? typeLabel : ""} Protein Bars`;
}
