import React from "react";
import styled from "styled-components";
import { GradientAnimation } from "../custom/animations";
import { SmallChartSVG } from "../custom/svg";
import { CircularProgress } from "@mui/material";

const ListRoundedSquare = styled.div.attrs((props) => ({
  color: props.color,
}))`
  display: block;
  text-decoration: none;
  color: black;
  width: 100%;
  height: 85px;
  border-radius: 15px;
  //   margin: 5px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  background: ${(props) =>
    props.rank <= 3
      ? "linear-gradient(135deg, " +
        props.color +
        " 0%, " +
        props.color +
        " 45%, white 50%, " +
        props.color +
        " 55%, " +
        props.color +
        " 100%)"
      : "#efefef"};
  background-size: 400% 400%;
  animation: ${GradientAnimation} 10s ease infinite;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 10px gray;
    }
  }
`;
const FrontRoundedSquare = styled.div`
  height: 75px;
  background-color: white;
  border-radius: 10px;
  position: static;
  margin: 5px;
`;
const ListOuterDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  width: 100%;
`;
const ListContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  margin-top: 3px;
  // margin-top: 5px;
`;
const ListImageDiv = styled.div`
  height: 80%;
  aspect-ratio: 1 / 1;
  margin-left: 5px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
`;
const ListImage = styled.img`
  height: 100%;
`;
const ListRank = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.2rem;
  position: static;
  margin-right: auto;
  margin-left: 0;

  @media (max-width: 760px) {
    font-size: 1rem;
  }
`;
const MobileTitleDiv = styled.div`
  //   margin-top: 5px;
  height: auto;
  min-width: 80%;
  margin-top: 2px;
  // margin-top: 10px;
`;
const PodiumBrand = styled.p`
  font-family: San Francisco Bold;
  font-size: 1rem;
  padding-left: 10px;
  line-height: 1.3rem;
  text-align: left;
  margin: 0px 0px 3px 0px;
  // margin-top: 5px;
  //   margin-bottom: 3px;font-size: 1rem;
  line-height: 1.3rem;
`;
const PodiumFlavor = styled.p`
  font-family: San Francisco Regular;
  font-size: 0.9rem;
  text-align: left;
  color: gray;
  padding-left: 20px;
  margin: 0px 0px;
`;

const MobileInfoDiv = styled.div`
  display: flex;
  font-family: San Francisco Regular;
  flex-direction: row;
  align-items; center;
  justify-content: left;
  width: 100%;
  margin-left: 16px;
  margin-top: 5px;

//   @media (min-width: 760px) {
//     display: none;
//   }
`;
const LoadingDiv = styled.div`
  display: flex;
  width: 80%;
  height: 100%;
  align-items: center;
`;
const BrandTickerDiv = styled.div`
  display: flex;
  padding-left: 10px;
  //   margin-bottom: 5px;
  // gap: 10px;
  justify-content: left;
  align-items: center;
`;

const BrandListSearch = (props) => {
  let color = "";
  let sign = "$";
  let subtitle = "";

  if (props.userCountry === "GB") {
    sign = "£";
  }

  if (props.filterRank === 1) {
    color = "#d4af37";
  } else if (props.filterRank === 2) {
    color = "#C0C0C0";
  } else if (props.filterRank === 3) {
    color = "#CD7F32";
  } else {
    color = "#efefef";
  }

  if (
    props.item.category === "preworkout" ||
    props.item.category === "massgainer" ||
    props.item.category === "energydrink" ||
    props.item.category === "proteinbar" ||
    props.item.category === "omega3" ||
    props.item.category === "intraworkout" ||
    props.item.category === "aminoacid" ||
    props.item.category === "magnesium" ||
    props.item.category === "turkesterone" ||
    props.item.category === "multivitamin"
  ) {
    subtitle = props.item.subtitle;
  }

  if (props.item.category === "electrolyte") {
    if (props.item.type === "packet") {
      subtitle = "Electrolyte Packets";
    }
    if (props.item.type === "powder") {
      subtitle = "Electrolyte Powder";
    }
    if (props.item.type === "capsule") {
      subtitle = "Electrolyte Capsules";
    }
    if (props.item.type === "tablet") {
      subtitle = "Electrolyte Tablets";
    }
  }

  if (props.item.category === "omega3") {
    if (props.item.type === "capsule") {
      subtitle = props.item.subtitle + " Capsules";
    }
    if (props.item.type === "gummy") {
      subtitle = props.item.subtitle + " Gummies";
    }
    if (props.item.type === "liquid") {
      subtitle = props.item.subtitle + " Liquid";
    }
    if (props.item.type === "oil") {
      subtitle = props.item.subtitle + " Oil";
    }
    if (props.item.type === "packet") {
      subtitle = props.item.subtitle + " Packets";
    }
    if (props.item.type === "softgel") {
      subtitle = props.item.subtitle + " Softgels";
    }
  }

  if (props.item.category === "proteinpowder") {
    if (props.item.type === "Vegan") {
      subtitle = "Vegan";
    } else if (props.item.type === "Clear") {
      subtitle = "Clear Whey Isolate";
    } else {
      subtitle = "Whey " + props.item.type;
    }
  }

  if (props.item.category === "betaalanine") {
    if (props.item.type === "powder") {
      subtitle = "Beta Alanine Powder";
    }
    if (props.item.type === "capsule") {
      subtitle = "Beta Alanine Capsules";
    }
  }

  if (props.item.category === "citrulline") {
    if (props.item.type === "powder") {
      subtitle = "Citrulline Powder";
    }
    if (props.item.type === "capsule") {
      subtitle = "Citrulline Capsules";
    }
  }

  if (props.item.category === "creatine") {
    if (props.item.type === "Micronized Monohydrate") {
      subtitle = "Micronized Monohydrate";
    } else {
      subtitle = props.item.type;
    }
  }

  return (
    <ListRoundedSquare color={color} onClick={props.onClick}>
      <FrontRoundedSquare>
        {!props.loading ? (
          <ListOuterDiv>
            <ListContentDiv>
              <ListImageDiv
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ListImage src={props.item.imglink} style={{ height: "80%" }} alt={props.item.brand + " " + props.item.subtitle + " " + props.item.category} />
              </ListImageDiv>
              {/* <ListRank>{props.filterRank}.</ListRank> */}
              <MobileTitleDiv>
                <BrandTickerDiv>
                  <PodiumBrand>{props.item.brand}</PodiumBrand>
                </BrandTickerDiv>
                <PodiumFlavor>{subtitle}</PodiumFlavor>
                <MobileInfoDiv>
                  <SmallChartSVG />
                  <p
                    style={{
                      margin: "2px 0px 0px 2px",
                      color: "gray",
                      fontSize: "11px",
                    }}
                  >
                    {props.item.min_servings !== props.item.max_servings
                      ? props.item.min_servings +
                        "-" +
                        props.item.max_servings +
                        " Servings"
                      : props.item.servings + " Servings"}
                    {" ‧ " +
                      (!props.item.flavor ? "Unflavored" : props.item.flavor)}
                  </p>
                </MobileInfoDiv>
              </MobileTitleDiv>
            </ListContentDiv>
          </ListOuterDiv>
        ) : (
          <LoadingDiv>
            <CircularProgress color="inherit" />{" "}
          </LoadingDiv>
        )}
      </FrontRoundedSquare>
    </ListRoundedSquare>
  );
};

export default BrandListSearch;
