import React from "react";
import { useParams } from "react-router-dom";
import { trackEvent } from "../custom/analytics";
import { compareLink } from "../custom/functions";
import { RightArrowSVG, LeftArrowSVG, EyeSVG, PlusSVG } from "../custom/svg";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import ShopNowButton from "../components/ShopNowButton";
import { Helmet } from "react-helmet-async";
import WarningPopUp from "../components/WarningPopUp";
import {
  StyledLink,
  UnderlinedSpan,
  ProductDiv,
  Disclaimer,
  Table,
  SizeDiv,
  FlavorDiv,
  FlavorButton,
  FlavorText,
  ArrowDiv,
  RankButton,
  BackDiv,
  BackButton,
  BackText,
  TableIngredient,
  TableValue,
  TableDefinition,
  TableButtonDiv,
  TableButton,
  ScoopButton,
  CompareButton,
} from "../css/productPage";
import ProductPageCard from "../components/ProductPageCard";

const NutritionTable = (props) => {
  const [selectedIngredient, setSelectedIngredient] = useState(null);

  const handleIngredientClick = (ingredient) => {
    if (ingredient === selectedIngredient) {
      return setSelectedIngredient(null);
    }
    setSelectedIngredient(ingredient);
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>
            <span>Nutrition: </span>
          </th>
          <th>
            <span>Amount: </span>
          </th>
          {/* <th>
              <span>Category Rank: </span>
            </th> */}
        </tr>
      </thead>
      <tbody>
        <tr>
          <TableIngredient>
            <span>Servings</span>
          </TableIngredient>
          <TableValue>
            <span>
              {props.twoScoop
                ? parseFloat(props.prod.servings) / 2
                : props.prod.servings}
            </span>
          </TableValue>
        </tr>
        {!props.prod.subtitle.includes("(Liquid)") ? (
          <tr>
            <TableIngredient>
              <span>Serving Size</span>
            </TableIngredient>
            <TableValue>
              <span>
                {props.twoScoop
                  ? parseFloat(props.prod.servingsize) * 2
                  : props.prod.servingsize}
                g
              </span>
            </TableValue>
          </tr>
        ) : (
          <tr>
            <TableIngredient>
              <span>Serving Size</span>
            </TableIngredient>
            <TableValue>
              <span>
                {props.twoScoop
                  ? parseFloat(props.prod.servingsize) * 2
                  : props.prod.servingsize}{" "}
                fl. oz
              </span>
            </TableValue>
          </tr>
        )}
        {props.ingredients.map((ingredient) => {
          const { value, label, unit, definition } = ingredient;

          if (value === "alphagpc" && props.prod[value] !== null) {
            let gpcType = "";
            let alphaDef = definition;

            if (props.prod.alphasize !== null) {
              gpcType = "(AlphaSize®)";
              alphaDef = props.ingredients.find(
                (ingredient) => ingredient.value === "alphasize"
              ).definition;
            }
            if (props.prod.alphazone !== null) {
              gpcType = "(AlphaZone®)";
              alphaDef = props.ingredients.find(
                (ingredient) => ingredient.value === "alphazone"
              ).definition;
            }
            return (
              <React.Fragment key={value}>
                <tr key={value}>
                  <TableIngredient>
                    {definition ? (
                      <UnderlinedSpan
                        onClick={() => handleIngredientClick(ingredient)}
                      >
                        {label} {gpcType}
                      </UnderlinedSpan>
                    ) : (
                      <span>
                        {label} {gpcType}
                      </span>
                    )}
                  </TableIngredient>
                  <TableValue>
                    <span>
                      {props.twoScoop
                        ? parseFloat(props.prod[value]) * 2
                        : props.prod[value]}
                      {unit}
                    </span>
                  </TableValue>
                </tr>
                {selectedIngredient && selectedIngredient.value === value && (
                  <tr>
                    <td colSpan="2" style={{ border: "none" }}>
                      <TableDefinition>{alphaDef}</TableDefinition>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          }
          if (value === "glycerol" && props.prod[value] !== null) {
            let type = "";
            let glycDef = definition;

            if (props.prod.hydroprime !== null) {
              type = "(HydroPrime®)";
              glycDef = props.ingredients.find(
                (ingredient) => ingredient.value === "hydroprime"
              ).definition;
            } else if (props.prod.hydromax !== null) {
              type = "(HydroMax™)";
              glycDef = props.ingredients.find(
                (ingredient) => ingredient.value === "hydromax"
              ).definition;
            } else if (props.prod.glycersize !== null) {
              type = "(GlycerSize™)";
              glycDef = props.ingredients.find(
                (ingredient) => ingredient.value === "glycersize"
              ).definition;
            } else if (props.prod.glycerpump !== null) {
              type = "(GlycerPump™)";
              glycDef = props.ingredients.find(
                (ingredient) => ingredient.value === "glycerpump"
              ).definition;
            }
            return (
              <>
                <tr key={value}>
                  <TableIngredient>
                    {definition ? (
                      <UnderlinedSpan
                        onClick={() => handleIngredientClick(ingredient)}
                      >
                        {label} {type}
                      </UnderlinedSpan>
                    ) : (
                      <span>
                        {label} {type}
                      </span>
                    )}
                  </TableIngredient>
                  <TableValue>
                    <span>
                      {props.twoScoop
                        ? parseFloat(props.prod[value]) * 2
                        : props.prod[value]}
                      {unit}
                    </span>
                  </TableValue>
                </tr>
                {selectedIngredient && selectedIngredient.value === value && (
                  <tr>
                    <td colSpan="2" style={{ border: "none" }}>
                      <TableDefinition>{glycDef}</TableDefinition>
                    </td>
                  </tr>
                )}
              </>
            );
          }
          if (value === "betaalanine" && props.prod[value] !== null) {
            let type = "";
            let betaDef = definition;

            if (props.prod.carnosyn !== null) {
              type = "(CarnoSyn®)";
              betaDef = props.ingredients.find(
                (ingredient) => ingredient.value === "carnosyn"
              ).definition;
            }
            return (
              <>
                <tr key={value}>
                  <TableIngredient>
                    {definition ? (
                      <UnderlinedSpan
                        onClick={() => handleIngredientClick(ingredient)}
                      >
                        {label} {type}
                      </UnderlinedSpan>
                    ) : (
                      <span>
                        {label} {type}
                      </span>
                    )}
                  </TableIngredient>
                  <TableValue>
                    <span>
                      {props.twoScoop
                        ? parseFloat(props.prod[value]) * 2
                        : props.prod[value]}
                      {unit}
                    </span>
                  </TableValue>
                </tr>
                {selectedIngredient && selectedIngredient.value === value && (
                  <tr>
                    <td colSpan="2" style={{ border: "none" }}>
                      <TableDefinition>{betaDef}</TableDefinition>
                    </td>
                  </tr>
                )}
              </>
            );
          }
          if (value === "creatine" && props.prod[value] !== null) {
            let type = "";
            let creaDef = definition;

            if (props.prod.creaclear !== null) {
              type = "(CreaClear™)";
              creaDef = props.ingredients.find(
                (ingredient) => ingredient.value === "creaclear"
              ).definition;
            }
            return (
              <>
                <tr key={value}>
                  <TableIngredient>
                    {definition ? (
                      <UnderlinedSpan
                        onClick={() => handleIngredientClick(ingredient)}
                      >
                        {label} {type}
                      </UnderlinedSpan>
                    ) : (
                      <span>
                        {label} {type}
                      </span>
                    )}
                  </TableIngredient>
                  <TableValue>
                    <span>
                      {props.twoScoop
                        ? parseFloat(props.prod[value]) * 2
                        : props.prod[value]}
                      {unit}
                    </span>
                  </TableValue>
                </tr>
                {selectedIngredient && selectedIngredient.value === value && (
                  <tr>
                    <td colSpan="2" style={{ border: "none" }}>
                      <TableDefinition>{creaDef}</TableDefinition>
                    </td>
                  </tr>
                )}
              </>
            );
          }
          if (
            props.prod[value] !== null &&
            value !== "hydromax" &&
            value !== "glycersize" &&
            value !== "glycerpump" &&
            value !== "hydropump" &&
            value !== "hydroprime" &&
            value !== "alphazone" &&
            value !== "alphasize" &&
            value !== "carnosyn" &&
            value !== "creaclear"
          ) {
            return (
              <>
                <tr key={value}>
                  <TableIngredient>
                    {definition ? (
                      <UnderlinedSpan
                        onClick={() => handleIngredientClick(ingredient)}
                      >
                        {label}
                      </UnderlinedSpan>
                    ) : (
                      <span>{label}</span>
                    )}
                  </TableIngredient>
                  <TableValue>
                    <span>
                      {props.twoScoop
                        ? parseFloat(props.prod[value]) * 2
                        : props.prod[value]}
                      {unit}
                    </span>
                  </TableValue>
                </tr>
                {selectedIngredient && selectedIngredient.value === value && (
                  <tr>
                    <td colSpan="2" style={{ border: "none" }}>
                      <TableDefinition>{definition}</TableDefinition>
                    </td>
                  </tr>
                )}
              </>
            );
          }
          return null;
        })}
      </tbody>
    </Table>
  );
};

const PreworkoutProduct = (props) => {
  let sign = "$";

  const [scoopToggle, setScoopToggle] = useState(true);

  const { brand, subtitle, flavor, servings, region } = useParams();
  const [productGroup, setProductGroup] = useState({
    category: null,
    brand: null,
    subtitle: null,
    type: null,
    region: null,
  });
  const [productLoading, setProductLoading] = useState(true);
  const [productData, setProductData] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(true);

  const closePopup = () => setPopupOpen(false);

  if (region === "GB") {
    sign = "£";
  }

  // console.log(preIngredients.length)

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (brand && subtitle && region) {
      setProductGroup({
        category: "preworkout",
        brand: brand,
        subtitle: subtitle,
        type: null,
        region: region,
      });
    }
  }, [brand, subtitle, region]);

  const constructQueryParams = (params) => {
    if (typeof params !== "object" || Array.isArray(params)) {
      throw new Error("Params should be an object with key-value pairs.");
    }

    let query = "/api/product-group?";
    for (const [key, value] of Object.entries(params)) {
      // console.log(`Processing key: ${key}, value: ${value}`); // Debugging line
      if (value !== null && value !== "") {
        query += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
      }
    }

    return query.endsWith("&") ? query.slice(0, -1) : query;
  };

  useEffect(() => {
    if (
      productGroup.category &&
      productGroup.brand &&
      productGroup.subtitle &&
      productGroup.region
    ) {
      setProductLoading(true);
      fetch(constructQueryParams(productGroup))
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setProductData(data);
          setProductLoading(false);
        })
        .catch((error) => {
          console.error("Error caught:", error);
          setProductLoading(false);
        });
    }
  }, [productGroup]);

  if (productLoading) {
    return (
      <center style={{ paddingTop: "150px" }}>
        <CircularProgress color="inherit" />
      </center>
    );
  }

  const prod = productData.find(
    (obj) =>
      obj.brand === brand &&
      obj.subtitle === subtitle &&
      obj.flavor === flavor &&
      obj.servings === servings
  );

  if (!prod) {
    return (
      <center style={{ paddingTop: "150px" }}>
        <CircularProgress color="inherit" />
      </center>
    );
  }

  const flavors = productData.filter(
    (obj) =>
      obj.brand === brand &&
      obj.subtitle === subtitle &&
      obj.servings === servings &&
      obj.stock === true
  );
  const sizes = productData.filter(
    (obj) =>
      obj.brand === brand &&
      obj.subtitle === subtitle &&
      obj.flavor === flavor &&
      obj.stock === true
  );
  const sizeCount = [
    ...new Set(
      productData
        .filter(
          (obj) =>
            obj.stock === true
        )
        .map((obj) => obj.servings)
    ),
  ].length;

  let backColor = "";
  let textColor = "";
  let previousValueRank = null;
  let adjustedRank = 1;
  let rank = 0;

  const originalIndex = props.preFilter.findIndex(
    (i) => i.brand === prod.brand && i.subtitle === prod.subtitle
  );

  for (let i = 0; i <= originalIndex; i++) {
    if (
      previousValueRank !== null &&
      props.preFilter[i].value_rank === previousValueRank
    ) {
      adjustedRank--;
    }
    if (i === originalIndex) {
      rank = adjustedRank;
    }
    previousValueRank = props.preFilter[i].value_rank;
    adjustedRank++;
  }

  if (rank === 1) {
    backColor = "#d4af37";
    textColor = "white";
  } else if (rank === 2) {
    backColor = "#C0C0C0";
    textColor = "white";
  } else if (rank === 3) {
    backColor = "#CD7F32";
    textColor = "white";
  } else {
    backColor = "#efefef";
    textColor = "black";
  }

  // green: #00a650
  // yreen: #cadb2a
  // red: #ed1b24

  const getStyle = (bool) => {
    return bool
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getTextStyle = (bool) => {
    return bool
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  const getPrev = () => {
    if (originalIndex <= 0) {
      return null;
    }

    const prevProduct = props.preFilter[originalIndex - 1];
    const link =
      "/preworkout/" +
      prevProduct.brand +
      "/" +
      prevProduct.subtitle +
      "/" +
      prevProduct.flavor +
      "/" +
      prevProduct.servings +
      "/" +
      prevProduct.region;

    return link;
  };

  const getNext = () => {
    if (originalIndex >= props.preFilter.length - 1) {
      return null;
    }

    const nextProduct = props.preFilter[originalIndex + 1];
    const link =
      "/preworkout/" +
      nextProduct.brand +
      "/" +
      nextProduct.subtitle +
      "/" +
      nextProduct.flavor +
      "/" +
      nextProduct.servings +
      "/" +
      nextProduct.region;

    return link;
  };

  const ScoopChange = () => {
    setScoopToggle(!scoopToggle);
  };

  // console.log(props.preFilter)

  return (
    <center>
      <Helmet>
        <title>{prod.brand + " - " + prod.subtitle}</title>
        <meta
          name="description"
          content={
            "View or compare " +
            prod.brand +
            "'s " +
            prod.subtitle +
            " to it's competitors."
          }
        />
        <meta
          name="keywords"
          content="best pre-workout, pre-workout rankings, pre-workout supplement, pre-workout value, high stim pre-workout, low stim pre-workout, pump formula, nootropic pre-workout, caffeine free pre-workout, top pre-workout, effective pre-workout ingredients, affordable pre-workout, pre-workout for energy, pre-workout for pump, workout supplements, pre-workout comparison, ingredient-based pre-workout, ingredient value pre-workout, pre-workout filter, pre-workout ingredient definitions"
        />
        <meta
          property="og:title"
          content={prod.brand + " - " + prod.subtitle}
        />
        <meta
          property="og:description"
          content={
            "View or compare " +
            prod.brand +
            "'s " +
            prod.subtitle +
            " to it's competitors."
          }
        />
      </Helmet>
      {rank > 0 && (
        <BackDiv>
          <StyledLink to={props.preFilterLink} method="get">
            <BackButton type="submit">
              <LeftArrowSVG />
              <BackText>Full Ranking Page</BackText>
            </BackButton>
          </StyledLink>
          {/* <BackText style={{color:"gray"}}>{rank} Place</BackText> */}
        </BackDiv>
      )}
      <ProductDiv backActive={rank > 0}>
        <ProductPageCard prod={prod} color={backColor} />
        {sizeCount > 1 && (
          <SizeDiv>
            {sizes.map(
              (item) =>
                item && (
                  <StyledLink
                    to={
                      "/preworkout/" +
                      item.brand +
                      "/" +
                      item.subtitle +
                      "/" +
                      item.flavor +
                      "/" +
                      item.servings +
                      "/" +
                      item.region
                    }
                    method="get"
                    key={item.servings}
                  >
                    <FlavorButton
                      type="submit"
                      style={getStyle(item.servings === prod.servings)}
                    >
                      <FlavorText
                        style={getTextStyle(item.servings === prod.servings)}
                      >
                        {item.twoscoop
                          ? parseFloat(item.servings) / 2 + "/" + item.servings
                          : item.servings}{" "}
                        Servings
                      </FlavorText>
                    </FlavorButton>
                  </StyledLink>
                )
            )}
          </SizeDiv>
        )}
        {/* <SubTitle>{subtitle}</SubTitle> */}
        <FlavorDiv>
          {flavors.map(
            (item) =>
              item && (
                <StyledLink
                  to={
                    "/preworkout/" +
                    item.brand +
                    "/" +
                    item.subtitle +
                    "/" +
                    item.flavor +
                    "/" +
                    item.servings +
                    "/" +
                    item.region
                  }
                  method="get"
                  key={item.flavor}
                >
                  <FlavorButton
                    type="submit"
                    style={getStyle(item.flavor === prod.flavor)}
                  >
                    <FlavorText
                      style={getTextStyle(item.flavor === prod.flavor)}
                    >
                      {item.flavor}
                    </FlavorText>
                  </FlavorButton>
                </StyledLink>
              )
          )}
        </FlavorDiv>
        <ArrowDiv>
          <StyledLink
            onClick={() => originalIndex > 0 && setProductLoading(true)}
            to={getPrev()}
            method="get"
          >
            <RankButton type="submit">
              <LeftArrowSVG color={originalIndex > 0 ? "black" : "white"} />
            </RankButton>
          </StyledLink>
          <Disclaimer style={{ marginTop: "5px" }}>
            {prod.shoplink &&
              !prod.shoplink.includes("amzn") &&
              !prod.shoplink.includes("amazon") &&
              !prod.shoplink.includes("lvnta") &&
              "*If price isn't accurate at checkout, use code SUPPHEAD."}
            {prod.shoplink &&
              !prod.shoplink.includes("amzn") &&
              !prod.shoplink.includes("amazon") &&
              !prod.shoplink.includes("lvnta") && <br />}
            These rankings are based on Nutritional statistics alone. <br />{" "}
            They have no consideration for flavor of product.
          </Disclaimer>
          <StyledLink
            onClick={() =>
              originalIndex + 1 < props.preFilter.length &&
              setProductLoading(true)
            }
            to={getNext()}
            method="get"
          >
            <RankButton type="submit">
              <RightArrowSVG
                color={
                  originalIndex + 1 === props.preFilter.length
                    ? "white"
                    : "black"
                }
              />
            </RankButton>
          </StyledLink>
        </ArrowDiv>
        <div style={{ padding: "10px 0px", width: "100%" }}>
          {prod.twoscoop ? (
            <>
              <TableButtonDiv>
                <TableButton>
                  <PlusSVG />
                  <CompareButton to={compareLink(prod)}>Compare</CompareButton>
                </TableButton>
                <TableButton>
                  <EyeSVG />
                  <ScoopButton onClick={ScoopChange}>
                    {scoopToggle ? "2 Scoops" : "1 Scoop"}
                  </ScoopButton>
                </TableButton>
              </TableButtonDiv>
              <NutritionTable
                prod={prod}
                twoScoop={scoopToggle}
                ingredients={props.ingredients}
              />
            </>
          ) : (
            <>
              <TableButtonDiv>
                <TableButton>
                  <PlusSVG />
                  <CompareButton to={compareLink(prod)}>Compare</CompareButton>
                </TableButton>
              </TableButtonDiv>
              <NutritionTable prod={prod} ingredients={props.ingredients} />
            </>
          )}
        </div>
        <Disclaimer>
          These statements have not been evaluated by the Food and Drug
          Administration. These products are not intended to diagnose, treat,
          cure or prevent any disease.
        </Disclaimer>
        {prod.shoplink && (
          <ShopNowButton
            color={backColor === "#efefef" ? "#2e2e2e" : backColor}
            textColor={"white"}
            price={sign + prod.price}
            shoplink={prod.shoplink}
            navBarOpen={props.navBarOpen}
          />
        )}
      </ProductDiv>
      {prod.dmaa && (
        <WarningPopUp
          ingredient={"DMAA"}
          description={
            "DMAA (1,3-dimethylamylamine) is a powerful stimulant that has been associated with serious health risks. This ingredient has been banned by several regulatory authorities, including the FDA, due to concerns about its safety. DMAA has been linked to adverse effects such as elevated blood pressure, heart attacks, arrhythmias, and, in extreme cases, death. We prioritize safety and compliance with regulatory guidelines, and therefore do not link to or endorse products that contain banned substances."
          }
          isOpen={isPopupOpen}
          onClose={closePopup}
        />
      )}
      {prod.dmha && !prod.dmaa && (
        <WarningPopUp
          ingredient={"DMHA"}
          description={
            "DMHA (2-Aminoisoheptane) is a powerful stimulant that has been associated with potential health risks. Due to safety concerns, this ingredient has been restricted or banned by various health authorities and sports organizations. DMHA has been linked to adverse effects such as elevated blood pressure, increased heart rate, cardiovascular strain, and, in extreme cases, serious heart complications. Continue at your own risk."
          }
          isOpen={isPopupOpen}
          onClose={closePopup}
        />
      )}
    </center>
  );
};

export default PreworkoutProduct;
