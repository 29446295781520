import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { GradientAnimation } from "../custom/animations";
import defaultproduct from "../images/defaultproduct.png";
import { ExternalSVG } from "../custom/svg";

const ProductCompareBackground = styled(Link)`
  display: block;
  text-decoration: none;
  color: black;
  width: 100%;
  height: 80px;
  border-radius: 15px;
  //   padding-top: 5px;
  position: static;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  //   background: linear-gradient(
  //     135deg,
  //     #efefef 0%,
  //     #efefef 45%,
  //     white 50%,
  //     #efefef 55%,
  //     #efefef 100%
  //   );
  background: ${(props) => props.color};
  background-size: 400% 400%;
  //   animation: ${GradientAnimation} 10s ease infinite;
  cursor: pointer;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 10px gray;
    }
  }
`;
const ProductCompareFront = styled.div`
  height: 70px;
  background-color: white;
  border-radius: 10px;
  position: static;
  margin: 5px;
`;
const ProductCompareOuterDiv = styled.div`
  display: flex;
  flex-direction: row;
  //   background: gray;
`;
const ProductCompareTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70px;
  width: 67%;
  //   margin-left: 0px;
  //   background: yellow;
`;
const CartDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ProductCompareBrand = styled.p`
  font-family: San Francisco Bold;
  font-size: 1rem;
  padding-left: 10px;
  line-height: 1.3rem;
  text-align: left;
  margin: 0px 0px;
  margin-bottom: 3px;
  line-height: 1.3rem;
`;
const ProductCompareFlavor = styled.p`
  font-family: San Francisco Regular;
  font-size: 0.9rem;
  text-align: left;
  color: gray;
  padding-left: 10px;
  margin: 0px 0px;
`;
const ProductImgDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  height: 70px;
  // background: yellow;
`;
const TestProductImgDiv = styled.div`
  display: flex;
  justify-content: center;
  // align-items: center;
  margin: 10px auto;
  width: 60px;
  //   background: yellow;
`;
const ProductImg = styled.img`
  display: block;
  height: 60px;
`;

// TODO ALT TAG CALCULATIONS FOR IMG
const ProductPageCard = ({ prod, color }) => {
  let subtitle;
  let altTag;

  if (
    prod.category === "preworkout" ||
    prod.category === "massgainer" ||
    prod.category === "aminoacid" ||
    prod.category === "energydrink" ||
    prod.category === "proteinbar" ||
    prod.category === "omega3" ||
    prod.category === "intraworkout" ||
    prod.category === "magnesium" ||
    prod.category === "turkesterone" ||
    prod.category === "multivitamin"
  ) {
    subtitle = prod.subtitle;
    altTag = prod.brand + " " + prod.subtitle + " " + prod.category;
  }

  if (prod.category === "electrolyte") {
    if (prod.type === "packet") {
      subtitle = "Electrolyte Packets";
    }
    if (prod.type === "powder") {
      subtitle = "Electrolyte Powder";
    }
    if (prod.type === "capsule") {
      subtitle = "Electrolyte Capsules";
    }
    if (prod.type === "tablet") {
      subtitle = "Electrolyte Tablets";
    }
    altTag = prod.brand + " " + prod.type + " " + prod.category;
  }

  if (prod.category === "proteinpowder") {
    if (prod.type === "Vegan") {
      subtitle = "Vegan";
    } else if (prod.type === "Clear") {
      subtitle = "Clear Whey Isolate";
    } else {
      subtitle = "Whey " + prod.type;
    }
    altTag = prod.brand + " " + prod.type + " " + prod.category;
  }

  if (prod.category === "betaalanine") {
    if (prod.type === "powder") {
      subtitle = "Beta Alanine Powder";
    }
    if (prod.type === "capsule") {
      subtitle = "Beta Alanine Capsules";
    }
    altTag = prod.brand + " " + prod.type + " " + prod.category;
  }

  if (prod.category === "citrulline") {
    if (prod.type === "powder") {
      subtitle = "Citrulline Powder";
    }
    if (prod.type === "capsule") {
      subtitle = "Citrulline Capsules";
    }
    altTag = prod.brand + " " + prod.type + " " + prod.category;
  }

  if (prod.category === "creatine") {
    if (prod.type === "Micronized Monohydrate") {
      subtitle = "Micronized Monohydrate";
    } else {
      subtitle = prod.type;
    }
    altTag = prod.brand + " " + prod.type + " " + prod.category;
  }

  return (
    <ProductCompareBackground
      color={color}
      to={prod.shoplink || window.location}
      {...(prod.shoplink
        ? { target: "_blank", rel: "noopener noreferrer" }
        : {})}
    >
      <ProductCompareFront>
        <ProductCompareOuterDiv>
          <ProductImgDiv>
            <TestProductImgDiv
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ProductImg
                src={prod ? prod.imglink : defaultproduct}
                style={{ height: "50px" }}
                alt={altTag}
              />
            </TestProductImgDiv>
          </ProductImgDiv>
          <ProductCompareTitleDiv>
            <div>
              <ProductCompareBrand>
                {prod ? prod.brand : "Product "}
              </ProductCompareBrand>
              <ProductCompareFlavor>
                {prod ? subtitle : "Select from search."}
              </ProductCompareFlavor>
            </div>
          </ProductCompareTitleDiv>
          {prod && (
            <CartDiv>
              <ExternalSVG color={"black"} />
            </CartDiv>
          )}
        </ProductCompareOuterDiv>
      </ProductCompareFront>
    </ProductCompareBackground>
  );
};

export default ProductPageCard;
