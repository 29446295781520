import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { GradientAnimation } from "../custom/animations";
import {
  DollarSVG,
  InstaSVG,
  TickerDownSVG,
  TickerUpSVG,
  ChartSVG,
  ListSVG,
  SearchSVG,
} from "../custom/svg";
import podiumGlyph from "../images/podiumglyph.png";

const ListRoundedSquare = styled(Link).attrs((props) => ({
  color: props.color,
}))`
  display: block;
  text-decoration: none;
  color: black;
  width: 95%;
  height: 100px;
  border-radius: 15px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  background: ${(props) =>
    props.rank <= 3
      ? "linear-gradient(135deg, " +
        props.color +
        " 0%, " +
        props.color +
        " 45%, white 50%, " +
        props.color +
        " 55%, " +
        props.color +
        " 100%)"
      : "#efefef"};
  background-size: 400% 400%;
  animation: ${GradientAnimation} 10s ease infinite;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 10px gray;
    }
  }
`;
const FrontRoundedSquare = styled.div`
  height: 90px;
  background-color: white;
  border-radius: 10px;
  position: static;
  margin: 5px;
`;
const ListOuterDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  width: 100%;
`;
const ListContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  gap: 10px;
`;
const ListImageDiv = styled.div`
  height: 80%;
  aspect-ratio: 1 / 1;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ListImage = styled.img`
  width: 28px;
`;
const ListRank = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.3rem;
  position: static;
  margin-right: auto;
  margin-left: 0;

  @media (max-width: 760px) {
    font-size: 1rem;
  }
`;
const MobileTitleDiv = styled.div`
  //margin-top: 5px;
  height: auto;
  min-width: 80%;
  // margin-top: 10px;
  // margin-top: 10px;
`;
const PodiumBrand = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.2rem;
  text-align: left;
  margin: 0px 0px;
`;
const PodiumFlavor = styled.p`
  font-family: San Francisco Regular;
  font-size: 0.9rem;
  text-align: left;
  color: gray;
  padding-left: 10px;
  margin-top: 0px;
  margin-bottom: 3px;
`;

const MobileInfoDiv = styled.div`
  display: flex;
  font-family: San Francisco Regular;
  flex-direction: row;
  align-items; center;
  width: 100%;
  padding-left: 5px;

  @media (min-width: 760px) {
    display: none;
  }
`;
const DesktopInfoDiv = styled.div`
  display: flex;
  font-family: San Francisco Regular;
  flex-direction: row;
  align-items; center;
  width: 100%;
  padding-left: 0px;

  // @media (min-width: 760px) {
  //   display: none;
  // }
`;
const ListItemBar = styled.div`
  display: block;
  width: 2px;
  border-radius: 50px;
  background-color: ${(props) => props.color};
  margin-right: 5px;
  height: 72px;
`;
const ListStatsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 150px;
  height: 70px;

  @media (max-width: 760px) {
    display: none;
  }
`;
const ListStats = styled.div`
  font-family: San Francisco Regular;
  font-size: 0.9rem;
  color: gray;
`;

const DesktopListInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LoadingDiv = styled.div`
  display: flex;
  width: 80%;
  height: 100%;
  align-items: center;
`;
const BrandTickerDiv = styled.div`
  display: flex;
  padding-left: 10px;
  margin-bottom: 5px;
  // gap: 10px;
  justify-content: left;
  align-items: center;
`;
const TickerDigit = styled.p`
  margin: 0px;
  margin-left: 5px;
  font-size: 12px;
  font-family: San Francisco Bold;
`;
const TickerUpDiv = styled.div`
  display: flex;
  justify-content: center;
  transform: translateY(-1px);
`;
const TickerDownDiv = styled.div`
  display: flex;
  justify-content: center;
  transform: translateY(1px);
`;

const ElectrolyteTypes = [
  {
    value: "powder",
    label: "Powder",
  },
  {
    value: "capsule",
    label: "Capsules",
  },
  {
    value: "packet",
    label: "Packets",
  },
  {
    value: "tablet",
    label: "Tablets",
  },
];

const BrandListPlaceholder = (props) => {
  let color = "#efefef";
  let link = "";
  let title = "";
  let subtitle = "";

//   if (props.filterRank === 1) {
//     color = "#d4af37";
//   } else if (props.filterRank === 2) {
//     color = "#C0C0C0";
//   } else if (props.filterRank === 3) {
//     color = "#CD7F32";
//   } else {
//     color = "#efefef";
//   }

  if (props.index === 1) {
    title = "Liking SUPPHEAD?";
    subtitle = "Follow our Instagram!";
    link = "https://www.instagram.com/supphead";
  } else if (props.index === 0) {
    title = "Missing Products?";
    subtitle = "Email us at contact@supphead.com";
    link = "mailto:contact@supphead.com?subject=Product-Request";
  } else if (props.index === 2) {
    title = "Advanced Search";
    subtitle = "Sign up to gain access!";
    link = "/account";
  }

  return (
    <ListRoundedSquare
      as="a"
      href={link} // Use `href` for anchor tags
      target="_blank" // This ensures the link opens in a new tab
      rel="noopener noreferrer" // For security reasons
      color={color}
      rank={props.filterRank}
    >
      <FrontRoundedSquare>
        <ListOuterDiv>
          <ListContentDiv>
            <ListImageDiv>
              {/* <ListImage src={podiumGlyph} /> */}
              {props.index === 1 && <InstaSVG />}
              {props.index === 0 && <ListImage src={podiumGlyph} />}
              {props.index === 2 && <SearchSVG />}
            </ListImageDiv>
            {/* <ListRank>
              <ListRank>{props.filterRank}.</ListRank>
            </ListRank> */}
            <MobileTitleDiv>
              <BrandTickerDiv>
                <PodiumBrand>{title}</PodiumBrand>
              </BrandTickerDiv>
              <PodiumFlavor>{subtitle}</PodiumFlavor>
            </MobileTitleDiv>
          </ListContentDiv>
        </ListOuterDiv>
      </FrontRoundedSquare>
    </ListRoundedSquare>
  );
};

export default BrandListPlaceholder;
