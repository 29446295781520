import React, { useState, useEffect } from "react";
import styled from "styled-components";
import menulogo from "../images/hamburgermenu.png";
import { Link, useLocation } from "react-router-dom";
import podiumglyph from "../images/podiumglyph.png";
import user from "../images/user.png";
import suppheadlogo from "../images/supphead.webp";
import mainlogo from "../images/supphead.webp";

const NavBarContainer = styled.nav`
  background-color: white;
  font-family: San Francisco Regular;
  overflow: hidden;
  color: white;
  transition-duration: 0.25s;
  height: 75px;
  //background: #efefef;
  height: ${(props) =>
    props.open ? "150px" : "75px"}; // 15px less than dividing by items
  // border-bottom: solid #efefef 1px;

  @media (max-width: 760px) {
    height: ${(props) => (props.open ? "825px" : "75px")};
    border-bottom: solid #efefef 1px;
  }
`;
const NavBarContent = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  height: 75px;
`;
const NavBarDropdown = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  height: 750px;
  background: #efefef;
  // margin-bottom: 20px;

  @media (min-width: 760px) {
    display: none;
  }
`;
const NavBarDropdownDesktop = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  font-size: 20px;
  height: 75px;
  background: #efefef;
  // margin-bottom: 20px;

  @media (max-width: 760px) {
    display: none;
  }
`;
const NavLogo = styled.img`
  height: 20px;
  padding: 30px;
  width: auto;
  cursor: pointer;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  -webkit-tap-highlight-color: transparent; /* Disable tap highlight */
  -webkit-touch-callout: none; /* Disable touch callout */
`;
const NavMenu = styled.img`
  height: 20px;
  padding: 30px;
  width: auto;
  cursor: pointer;

  @media (min-width: 760px) {
    display: none;
  }
`;
const NavLinkDivDesktop = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px;
  gap: 20px;

  @media (max-width: 760px) {
    display: none;
  }
`;
const NavLinkDivMobile = styled.div`
  display: flex;
  flex-direction: column;
  // padding-top: 10px;
  // padding-bottom: 10px;
  padding-left: 35px;
  gap: 20px;

  @media (min-width: 760px) {
    display: none;
  }
`;
const NavLinks = styled(Link)`
  text-decoration: none;
  color: black;

  &:hover {
    text-decoration: underline;
  }
`;
const Text = styled.p`
  font-size: 14px;
  margin: auto;
`;

const NavBar = (props) => {
  const location = useLocation();
  const pathname = location.pathname;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const img1 = new Image();
    const img2 = new Image();
    img1.src = user;
    img2.src = suppheadlogo;
  }, []);

  const triggerOpen = () => {
    setOpen((prevState) => {
      props.setNavBarOpen(!prevState);
      return !prevState;
    });
  };

  return (
    <NavBarContainer open={open}>
      <NavBarContent>
        {pathname === "/" ? (
          <Link to="/account">
            <NavLogo src={user} isVisible={pathname === "/"} alt="user" />
          </Link>
        ) : (
          <Link to="/" onClick={open && triggerOpen}>
            <NavLogo
              src={suppheadlogo}
              isVisible={pathname !== "/"}
              alt="supphead logo"
            />
          </Link>
        )}
        <NavMenu src={menulogo} onClick={triggerOpen} alt="menu button" />
        <NavLinkDivDesktop>
          <NavLinks onClick={triggerOpen}>
            <Text>All Supplements</Text>
          </NavLinks>
          <NavLinks to="/compare" onClick={open && triggerOpen}>
            <Text>Compare Products</Text>
          </NavLinks>
          <NavLinks to="/article" onClick={open && triggerOpen}>
            <Text>Articles</Text>
          </NavLinks>
          <NavLinks to="/about" onClick={open && triggerOpen}>
            <Text>About</Text>
          </NavLinks>
          <NavLinks to="/account" onClick={open && triggerOpen}>
            {" "}
            {/* swap to account when logged in*/}
            {!props.isLoggedIn ? (
              <Text>Login or Sign Up</Text>
            ) : (
              <Text>Account</Text>
            )}
          </NavLinks>
          {/* <NavLinks href="/login">
            <Text>Log In</Text>
          </NavLinks>
          <NavLinks href="/signup">
            <Text>Sign Up</Text>
          </NavLinks> */}
        </NavLinkDivDesktop>
      </NavBarContent>
      <NavBarDropdownDesktop>
        <NavLinkDivDesktop style={{ alignItems: "right" }}>
          <NavLinks to="/aminoacid/examples" onClick={triggerOpen}>
            <Text>Amino Acids</Text>
          </NavLinks>
          <NavLinks to="/betaalanine/examples" onClick={triggerOpen}>
            <Text>Beta Alanine</Text>
          </NavLinks>
          <NavLinks to="/citrulline/examples" onClick={triggerOpen}>
            <Text>Citrulline</Text>
          </NavLinks>
          <NavLinks to="/creatine/examples" onClick={triggerOpen}>
            <Text>Creatine</Text>
          </NavLinks>
          <NavLinks to="/electrolyte/examples" onClick={triggerOpen}>
            <Text>Electrolytes</Text>
          </NavLinks>
          <NavLinks to="/energydrink/examples" onClick={triggerOpen}>
            <Text>Energy Drinks</Text>
          </NavLinks>
          <NavLinks to="/intraworkout/examples" onClick={triggerOpen}>
            <Text>Intra-Workout</Text>
          </NavLinks>
          <NavLinks to="/magnesium/examples" onClick={triggerOpen}>
            <Text>Magnesium</Text>
          </NavLinks>
          <NavLinks to="/massgainer" onClick={triggerOpen}>
            <Text>Mass Gainer</Text>
          </NavLinks>
          <NavLinks to="/multivitamin/examples" onClick={triggerOpen}>
            <Text>Multivitamin</Text>
          </NavLinks>
          <NavLinks to="/omega3/examples" onClick={triggerOpen}>
            <Text>Omega-3</Text>
          </NavLinks>
          <NavLinks to="/preworkout/examples" onClick={triggerOpen}>
            <Text>Pre-Workout</Text>
          </NavLinks>
          <NavLinks to="/proteinbar/examples" onClick={triggerOpen}>
            <Text>Protein Bars</Text>
          </NavLinks>
          <NavLinks to="/proteinpowder/examples" onClick={triggerOpen}>
            <Text>Protein Powder</Text>
          </NavLinks>
          <NavLinks to="/turkesterone" onClick={triggerOpen}>
            <Text>Turkesterone</Text>
          </NavLinks>
        </NavLinkDivDesktop>
      </NavBarDropdownDesktop>
      <NavBarDropdown>
        <NavLinkDivMobile>
          <NavLinks to="/aminoacid/examples" onClick={triggerOpen}>
            <Text>Amino Acids</Text>
          </NavLinks>
          <NavLinks to="/betaalanine/examples" onClick={triggerOpen}>
            <Text>Beta Alanine</Text>
          </NavLinks>
          <NavLinks to="/citrulline/examples" onClick={triggerOpen}>
            <Text>Citrulline</Text>
          </NavLinks>
          <NavLinks to="/creatine/examples" onClick={triggerOpen}>
            <Text>Creatine</Text>
          </NavLinks>
          <NavLinks to="/electrolyte/examples" onClick={triggerOpen}>
            <Text>Electrolytes</Text>
          </NavLinks>
          <NavLinks to="/energydrink/examples" onClick={triggerOpen}>
            <Text>Energy Drinks</Text>
          </NavLinks>
          <NavLinks to="/intraworkout/examples" onClick={triggerOpen}>
            <Text>Intra-Workout</Text>
          </NavLinks>
          <NavLinks to="/magnesium/examples" onClick={triggerOpen}>
            <Text>Magnesium</Text>
          </NavLinks>
          <NavLinks to="/massgainer" onClick={triggerOpen}>
            <Text>Mass Gainer</Text>
          </NavLinks>
          <NavLinks to="/multivitamin/examples" onClick={triggerOpen}>
            <Text>Multivitamin</Text>
          </NavLinks>
          <NavLinks to="/omega3/examples" onClick={triggerOpen}>
            <Text>Omega-3</Text>
          </NavLinks>
          <NavLinks to="/preworkout/examples" onClick={triggerOpen}>
            <Text>Pre-Workout</Text>
          </NavLinks>
          <NavLinks to="/proteinbar/examples" onClick={triggerOpen}>
            <Text>Protein Bars</Text>
          </NavLinks>
          <NavLinks to="/proteinpowder/examples" onClick={triggerOpen}>
            <Text>Protein Powder</Text>
          </NavLinks>
          <NavLinks to="/turkesterone" onClick={triggerOpen}>
            <Text>Turkesterone</Text>
          </NavLinks>
          <NavLinks to="/compare" onClick={triggerOpen}>
            <Text>Compare</Text>
          </NavLinks>
          <NavLinks to="/article" onClick={triggerOpen}>
            <Text>Articles</Text>
          </NavLinks>
          <NavLinks to="/about" onClick={triggerOpen}>
            <Text>About</Text>
          </NavLinks>
          <NavLinks to="/account" onClick={triggerOpen}>
            {" "}
            {/* swap to account when logged in*/}
            {!props.isLoggedIn ? (
              <Text>Login or Sign Up</Text>
            ) : (
              <Text>Account</Text>
            )}
          </NavLinks>
          {/* <NavLinks href="/login">
            <Text>Log In</Text>
          </NavLinks>
          <NavLinks href="/signup">
            <Text>Sign Up</Text>
          </NavLinks> */}
        </NavLinkDivMobile>
      </NavBarDropdown>
    </NavBarContainer>
  );
};

export default NavBar;
