import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  text-decoration: none; /* Remove underline */
  color: inherit; /* Ensure text color is inherited */
`;
export const UnderlinedSpan = styled.span`
  text-decoration: underline; /* Add underline to the span */
  color: inherit; /* Ensure text color is inherited */
  cursor: pointer;
`;
export const ProductDiv = styled.div`
  width: 90.25%;
  position: static;
  //   padding-top: 10px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 70px;
  padding: ${(props) =>
    props.backActive ? "0px 0px 30px 0px" : "10px 0px 30px 0px"};

  @media (min-width: 760px) {
    width: 40%;
  }
`;
export const Disclaimer = styled.p`
  max-width: 350px;
  color: gray;
  //letter-spacing: 0.7px;
  font-size: 10px;
  font-family: San Francisco Regular;

  @media (max-width: 760px) {
    margin-top: 0px;
  }
`;
export const Table = styled.table`
  border-collapse: seperate;
  position: static;
  width: 90%;
  padding: 0px 0px 0px 0px;
  margin-left: auto;
  margin-right: auto;
  font-family: San Francisco Regular;

  & span {
    float: left;
    margin: 0.5rem;
  }

  & th,
  td {
    border-style: dashed;
    border: 1px solid #ccc;
  }

  @media (max-width: 760px) {
    width: 90%;
  }
`;
export const FlavorDiv = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  font-family: San Francisco Bold;
  width: 100%;
  padding-left: 10px;
  padding-top: 5px;
`;
export const SizeDiv = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  padding-left: 10px;
  padding-top: 5px;
`;
export const FlavorButton = styled.button`
  all: unset;
  display: block;
  text-decoration: none;
  cursor: pointer;
  border-radius: 10px;
  padding: 2px;
  border: none;
  background: #efefef;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  transition-duration: 0.25s;
  box-shadow: ${(props) => props.active && "0px 0px 5px black"};

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;
export const FlavorText = styled.p`
  font-size: 0.9rem;
  margin: 5px;
  position: static;
  line-height: 20px;
  color: black;
  font-family: San Francisco Bold;
`;
export const ArrowDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
`;
export const RankButton = styled.button`
  all: unset;
  // text-decoration: underline;
  // color: gray;
  // background: gray;
  display: flex;
  align-items: center;

  &:active {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;
  }
`;
export const BackDiv = styled.div`
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  line-height: 14px;
  font-family: San Francisco Regular;
  padding-bottom: 10px;
  // background: gray;

  @media (max-width: 760px) {
    width: 90%;
    height: 50px;
    line-height: 12px;
    padding-bottom: 0px;
  }
`;
export const BackButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  gap: 5px;

  &:active {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;
  }
`;
export const BackText = styled.p`
  font-size: 14px;
  color: black;
  // margin: 0px;

  @media (max-width: 760px) {
    font-size: 12px;
  }
`;
export const TableIngredient = styled.td`
  width: 65%;
`;

export const TableValue = styled.td`
  width: 35%;
`;
export const TableDefinition = styled.p`
  color: gray;
  font-size: 14px;
  margin: 10px 5px 10px 5px;
  font-family: San Francisco Regular;
`;
export const TableButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  //   background: yellow;

  @media (max-width: 760px) {
    width: 85%;
  }
`;
export const TableButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  &:hover {
    text-decoration: underline;
  }
`;
export const ScoopButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  color: black;
  // text-decoration: underline;

  &:hover {
    cursor: pointer;
  }

  &:active {
    text-decoration: underline;
  }
`;
export const CompareButton = styled(Link)`
  all: unset;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  color: black;
  // text-decoration: underline;

  &:hover {
    cursor: pointer;
  }

  &:active {
    text-decoration: underline;
  }
`;
