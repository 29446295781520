import React, { useState, useEffect } from "react";
import { ReactTyped } from "react-typed";
import PopUp from "../components/PopUp";
import BrandListCompact from "../components/BrandListCompact";
import { tieBreaker, searchPageGen } from "../custom/functions";
import { IngredientEyeSVG, LinkSVG } from "../custom/svg";
import podiumGlyph from "../images/podiumglyph.png";
import { LiveIndicator } from "../custom/animations";
import { Helmet } from "react-helmet-async";
import AdPopUp from "../components/AdPopUp";
import {
  BackDiv,
  BackButton,
  BackText,
  ResponseDiv,
  ResponseTitle,
  ResponseSubtitle,
  SvgWrapper,
  PodiumDivMobile,
  FilterButtonDiv,
  ShowMoreButton,
  PodiumGlyphImg,
  TabBar,
} from "../css/examplePage";

const CitrullineExample = (props) => {
  const [exampleLoading, setExampleLoading] = useState(true);
  const [exampleOne, setExampleOne] = useState([]);
  const [exampleTwo, setExampleTwo] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupIngredients, setPopupIngredients] = useState(null);
  const [popupIngredientList, setPopupIngredientList] = useState(null);

  const closePopup = () => setPopupOpen(false);

  const handlePopUp = (ingredients, ingredientList) => {
    setPopupOpen(true);
    setPopupIngredients(ingredients);
    setPopupIngredientList(ingredientList);
  };

  useEffect(() => {
    if (!props.userCountry) return;

    const fetchExampleData = (url) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.ok) {
            const data = await response.json();
            resolve(data);
          } else {
            reject(new Error(`HTTP error! status: ${response.status}`));
          }
        } catch (error) {
          reject(error);
        }
      });
    };

    const fetchAllExamples = (userCountry) => {
      let fetchOne;
      let fetchTwo;

      fetchOne = fetchExampleData(
        `/api/citrulline?filter=powder&region=` + userCountry
      );
      fetchTwo = fetchExampleData(
        `/api/citrulline?filter=capsule&region=` + userCountry
      );

      setExampleLoading(true);

      Promise.all([fetchOne, fetchTwo])
        .then(([dataOne, dataTwo]) => {
          setExampleOne(dataOne);
          setExampleTwo(dataTwo);
          setExampleLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchAllExamples(props.userCountry);
  }, [props.userCountry]);

  return (
    <center>
      <Helmet>
        <title>Citrulline Ranking Examples</title>
        <meta
          name="description"
          content="Discover the best value Citrulline with our value ranking system. Whether you want a powder or capsule form, our rankings highlight top Citrulline based on value."
        />
        <meta
          name="keywords"
          content="best citrulline, citrulline rankings, citrulline supplement, citrulline value, top citrulline, effective citrulline, affordable citrulline, workout supplements, citrulline comparison, ingredient-based citrulline, ingredient value citrulline, citrulline definition"
        />
        <meta property="og:title" content="Citrulline Ranking Examples" />
        <meta
          property="og:description"
          content="Discover the best value Citrulline with our value ranking system. Whether you want a powder or capsule form, our rankings highlight top Citrulline based on value."
        />
      </Helmet>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton to={"/citrulline"}>
          <LinkSVG />
          <BackText>Citrulline Filter</BackText>
        </BackButton>
        <LiveIndicator />
      </BackDiv>
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Citrulline Powder"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() =>
            handlePopUp(
              ["citrulline"],
              props.ingredients.filter((i) => i.category === "preworkout")
            )
          }
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {"Ranked by Citrulline value."}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleOne).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleOne}
                  filterLink={searchPageGen(
                    "citrulline",
                    "powder",
                    null,
                    null,
                    null,
                    [],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setCitrullineFilter}
                  setFilterLink={props.setCitrullineFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "citrulline",
            "powder",
            null,
            null,
            null,
            [],
            [],
            [],
            props.ingredients
          )}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Citrulline Capsules"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() =>
            handlePopUp(
              ["citrulline"],
              props.ingredients.filter((i) => i.category === "preworkout")
            )
          }
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {"Ranked by Citrulline value."}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleTwo).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleTwo}
                  filterLink={searchPageGen(
                    "citrulline",
                    "capsule",
                    null,
                    null,
                    null,
                    [],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setCitrullineFilter}
                  setFilterLink={props.setCitrullineFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "citrulline",
            "capsule",
            null,
            null,
            null,
            [],
            [],
            [],
            props.ingredients
          )}
          style={{ marginBottom: "25px" }}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      {/* <LastTabBar /> */}
      <PopUp
        isOpen={isPopupOpen}
        onClose={closePopup}
        ingredients={popupIngredients}
        ingredientList={popupIngredientList}
      />
      <AdPopUp canOpen={!isPopupOpen} accessories={props.accessories} />
    </center>
  );
};

export default CitrullineExample;
