import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { trackEvent } from "../custom/analytics";
import { LeftArrowSVG, PlusSVG, RightArrowSVG } from "../custom/svg";
import { CircularProgress } from "@mui/material";
import ShopNowButton from "../components/ShopNowButton";
import { compareLink } from "../custom/functions";
import { Helmet } from "react-helmet-async";
import {
  StyledLink,
  UnderlinedSpan,
  ProductDiv,
  Disclaimer,
  Table,
  SizeDiv,
  FlavorButton,
  FlavorText,
  ArrowDiv,
  RankButton,
  BackDiv,
  BackButton,
  BackText,
  TableIngredient,
  TableValue,
  TableDefinition,
  TableButtonDiv,
  TableButton,
  CompareButton,
} from "../css/productPage";
import ProductPageCard from "../components/ProductPageCard";

const NutritionTable = (props) => {
  const [selectedIngredient, setSelectedIngredient] = useState(null);

  const handleIngredientClick = (ingredient) => {
    if (ingredient === selectedIngredient) {
      return setSelectedIngredient(null);
    }
    setSelectedIngredient(ingredient);
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>
            <span>Nutrition: </span>
          </th>
          <th>
            <span>Amount: </span>
          </th>
          {/* <th>
              <span>Category Rank: </span>
            </th> */}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <span>Servings</span>
          </td>
          <td>
            <span>{props.servings}</span>
          </td>
        </tr>
        <tr>
          <TableIngredient>
            <UnderlinedSpan onClick={() => handleIngredientClick("citrulline")}>
              Citrulline
            </UnderlinedSpan>
          </TableIngredient>
          <TableValue>
            <span>{props.servingSize}mg</span>
          </TableValue>
        </tr>
        {selectedIngredient && selectedIngredient === "citrulline" && (
          <tr>
            <td colSpan="2" style={{ border: "none" }}>
              <TableDefinition>
                {
                  props.ingredients.find((item) => item.value === "citrulline")
                    .definition
                }
              </TableDefinition>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

const CitrullineProduct = (props) => {
  let sign = "$";

  const { brand, type, flavor, servings, region } = useParams();
  const [productGroup, setProductGroup] = useState({
    category: null,
    brand: null,
    subtitle: null,
    type: null,
    region: null,
  });
  const [productLoading, setProductLoading] = useState(true);
  const [productData, setProductData] = useState([]);

  if (region === "GB") {
    sign = "£";
  }

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (brand && type && region) {
      setProductGroup({
        category: "citrulline",
        brand: brand,
        subtitle: null,
        type: type,
        region: region,
      });
    }
  }, [brand, type, region]);

  const constructQueryParams = (params) => {
    if (typeof params !== "object" || Array.isArray(params)) {
      throw new Error("Params should be an object with key-value pairs.");
    }

    let query = "/api/product-group?";
    for (const [key, value] of Object.entries(params)) {
      // console.log(`Processing key: ${key}, value: ${value}`); // Debugging line
      if (value !== null && value !== "") {
        query += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
      }
    }

    return query.endsWith("&") ? query.slice(0, -1) : query;
  };

  useEffect(() => {
    if (
      productGroup.category &&
      productGroup.brand &&
      productGroup.type &&
      productGroup.region
    ) {
      setProductLoading(true);
      fetch(constructQueryParams(productGroup))
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setProductData(data);
          setProductLoading(false);
        })
        .catch((error) => {
          console.error("Error caught:", error);
          setProductLoading(false);
        });
    }
  }, [productGroup]);

  if (productLoading) {
    return (
      <center style={{ paddingTop: "150px" }}>
        <CircularProgress color="inherit" />
      </center>
    );
  }

  // problem here maybe with flavor?
  const prod = productData.find(
    (obj) =>
      obj.brand === brand &&
      obj.type === type &&
      (obj.flavor === null ? "null" : obj.flavor) === flavor &&
      obj.servings === parseInt(servings)
  );

  if (!prod) {
    return (
      <center style={{ paddingTop: "150px" }}>
        <CircularProgress color="inherit" />
      </center>
    );
  }

  // const flavors = productData.filter(
  //   (obj) =>
  //     obj.brand === brand &&
  //     obj.type === type &&
  //     obj.servings === servings &&
  //     obj.stock === true
  // );

  const sizes = productData.filter(
    (obj) =>
      obj.brand === brand &&
      obj.type === type &&
      (obj.flavor === null ? "null" : obj.flavor) === flavor &&
      obj.stock === true
  );
  const sizeCount = [
    ...new Set(
      productData
        .filter(
          (obj) =>
            obj.stock === true
        )
        .map((obj) => obj.servings)
    ),
  ].length;

  let backColor = "";
  let textColor = "";
  const rank =
    props.citrullineFilter.findIndex(
      (obj) => obj.brand === prod.brand && obj.type === prod.type
    ) + 1;

  if (rank === 1) {
    backColor = "#d4af37";
    textColor = "white";
  } else if (rank === 2) {
    backColor = "#C0C0C0";
    textColor = "white";
  } else if (rank === 3) {
    backColor = "#CD7F32";
    textColor = "white";
  } else {
    backColor = "#efefef";
    textColor = "black";
  }
  // green: #00a650
  // yreen: #cadb2a
  // red: #ed1b24

  const getStyle = (bool) => {
    return bool
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getTextStyle = (bool) => {
    return bool
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  // BUG! bug when brand + type match stuck on loading because no trigger
  const getPrev = () => {
    if (rank === 0 || rank === 1) {
      return null;
    }

    const link =
      "/citrulline/" +
      props.citrullineFilter[rank - 2].brand +
      "/" +
      props.citrullineFilter[rank - 2].type +
      "/" +
      props.citrullineFilter[rank - 2].flavor +
      "/" +
      props.citrullineFilter[rank - 2].servings +
      "/" +
      props.citrullineFilter[rank - 2].region;

    return link;
  };

  const getNext = () => {
    if (rank === 0 || rank === props.citrullineFilter.length) {
      return null;
    }

    const link =
      "/citrulline/" +
      props.citrullineFilter[rank].brand +
      "/" +
      props.citrullineFilter[rank].type +
      "/" +
      props.citrullineFilter[rank].flavor +
      "/" +
      props.citrullineFilter[rank].servings +
      "/" +
      props.citrullineFilter[rank].region;

    return link;
  };

  return (
    <center>
      <Helmet>
        <title>{prod.brand + " - Citrulline " + (prod.type === "powder" ? "Powder" : "Capsules")}</title>
        <meta
          name="description"
          content={
            "View or compare " +
            prod.brand +
            "'s Citrulline " +
            (prod.type === "powder" ? "Powder" : "Capsules") +
            " to it's competitors."
          }
        />
        <meta
          name="keywords"
          content="best citrulline, citrulline rankings, citrulline supplement, citrulline value, top citrulline, effective citrulline, affordable citrulline, workout supplements, citrulline comparison, ingredient-based citrulline, ingredient value citrulline, citrulline definition"
        />
        <meta property="og:title" content={prod.brand + " - Citrulline " + (prod.type === "powder" ? "Powder" : "Capsules")} />
        <meta
          property="og:description"
          content={
            "View or compare " +
            prod.brand +
            "'s Citrulline " +
            (prod.type === "powder" ? "Powder" : "Capsules") +
            " to it's competitors."
          }
        />
      </Helmet>
      {rank > 0 && (
        <BackDiv>
          <StyledLink to={props.citrullineFilterLink} method="get">
            <BackButton type="submit">
              <LeftArrowSVG />
              <BackText>Full Ranking Page</BackText>
            </BackButton>
          </StyledLink>
          {/* <BackText style={{color:"gray"}}>{rank} Place</BackText> */}
        </BackDiv>
      )}
      <ProductDiv backActive={rank > 0}>
        <ProductPageCard prod={prod} color={backColor} />
        {sizeCount > 1 && (
          <SizeDiv>
            {sizes.map(
              (item) =>
                item && (
                  <StyledLink
                    to={
                      "/citrulline/" +
                      item.brand +
                      "/" +
                      item.type +
                      "/" +
                      item.flavor +
                      "/" +
                      item.servings +
                      "/" +
                      item.region
                    }
                    method="get"
                    key={item.brand + " " + item.flavor + " " + item.servings}
                  >
                    <FlavorButton
                      type="submit"
                      style={getStyle(item.servings === prod.servings)}
                    >
                      <FlavorText
                        style={getTextStyle(item.servings === prod.servings)}
                      >
                        {item.servings} Servings
                      </FlavorText>
                    </FlavorButton>
                  </StyledLink>
                )
            )}
          </SizeDiv>
        )}
        <ArrowDiv>
          <StyledLink
            onClick={() => rank - 1 > 0 && setProductLoading(true)}
            to={getPrev()}
            method="get"
          >
            <RankButton type="submit">
              <LeftArrowSVG color={rank - 1 > 0 ? "black" : "white"} />
            </RankButton>
          </StyledLink>
          <Disclaimer style={{ marginTop: "5px" }}>
            {prod.shoplink &&
              !prod.shoplink.includes("amzn") &&
              !prod.shoplink.includes("amazon") &&
              !prod.shoplink.includes("lvnta") &&
              "*If price isn't accurate at checkout, use code SUPPHEAD."}
            {prod.shoplink &&
              !prod.shoplink.includes("amzn") &&
              !prod.shoplink.includes("amazon") &&
              !prod.shoplink.includes("lvnta") && <br />}
            These rankings are based on Nutritional statistics alone. <br />{" "}
            They have no consideration for flavor of product.
          </Disclaimer>
          <StyledLink
            onClick={() =>
              rank < props.citrullineFilter.length && setProductLoading(true)
            }
            to={getNext()}
            method="get"
          >
            <RankButton type="submit">
              <RightArrowSVG
                color={rank === props.citrullineFilter.length ? "white" : "black"}
              />
            </RankButton>
          </StyledLink>
        </ArrowDiv>
        <div style={{ padding: "10px 0px", width: "100%" }}>
          <TableButtonDiv>
            <TableButton>
              <PlusSVG />
              <CompareButton to={compareLink(prod)}>Compare</CompareButton>
            </TableButton>
          </TableButtonDiv>
          <NutritionTable
            servingSize={prod.servingsize}
            servings={prod.servings}
            ingredients={props.ingredients.filter(
              (i) => i.category === "preworkout"
            )}
          />
        </div>
        <Disclaimer>
          These statements have not been evaluated by the Food and Drug
          Administration. These products are not intended to diagnose, treat,
          cure or prevent any disease.
        </Disclaimer>
        {prod.shoplink && (
          <ShopNowButton
            color={backColor === "#efefef" ? "#2e2e2e" : backColor}
            textColor={"white"}
            price={sign + prod.price}
            shoplink={prod.shoplink}
            navBarOpen={props.navBarOpen}
          />
        )}
      </ProductDiv>
    </center>
  );
};

export default CitrullineProduct;
