import React, { useState, useEffect } from "react";
import { ReactTyped } from "react-typed";
import PopUp from "../components/PopUp";
import BrandListCompact from "../components/BrandListCompact";
import {
  tieBreaker,
  searchPageGen,
} from "../custom/functions";
import { IngredientEyeSVG, LinkSVG } from "../custom/svg";
import podiumGlyph from "../images/podiumglyph.png";
import { LiveIndicator } from "../custom/animations";
import { Helmet } from "react-helmet-async";
import AdPopUp from "../components/AdPopUp";
import {
  BackDiv,
  BackButton,
  BackText,
  ResponseDiv,
  ResponseTitle,
  ResponseSubtitle,
  SvgWrapper,
  PodiumDivMobile,
  FilterButtonDiv,
  ShowMoreButton,
  PodiumGlyphImg,
  TabBar,
} from "../css/examplePage";

const MagnesiumExample = (props) => {
  const [exampleLoading, setExampleLoading] = useState(true);
  const [exampleOne, setExampleOne] = useState([]);
  const [exampleTwo, setExampleTwo] = useState([]);
  const [exampleThree, setExampleThree] = useState([]);
  const [exampleFour, setExampleFour] = useState([]);
  const [exampleFive, setExampleFive] = useState([]);
  const [exampleSix, setExampleSix] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupIngredients, setPopupIngredients] = useState(null);
  const [popupIngredientList, setPopupIngredientList] = useState(null);

  const closePopup = () => setPopupOpen(false);

  const handlePopUp = (ingredients, ingredientList) => {
    setPopupOpen(true);
    setPopupIngredients(ingredients);
    setPopupIngredientList(ingredientList);
  };

  useEffect(() => {
    if (!props.userCountry) return;

    const fetchExampleData = (url) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.ok) {
            const data = await response.json();
            resolve(data);
          } else {
            reject(new Error(`HTTP error! status: ${response.status}`));
          }
        } catch (error) {
          reject(error);
        }
      });
    };

    const fetchAllExamples = (userCountry) => {
      let fetchOne;
      let fetchTwo;
      let fetchThree;
      let fetchFour;
      let fetchFive;
      let fetchSix;

      fetchOne = fetchExampleData(
        `/api/magnesium?ingredient1=citrate&region=` + userCountry
      );
      fetchTwo = fetchExampleData(
        `/api/magnesium?ingredient1=glycinate&region=` + userCountry
      );
      fetchThree = fetchExampleData(
        `/api/magnesium?ingredient1=oxide&region=` + userCountry
      );
      fetchFour = fetchExampleData(
        `/api/magnesium?ingredient1=malate&region=` + userCountry
      );
      fetchFive = fetchExampleData(
        `/api/magnesium?ingredient1=taurate&region=` + userCountry
      );
      fetchSix = fetchExampleData(
        `/api/magnesium?ingredient1=magnesium&region=` + userCountry
      );

      setExampleLoading(true);

      Promise.all([
        fetchOne,
        fetchTwo,
        fetchThree,
        fetchFour,
        fetchFive,
        fetchSix,
      ])
        .then(([dataOne, dataTwo, dataThree, dataFour, dataFive, dataSix]) => {
          setExampleOne(dataOne);
          setExampleTwo(dataTwo);
          setExampleThree(dataThree);
          setExampleFour(dataFour);
          setExampleFive(dataFive);
          setExampleSix(dataSix);
          setExampleLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchAllExamples(props.userCountry);
    // props.setPreFilter([]);
    // props.setPreFilterLink(null);
    // props.setProteinFilter([]);
    // props.setProteinFilterLink(null);
    // props.setElectrolyteFilter([]);
    // props.setElectrolyteFilterLink(null);
  }, [props.userCountry]);

  return (
    <center>
      <Helmet>
        <title>Magnesium Ranking Examples</title>
        <meta
          name="description"
          content="Discover the best value Magnesium with our ingredient value ranking system. Whether you want Citrate or Oxide, our rankings highlight top Magnesium products based on value."
        />
        <meta
          name="keywords"
          content="best magnesium, magnesium rankings, magnesium supplements, magnesium value, high absorption magnesium, low absorption magnesium, top magnesium formula, effective magnesium ingredients, affordable magnesium capsules, workout supplements with magnesium, magnesium comparison, ingredient-based magnesium powder, ingredient value magnesium, magnesium ingredient definitions"
        />
        <meta property="og:title" content="Magnesium Ranking Examples" />
        <meta
          property="og:description"
          content="best magnesium, magnesium rankings, magnesium supplements, magnesium value, high absorption magnesium, low absorption magnesium, top magnesium formula, effective magnesium ingredients, affordable magnesium capsules, workout supplements with magnesium, magnesium comparison, ingredient-based magnesium powder, ingredient value magnesium, magnesium ingredient definitions"
        />
      </Helmet>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton to={"/magnesium?ingredient1=magnesium"}>
          <LinkSVG />
          <BackText>Magnesium Filter</BackText>
        </BackButton>
        <LiveIndicator />
      </BackDiv>
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Magnesium Citrate"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() => handlePopUp(["citrate"], props.ingredients)}
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          Ranked by Magnesium value.
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleOne).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleOne}
                  filterLink={searchPageGen(
                    "magnesium",
                    null,
                    null,
                    null,
                    null,
                    ["citrate"],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setMagnesiumFilter}
                  setFilterLink={props.setMagnesiumFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "magnesium",
            null,
            null,
            null,
            null,
            ["citrate"],
            [],
            [],
            props.ingredients
          )}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Magnesium Glycinate"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() => handlePopUp(["glycinate"], props.ingredients)}
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          Ranked by Magnesium value.
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleTwo).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleTwo}
                  filterLink={searchPageGen(
                    "magnesium",
                    null,
                    null,
                    null,
                    null,
                    ["glycinate"],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setMagnesiumFilter}
                  setFilterLink={props.setMagnesiumFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "magnesium",
            null,
            null,
            null,
            null,
            ["glycinate"],
            [],
            [],
            props.ingredients
          )}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Magnesium Oxide"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() => handlePopUp(["oxide"], props.ingredients)}
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          Ranked by Magnesium value.
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleThree).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleThree}
                  filterLink={searchPageGen(
                    "magnesium",
                    null,
                    null,
                    null,
                    null,
                    ["oxide"],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setMagnesiumFilter}
                  setFilterLink={props.setMagnesiumFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "magnesium",
            null,
            null,
            null,
            null,
            ["oxide"],
            [],
            [],
            props.ingredients
          )}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Magnesium Malate"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() => handlePopUp(["malate"], props.ingredients)}
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          Ranked by Magnesium value.
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleFour).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleThree}
                  filterLink={searchPageGen(
                    "magnesium",
                    null,
                    null,
                    null,
                    null,
                    ["malate"],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setMagnesiumFilter}
                  setFilterLink={props.setMagnesiumFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "magnesium",
            null,
            null,
            null,
            null,
            ["malate"],
            [],
            [],
            props.ingredients
          )}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Magnesium Threonate"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() => handlePopUp(["threonate"], props.ingredients)}
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          Ranked by Magnesium value.
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleFive).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleThree}
                  filterLink={searchPageGen(
                    "magnesium",
                    null,
                    null,
                    null,
                    null,
                    ["threonate"],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setMagnesiumFilter}
                  setFilterLink={props.setMagnesiumFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "magnesium",
            null,
            null,
            null,
            null,
            ["threonate"],
            [],
            [],
            props.ingredients
          )}
          style={{ marginBottom: "25px" }}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["All Magnesium"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() => handlePopUp(["magnesium"], props.ingredients)}
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          Ranked by Magnesium value.
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleSix).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleThree}
                  filterLink={searchPageGen(
                    "magnesium",
                    null,
                    null,
                    null,
                    null,
                    ["magnesium"],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setMagnesiumFilter}
                  setFilterLink={props.setMagnesiumFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "magnesium",
            null,
            null,
            null,
            null,
            ["magnesium"],
            [],
            [],
            props.ingredients
          )}
          style={{ marginBottom: "25px" }}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      {/* <LastTabBar /> */}
      <PopUp
        isOpen={isPopupOpen}
        onClose={closePopup}
        ingredients={popupIngredients}
        ingredientList={popupIngredientList}
      />
      <AdPopUp canOpen={!isPopupOpen} accessories={props.accessories} />
    </center>
  );
};

export default MagnesiumExample;
