import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { LiveIndicator } from "../custom/animations";
import BrandListCompact from "../components/BrandListCompact";
import { trackEvent } from "../custom/analytics";
import { ChartSVG, IngredientEyeSVG } from "../custom/svg";
import { Regions, subMakerProtein } from "../custom/functions";
import PopUp from "../components/PopUp";
import { Helmet } from "react-helmet-async";
import AdPopUp from "../components/AdPopUp";
import {
  PodiumDivMobile,
  SvgWrapper,
  FilterSubtitle,
  Title,
  ListDiv,
  SuggestionDiv,
  Suggestion,
  SuggestionText,
  BackDiv,
  BackButton,
  BackText,
  RankInfo,
  RankText,
} from "../css/fetchPage";

const Ranks = [
  {
    value: "lowcal",
    label: "Lowest Calories",
  },
  {
    value: "highcal",
    label: "Highest Calories",
  },
];

const Type = [
  {
    value: "blend",
    label: "Blend",
  },
  {
    value: "clear",
    label: "Clear",
  },
  {
    value: "concentrate",
    label: "Concentrate",
  },
  {
    value: "isolate",
    label: "Isolate",
  },
  {
    value: "vegan",
    label: "Vegan",
  },
];

function responseMaker(type) {
  if (type === "isolate") {
    return "Whey Isolate Protein Powder";
  }
  if (type === "concentrate") {
    return "Whey Concentrate Protein Powder";
  }
  if (type === "clear") {
    return "Clear Whey Isolate Protein Powder";
  }
  if (type === "blend") {
    return "Whey Blend Protein Powder";
  }
  if (type === "vegan") {
    return "Vegan Protein Powder";
  }

  return "All Protein Powder";
}

function newapiHandler(filter, rank, userCountry) {
  let link = "/api/proteinpowder?";
  const params = new URLSearchParams();

  if (filter) {
    params.append("filter", filter);
  }

  if (rank) {
    params.append("rank", rank);
  }

  params.append("region", userCountry);

  link += params.toString();

  // console.log(link);

  return link;
}

const ProteinFilter = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [typeSelected, setType] = useState(searchParams.get("type") || null);
  const [regionSelected, setRegion] = useState(null);
  const [infoActive, setInfoActive] = useState(false);
  const [rankSelection, setRankSelection] = useState(
    searchParams.get("rank") || null
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState(responseMaker(typeSelected));
  const [filterSubtitle, setFilterSubtitle] = useState(
    subMakerProtein(rankSelection)
  );
  const [finalItems, setFinalItems] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  const [rankList, setRankList] = useState([...Ranks]);
  const [typeList, setTypeList] = useState([...Type]);

  const ref = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const excludeRef = useRef(null);
  const [excludeIsDragging, setExcludeIsDragging] = useState(false);
  const [excludeStartX, setExcludeStartX] = useState(0);
  const [excludeScrollLeft, setExcludeScrollLeft] = useState(0);

  const startDragging = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - ref.current.offsetLeft);
    setScrollLeft(ref.current.scrollLeft);
  };

  const stopDragging = (event) => {
    setIsDragging(false);
  };

  const whileDragging = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - ref.current.offsetLeft;
    const walk = (x - startX) * 3; // the *3 is the scroll speed factor
    ref.current.scrollLeft = scrollLeft - walk;
  };

  const excludeStartDragging = (e) => {
    setExcludeIsDragging(true);
    setExcludeStartX(e.pageX - excludeRef.current.offsetLeft);
    setExcludeScrollLeft(excludeRef.current.scrollLeft);
  };

  const excludeStopDragging = (event) => {
    setExcludeIsDragging(false);
  };

  const excludeWhileDragging = (e) => {
    if (!excludeIsDragging) return;
    e.preventDefault();
    const x = e.pageX - excludeRef.current.offsetLeft;
    const walk = (x - excludeStartX) * 3; // the *3 is the scroll speed factor
    excludeRef.current.scrollLeft = excludeScrollLeft - walk;
  };

  const handleRegionClick = (item) => {
    // props.setUserCountry(item.value);
    setRegion((prevSelected) => {
      if (prevSelected === item.value) {
        // setTypeList(
        //   removeActive(item, typeList, 1)
        // );
        return prevSelected;
      } else if (prevSelected !== item.value) {
        // setTypeList(reorderList(item, typeList));
        return item.value;
      } else {
        return prevSelected;
      }
    });
  };

  const handleRankClick = (item) => {
    setRankSelection((prevSelected) => {
      if (prevSelected === item.value) {
        // setTypeList(
        //   removeActive(item, typeList, 1)
        // );
        return null;
      } else if (prevSelected !== item.value) {
        // setTypeList(reorderList(item, typeList));
        return item.value;
      } else {
        return prevSelected;
      }
    });
  };

  const handleTypeClick = (item) => {
    setType((prevSelected) => {
      if (prevSelected === item.value) {
        // setTypeList(
        //   removeActive(item, typeList, 1)
        // );
        return null;
      } else if (prevSelected !== item.value) {
        // setTypeList(reorderList(item, typeList));
        return item.value;
      } else {
        return prevSelected;
      }
    });
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        newapiHandler(typeSelected, rankSelection, regionSelected)
      );
      if (!response.ok) {
        setLoading(true);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      trackEvent(
        "Filter",
        "Filtered: Protein Powder",
        (rankSelection ? rankSelection + " " : "") +
          (typeSelected ? typeSelected : "")
      );
      // setMobilePodium([...result.slice(0, 3)]);
      // setList([...result.slice(3, result.length)]);
      // setData([...result]);
      setTitle(responseMaker(typeSelected));
      setFilterSubtitle(subMakerProtein(rankSelection));

      const params = new URLSearchParams();

      if (typeSelected) params.append("type", typeSelected);
      if (rankSelection) params.append("rank", rankSelection);
      if (regionSelected) params.append("region", regionSelected);

      setSearchParams(params, { replace: true });

      props.setProteinFilter([...result]);
      props.setProteinFilterLink("/proteinpowder?" + params.toString());

      let previousValueRank = null;
      let currentRank = 1;
      let previousOldValueRank = null;
      let oldCurrentRank = 1;

      // First, calculate the current ranks while handling ties
      const rankedItems = result.map((item, index) => {
        if (
          previousValueRank !== null &&
          item.value_rank === previousValueRank
        ) {
          currentRank--;
        }
        const rankToShow = currentRank;
        previousValueRank = item.value_rank;
        currentRank++;
        return { ...item, currentRank: rankToShow };
      });

      // Sort items by old_value_rank
      const sortedByOldValueRank = [...rankedItems].sort(
        (a, b) => a.old_value_rank - b.old_value_rank
      );

      // Calculate old ranks while handling ties
      const rankedOldItems = sortedByOldValueRank.map((item, index) => {
        if (
          previousOldValueRank !== null &&
          item.old_value_rank === previousOldValueRank
        ) {
          oldCurrentRank--;
        }
        const oldRankToShow = oldCurrentRank;
        previousOldValueRank = item.old_value_rank;
        oldCurrentRank++;
        return { ...item, oldRank: oldRankToShow };
      });

      // Merge old ranks back into the original items based on value_rank
      const mergedItems = rankedItems.map((item) => {
        const rankedOldItem = rankedOldItems.find(
          (i) => i.brand === item.brand && i.type === item.type
        );
        return {
          ...item,
          oldRank: rankedOldItem ? rankedOldItem.oldRank : null,
        };
      });

      // console.log(mergedItems);
      setFinalItems(mergedItems);
      setLoading(false);
    } catch (error) {
      setError(error); // Update the state with the error
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, [props.userCountry]);

  useEffect(() => {
    const regionParam = searchParams.get("region");

    if (regionParam || regionSelected === null) {
      setRegion(regionParam || props.userCountry);
    }
  }, [props.userCountry, searchParams]);

  useEffect(() => {
    if (!regionSelected) return;
    // window.scroll(0, 0);
    fetchData();
  }, [typeSelected, rankSelection, regionSelected]);

  const getStyle = (item, list) => {
    return item.value === list
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getTextStyle = (item, list) => {
    return item.value === list
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };
  const infoToggle = () => {
    setInfoActive(!infoActive);
  };

  return (
    <center style={{ overflowX: "hidden" }}>
      <Helmet>
        <title>Protein Powder Rankings</title>
        <meta
          name="description"
          content="Discover the best value Protein Powder with our protein value ranking system. Whether you want a low calorie, whey isolate, or vegan formula, our rankings highlight top Protein Powders based on protein value."
        />
        <meta
          name="keywords"
          content="best protein powder, protein powder rankings, protein powder supplement, protein powder value, high calorie protein powder, low calorie protein powder, top protein powder, effective protein powder ingredients, affordable protein powder, workout supplements, protein powder comparison, ingredient-based protein powder, ingredient value protein powder, protein powder ingredient definitions"
        />
        <meta property="og:title" content="Protein Powder Rankings" />
        <meta
          property="og:description"
          content="Discover the best value Protein Powder with our protein value ranking system. Whether you want a low calorie, whey isolate, or vegan formula, our rankings highlight top Protein Powders based on protein value."
        />
      </Helmet>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton onClick={infoToggle}>
          <ChartSVG />
          <BackText>Ranking Info {!infoActive ? "+" : "-"}</BackText>
        </BackButton>
        <LiveIndicator />
        {/* </StyledLink>
          <BackText style={{color:"gray"}}>{rank} Place</BackText> */}
      </BackDiv>
      {infoActive && (
        <RankInfo>
          <RankText>
            Products are ranked by Total Protein Value.
            <br />
            Formula: (Total Servings * Protein Serving) / Price
          </RankText>
        </RankInfo>
      )}
      <PodiumDivMobile style={{ paddingTop: "0px" }}>
        {!loading ? (
          finalItems.slice(0, 3).map((item, index) => {
            if (!item) return null;
            return (
              <BrandListCompact
                item={item}
                filterRank={item.currentRank}
                oldRank={item.oldRank}
                userCountry={regionSelected}
                ticker={true}
                ai={true}
                key={index}
                loading={false}
              />
            );
          })
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <Title>{title}</Title>
      <FilterSubtitle onClick={openPopup}>
        <SvgWrapper>
          <IngredientEyeSVG />
        </SvgWrapper>
        {filterSubtitle}
      </FilterSubtitle>
      <SuggestionDiv
        style={{ paddingBottom: "10px" }}
      >
        {/* <SuggestionText>Type:</SuggestionText> */}
        {Regions.map(
          (item) =>
            item && (
              <Suggestion
                key={item.value}
                onClick={() => handleRegionClick(item)}
                style={getStyle(item, regionSelected || "")}
              >
                <SuggestionText
                  style={getTextStyle(item, regionSelected || "")}
                >
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      <SuggestionDiv
        ref={ref}
        onMouseDown={startDragging}
        onMouseLeave={stopDragging}
        onMouseUp={stopDragging}
        onMouseMove={whileDragging}
        style={{ paddingTop: "5px", paddingBottom: "10px" }}
      >
        {/* <SuggestionText>Rank:</SuggestionText> */}
        {rankList.map(
          (item) =>
            item && (
              <Suggestion
                key={item.value}
                onClick={() => handleRankClick(item)}
                style={getStyle(item, rankSelection)}
              >
                <SuggestionText style={getTextStyle(item, rankSelection)}>
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      <SuggestionDiv
        ref={excludeRef}
        onMouseDown={excludeStartDragging}
        onMouseLeave={excludeStopDragging}
        onMouseUp={excludeStopDragging}
        onMouseMove={excludeWhileDragging}
        style={{ paddingTop: "5px", marginBottom: "30px" }}
      >
        {/* <SuggestionText>Type:</SuggestionText> */}
        {typeList.map(
          (item) =>
            item && (
              <Suggestion
                key={item.value}
                onClick={() => handleTypeClick(item)}
                style={getStyle(item, typeSelected)}
              >
                <SuggestionText style={getTextStyle(item, typeSelected)}>
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      {/* <FilterButtonDiv>
        <FilterButtons onClick={(typeSelected || rankSelection) && handleClick}>
          <ListShopText>Filter</ListShopText>
        </FilterButtons>
        <FilterButtons
          onClick={() => {
            setType(null);
            setRankSelection(null);
          }}
        >
          <ListShopText>Clear</ListShopText>
        </FilterButtons>
      </FilterButtonDiv> */}
      <ListDiv>
        {!loading ? (
          finalItems.slice(3).map((item, index) => {
            if (!item) return null;
            return (
              <BrandListCompact
                item={item}
                filterRank={item.currentRank}
                oldRank={item.oldRank}
                userCountry={regionSelected}
                ticker={true}
                ai={true}
                key={index}
                loading={false}
              />
            );
          })
        ) : (
          <>
            <BrandListCompact filterRank={4} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={5} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={6} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={7} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={8} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={9} loading={true}></BrandListCompact>
          </>
        )}
      </ListDiv>
      {!typeSelected ? (
        <PopUp
          isOpen={isPopupOpen}
          onClose={closePopup}
          ingredients={rankSelection ? ["calories", "protein"] : ["protein"]}
          ingredientList={props.ingredients}
        />
      ) : (
        <PopUp
          isOpen={isPopupOpen}
          onClose={closePopup}
          ingredients={
            rankSelection ? ["calories", typeSelected] : [typeSelected]
          }
          ingredientList={props.ingredients}
        />
      )}
      <AdPopUp canOpen={!isPopupOpen} accessories={props.accessories} />

      {/* <StyledReactPaginate
        pageCount={totalPages}
        onPageChange={handlePageChange}
        previousLabel={"Prev"}
      /> */}
    </center>
  );
};

export default ProteinFilter;
