import React, { useState, useEffect } from "react";
import { ReactTyped } from "react-typed";
import PopUp from "../components/PopUp";
import BrandListCompact from "../components/BrandListCompact";
import {
  tieBreaker,
  searchPageGen,
  submaker,
} from "../custom/functions";
import { IngredientEyeSVG, LinkSVG } from "../custom/svg";
import podiumGlyph from "../images/podiumglyph.png";
import { LiveIndicator } from "../custom/animations";
import { Helmet } from "react-helmet-async";
import AdPopUp from "../components/AdPopUp";
import {
  BackDiv,
  BackButton,
  BackText,
  ResponseDiv,
  ResponseTitle,
  ResponseSubtitle,
  SvgWrapper,
  PodiumDivMobile,
  FilterButtonDiv,
  ShowMoreButton,
  PodiumGlyphImg,
  TabBar,
} from "../css/examplePage";

const OmegaExample = (props) => {
  const [exampleLoading, setExampleLoading] = useState(true);
  const [exampleOne, setExampleOne] = useState([]);
  const [exampleTwo, setExampleTwo] = useState([]);
  const [exampleThree, setExampleThree] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupIngredients, setPopupIngredients] = useState(null);
  const [popupIngredientList, setPopupIngredientList] = useState(null);

  const closePopup = () => setPopupOpen(false);

  const handlePopUp = (ingredients, ingredientList) => {
    setPopupOpen(true);
    setPopupIngredients(ingredients);
    setPopupIngredientList(ingredientList);
  };

  useEffect(() => {
    if (!props.userCountry) return;

    const fetchExampleData = (url) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.ok) {
            const data = await response.json();
            resolve(data);
          } else {
            reject(new Error(`HTTP error! status: ${response.status}`));
          }
        } catch (error) {
          reject(error);
        }
      });
    };

    const fetchAllExamples = (userCountry) => {
      let fetchOne;
      let fetchTwo;
      let fetchThree;

      fetchOne = fetchExampleData(
        `/api/omega3?ingredient1=dha&ingredient2=epa&ingredient3=otherfattyacids&filter=softgel&region=` +
          userCountry
      );
      fetchTwo = fetchExampleData(
        `/api/omega3?ingredient1=dha&ingredient2=epa&region=` + userCountry
      );

      if (userCountry === "GB") {
        fetchThree = fetchExampleData(
          `/api/omega3?ingredient1=fishoil&region=` + userCountry
        );
      }

      setExampleLoading(true);

      Promise.all([fetchOne, fetchTwo, fetchThree])
        .then(([dataOne, dataTwo, dataThree]) => {
          setExampleOne(dataOne);
          setExampleTwo(dataTwo);
          setExampleThree(dataThree);
          setExampleLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchAllExamples(props.userCountry);
    // props.setPreFilter([]);
    // props.setPreFilterLink(null);
    // props.setProteinFilter([]);
    // props.setProteinFilterLink(null);
    // props.setElectrolyteFilter([]);
    // props.setElectrolyteFilterLink(null);
  }, [props.userCountry]);

  return (
    <center>
      <Helmet>
        <title>Omega-3 Ranking Examples</title>
        <meta
          name="description"
          content="Discover the best value Omega-3 supplements with our ingredient value ranking system. Whether you're looking for high EPA fish oil or DHA-rich omega-3 capsules, our rankings highlight top Omega-3 products based on ingredient value."
        />
        <meta
          name="keywords"
          content="Best Omega-3 Supplements, Omega-3 Rankings, Fish Oil Benefits, Omega-3 Value, High EPA Fish Oil, High DHA Fish Oil, Low Mercury Fish Oil, Top Omega-3 Formulas, Effective Omega-3 Ingredients, Affordable Omega-3 Capsules, Brain-Boosting Supplements, Omega-3 Comparison, Ingredient-Based Fish Oil Analysis, Ingredient Value Omega-3 Supplements, Omega-3 Ingredient Definitions"
        />
        <meta property="og:title" content="Omega-3 Ranking Examples" />
        <meta
          property="og:description"
          content="Discover the best value Omega-3 supplements with our ingredient value ranking system. Whether you're looking for high EPA fish oil or DHA-rich omega-3 capsules, our rankings highlight top Omega-3 products based on ingredient value."
        />
      </Helmet>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton to={"/omega3?ingredient1=dha&ingredient2=epa"}>
          <LinkSVG />
          <BackText>Omega-3 Filter</BackText>
        </BackButton>
        <LiveIndicator />
      </BackDiv>
      {props.userCountry !== "GB" ? (
        <>
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["Omega-3 Softgels"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            <ResponseSubtitle
              onClick={() =>
                handlePopUp(["dha", "epa", "otherfattyacids"], props.ingredients)
              }
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {submaker(["dha", "epa", "otherfattyacids"], [], props.ingredients)}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(exampleOne).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      userCountry={props.userCountry}
                      ai={true}
                      key={index}
                      filter={exampleOne}
                      filterLink={searchPageGen(
                        "omega3",
                        "softgel",
                        null,
                        null,
                        null,
                        ["dha", "epa", "otherfattyacids"],
                        [],
                        [],
                        props.ingredients
                      )}
                      setFilter={props.setOmegaFilter}
                      setFilterLink={props.setOmegaFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "omega3",
                "softgel",
                null,
                null,
                null,
                ["dha", "epa", "otherfattyacids"],
                [],
                [],
                props.ingredients
              )}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
        </>
      ) : (
        <>
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["Omega-3 Softgels"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            <ResponseSubtitle
              onClick={() => handlePopUp(["dha", "epa"], props.ingredients)}
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {submaker(["dha", "epa"], [], props.ingredients)}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(exampleThree).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      userCountry={props.userCountry}
                      ai={true}
                      key={index}
                      filter={exampleThree}
                      filterLink={searchPageGen(
                        "omega3",
                        "softgel",
                        null,
                        null,
                        null,
                        ["dha", "epa"],
                        [],
                        [],
                        props.ingredients
                      )}
                      setFilter={props.setOmegaFilter}
                      setFilterLink={props.setOmegaFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "omega3",
                "softgel",
                null,
                null,
                null,
                ["dha", "epa"],
                [],
                [],
                props.ingredients
              )}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
        </>
      )}
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["All Omega-3s"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() => handlePopUp(["dha", "epa"], props.ingredients)}
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {submaker(["dha", "epa"], [], props.ingredients)}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleTwo).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleTwo}
                  filterLink={searchPageGen(
                    "omega3",
                    null,
                    null,
                    null,
                    null,
                    ["dha", "epa"],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setOmegaFilter}
                  setFilterLink={props.setOmegaFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "omega3",
            null,
            null,
            null,
            null,
            ["dha", "epa"],
            [],
            [],
            props.ingredients
          )}
          style={{ marginBottom: "25px" }}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      {/* <LastTabBar /> */}
      <PopUp
        isOpen={isPopupOpen}
        onClose={closePopup}
        ingredients={popupIngredients}
        ingredientList={popupIngredientList}
      />
      <AdPopUp canOpen={!isPopupOpen} accessories={props.accessories} />
    </center>
  );
};

export default OmegaExample;
