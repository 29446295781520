import React, { useState, useEffect } from "react";
import { ReactTyped } from "react-typed";
import PopUp from "../components/PopUp";
import BrandListCompact from "../components/BrandListCompact";
import {
  tieBreaker,
  searchPageGen,
  submaker,
} from "../custom/functions";
import { IngredientEyeSVG, LinkSVG } from "../custom/svg";
import podiumGlyph from "../images/podiumglyph.png";
import { LiveIndicator } from "../custom/animations";
import { Helmet } from "react-helmet-async";
import FanPopUp from "../components/FanPopUp";
import {
  BackDiv,
  BackButton,
  BackText,
  ResponseDiv,
  ResponseTitle,
  ResponseSubtitle,
  SvgWrapper,
  PodiumDivMobile,
  FilterButtonDiv,
  ShowMoreButton,
  PodiumGlyphImg,
  TabBar,
} from "../css/examplePage";

const IntraExample = (props) => {
  const [exampleLoading, setExampleLoading] = useState(true);
  const [exampleOne, setExampleOne] = useState([]);
  const [exampleFour, setExampleFour] = useState([]);
  const [exampleFive, setExampleFive] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupIngredients, setPopupIngredients] = useState(null);
  const [popupIngredientList, setPopupIngredientList] = useState(null);

  const closePopup = () => setPopupOpen(false);

  const handlePopUp = (ingredients, ingredientList) => {
    setPopupOpen(true);
    setPopupIngredients(ingredients);
    setPopupIngredientList(ingredientList);
  };

  useEffect(() => {
    if (!props.userCountry) return;

    const fetchExampleData = (url) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.ok) {
            const data = await response.json();
            resolve(data);
          } else {
            reject(new Error(`HTTP error! status: ${response.status}`));
          }
        } catch (error) {
          reject(error);
        }
      });
    };

    const fetchAllExamples = (userCountry) => {
      let fetchOne;
      let fetchFour;
      let fetchFive;

      if (userCountry === "GB") {
        fetchFour = fetchExampleData(
          `/api/intraworkout?ingredient1=carbs&ingredient2=potassium&ingredient3=sodium&region=` +
            userCountry
        );
        fetchFive = fetchExampleData(
          "/api/intraworkout?ingredient1=isoleucine&ingredient2=leucine&ingredient3=valine&ingredient4=taurine&ingredient5=carbs&region=" +
            userCountry
        );
      } else {
        fetchOne = fetchExampleData(
          `/api/intraworkout?ingredient1=isoleucine&ingredient2=leucine&ingredient3=valine&ingredient4=carbs&ingredient5=taurine&exclude1=caffeine&region=` +
            userCountry
        );
        fetchFour = fetchExampleData(
          `/api/intraworkout?ingredient1=carbs&ingredient2=potassium&ingredient3=sodium&region=` +
            userCountry
        );
        fetchFive = fetchExampleData(
          "/api/intraworkout?ingredient1=isoleucine&ingredient2=leucine&ingredient3=valine&ingredient4=betaine&ingredient5=taurine&ingredient6=carbs&region=" +
            userCountry
        );
      }

      setExampleLoading(true);

      Promise.all([
        fetchOne,
        fetchFour,
        fetchFive,
      ])
        .then(
          ([
            dataOne,
            dataFour,
            dataFive,
          ]) => {
            setExampleOne(dataOne);
            setExampleFour(dataFour);
            setExampleFive(dataFive);
            setExampleLoading(false);
          }
        )
        .catch((error) => {
          console.log(error);
        });
    };

    fetchAllExamples(props.userCountry);
  }, [props.userCountry]);

  return (
    <center>
      <Helmet>
        <title>Intra-Workout Ranking Examples</title>
        <meta
          name="description"
          content="Discover the best value Intra-Workout supplements with our ingredient value ranking system. Whether you're focused on hydration, enhanced performance, or endurance, our rankings highlight the top Intra-Workout formulas based on ingredient value."
        />
        <meta
          name="keywords"
          content="best intra-workout, intra-workout rankings, intra-workout supplement, intra-workout value, hydration supplements, performance supplements, endurance intra-workout, electrolyte supplements, amino acid intra-workout, top intra-workout, effective intra-workout ingredients, affordable intra-workout, intra-workout for hydration, intra-workout for recovery, workout hydration supplements, intra-workout comparison, ingredient-based intra-workout, ingredient value intra-workout, intra-workout ingredient definitions"
        />
        <meta property="og:title" content="Intra-Workout Ranking Examples" />
        <meta
          property="og:description"
          content="Discover the best value Intra-Workout supplements with our ingredient value ranking system. Whether you're focused on hydration, enhanced performance, or endurance, our rankings highlight the top Intra-Workout formulas based on ingredient value."
        />
      </Helmet>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton to={"/intraworkout"}>
          {" "}
          {/* FIX DEFAULT */}
          <LinkSVG />
          <BackText>Intra-Workout Filter</BackText>
        </BackButton>
        <LiveIndicator />
      </BackDiv>
      {props.userCountry === "US" && (
        <>
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["Hydration Intra-Workout"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            <ResponseSubtitle
              onClick={() =>
                handlePopUp(["carbs", "potassium", "sodium"], props.ingredients)
              }
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {submaker(["carbs", "potassium", "sodium"], [], props.ingredients)}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(exampleFour).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      userCountry={props.userCountry}
                      ai={true}
                      key={index}
                      filter={exampleFour}
                      filterLink={searchPageGen(
                        "intraworkout",
                        null,
                        null,
                        null,
                        null,
                        ["carbs", "potassium", "sodium"],
                        [],
                        [],
                        props.ingredients
                      )}
                      setFilter={props.setIntraFilter}
                      setFilterLink={props.setIntraFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "intraworkout",
                null,
                null,
                null,
                null,
                ["carbs", "potassium", "sodium"],
                [],
                [],
                props.ingredients
              )}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
          <TabBar />
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["BCAA Intra-Workout"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            <ResponseSubtitle
              onClick={() =>
                handlePopUp(
                  [
                    "betaine",
                    "carbs",
                    "isoleucine",
                    "leucine",
                    "taurine",
                    "valine",
                  ],
                  props.ingredients
                )
              }
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {submaker([
                "betaine",
                "carbs",
                "isoleucine",
                "leucine",
                "taurine",
                "valine",
              ], [], props.ingredients)}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(exampleFive).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      userCountry={props.userCountry}
                      ai={true}
                      key={index}
                      filter={exampleFive}
                      filterLink={searchPageGen(
                        "intraworkout",
                        null,
                        null,
                        null,
                        null,
                        [
                          "betaine",
                          "carbs",
                          "isoleucine",
                          "leucine",
                          "taurine",
                          "valine",
                        ],
                        [],
                        [],
                        props.ingredients
                      )}
                      setFilter={props.setIntraFilter}
                      setFilterLink={props.setIntraFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "intraworkout",
                null,
                null,
                null,
                null,
                [
                  "betaine",
                  "carbs",
                  "isoleucine",
                  "leucine",
                  "taurine",
                  "valine",
                ],
                [],
                [],
                props.ingredients
              )}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
          <TabBar />
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["Caffeine Free Intra-Workout"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            {/* <ResponseSubtitle>
                {props.userCountry === "GB"
                  ? "Ranked by Caffeine, Citrulline and Glycerol value."
                  : "Ranked by Citrulline, Glycerol and Malic Acid value."}
              </ResponseSubtitle> */}
            <ResponseSubtitle
              onClick={() =>
                handlePopUp(
                  ["carbs", "isoleucine", "leucine", "taurine", "valine"],
                  props.ingredients
                )
              }
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {submaker([
                "carbs",
                "isoleucine",
                "leucine",
                "taurine",
                "valine",
              ], [], props.ingredients)}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(exampleOne).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      ai={true}
                      userCountry={props.userCountry}
                      key={index}
                      filter={exampleOne}
                      filterLink={searchPageGen(
                        "intraworkout",
                        null,
                        null,
                        null,
                        null,
                        ["carbs", "isoleucine", "leucine", "taurine", "valine"],
                        ["caffeine"],
                        [],
                        props.ingredients
                      )}
                      setFilter={props.setIntraFilter}
                      setFilterLink={props.setIntraFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "intraworkout",
                null,
                null,
                null,
                null,
                ["carbs", "isoleucine", "leucine", "taurine", "valine"],
                ["caffeine"],
                [],
                props.ingredients
              )}
              style={{ marginBottom: "25px" }}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
        </>
      )}
      {props.userCountry === "GB" && (
        <>
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["Hydration Intra-Workout"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            <ResponseSubtitle
              onClick={() =>
                handlePopUp(["carbs", "potassium", "sodium"], props.ingredients)
              }
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {submaker(["carbs", "potassium", "sodium"], [], props.ingredients)}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(exampleFour).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      userCountry={props.userCountry}
                      ai={true}
                      key={index}
                      filter={exampleFour}
                      filterLink={searchPageGen(
                        "intraworkout",
                        null,
                        null,
                        null,
                        null,
                        ["carbs", "potassium", "sodium"],
                        [],
                        [],
                        props.ingredients
                      )}
                      setFilter={props.setIntraFilter}
                      setFilterLink={props.setIntraFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "intraworkout",
                null,
                null,
                null,
                null,
                ["carbs", "potassium", "sodium"],
                [],
                [],
                props.ingredients
              )}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
          <TabBar />
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["BCAA Intra-Workout"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            <ResponseSubtitle
              onClick={() =>
                handlePopUp(
                  ["carbs", "isoleucine", "leucine", "taurine", "valine"],
                  props.ingredients
                )
              }
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {submaker([
                "carbs",
                "isoleucine",
                "leucine",
                "taurine",
                "valine",
              ], [], props.ingredients)}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(exampleFive).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      userCountry={props.userCountry}
                      ai={true}
                      key={index}
                      filter={exampleFive}
                      filterLink={searchPageGen(
                        "intraworkout",
                        null,
                        null,
                        null,
                        null,
                        ["carbs", "isoleucine", "leucine", "taurine", "valine"],
                        [],
                        [],
                        props.ingredients
                      )}
                      setFilter={props.setIntraFilter}
                      setFilterLink={props.setIntraFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "intraworkout",
                null,
                null,
                null,
                null,
                ["carbs", "isoleucine", "leucine", "taurine", "valine"],
                [],
                [],
                props.ingredients
              )}
              style={{ marginBottom: "25px" }}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
        </>
      )}
      {/* <LastTabBar /> */}
      <PopUp
        isOpen={isPopupOpen}
        onClose={closePopup}
        ingredients={popupIngredients}
        ingredientList={popupIngredientList}
      />
      {/* <AdPopUp canOpen={!isPopupOpen} accessories={props.accessories} /> */}
      <FanPopUp canOpen={!isPopupOpen} userCountry={props.userCountry} />
    </center>
  );
};

export default IntraExample;
