import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { LiveIndicator } from "../custom/animations";
import BrandListCompact from "../components/BrandListCompact";
import { trackEvent } from "../custom/analytics";
import { ChartSVG, IngredientEyeSVG } from "../custom/svg";
import { Regions } from "../custom/functions";
import PopUp from "../components/PopUp";
import { Helmet } from "react-helmet-async";
import AdPopUp from "../components/AdPopUp";
import {
  PodiumDivMobile,
  SvgWrapper,
  FilterSubtitle,
  Title,
  ListDiv,
  SuggestionDiv,
  Suggestion,
  SuggestionText,
  BackDiv,
  BackButton,
  BackText,
  RankInfo,
  RankText,
} from "../css/fetchPage";

const MassFilter = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [infoActive, setInfoActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [finalItems, setFinalItems] = useState([]);
  const [regionSelected, setRegion] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch("/api/massgainer?region=" + regionSelected);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      trackEvent("Filter", "Filtered: Mass Gainer");

      const params = new URLSearchParams();
      if (regionSelected) params.append("region", regionSelected);
      setSearchParams(params, { replace: true });

      props.setMassFilter([...result]);
      props.setMassFilterLink("/massgainer?" + params.toString());

      let previousValueRank = null;
      let currentRank = 1;
      let previousOldValueRank = null;
      let oldCurrentRank = 1;

      // First, calculate the current ranks while handling ties
      const rankedItems = result.map((item, index) => {
        if (
          previousValueRank !== null &&
          item.value_rank === previousValueRank
        ) {
          currentRank--;
        }
        const rankToShow = currentRank;
        previousValueRank = item.value_rank;
        currentRank++;
        return { ...item, currentRank: rankToShow };
      });

      // Sort items by old_value_rank
      const sortedByOldValueRank = [...rankedItems].sort(
        (a, b) => a.old_value_rank - b.old_value_rank
      );

      // Calculate old ranks while handling ties
      const rankedOldItems = sortedByOldValueRank.map((item, index) => {
        if (
          previousOldValueRank !== null &&
          item.old_value_rank === previousOldValueRank
        ) {
          oldCurrentRank--;
        }
        const oldRankToShow = oldCurrentRank;
        previousOldValueRank = item.old_value_rank;
        oldCurrentRank++;
        return { ...item, oldRank: oldRankToShow };
      });

      // Merge old ranks back into the original items based on value_rank
      const mergedItems = rankedItems.map((item) => {
        const rankedOldItem = rankedOldItems.find(
          (i) => i.brand === item.brand && i.subtitle === item.subtitle
        );
        return {
          ...item,
          oldRank: rankedOldItem ? rankedOldItem.oldRank : null,
        };
      });

      // console.log(mergedItems);
      setFinalItems(mergedItems);
      setLoading(false);
    } catch (error) {
      setError(error); // Update the state with the error
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, [props.userCountry]);

  useEffect(() => {
    const regionParam = searchParams.get("region");

    if (regionParam || regionSelected === null) {
      setRegion(regionParam || props.userCountry);
    }
  }, [props.userCountry, searchParams]);

  useEffect(() => {
    if (!regionSelected) return;
    // window.scroll(0, 0);
    fetchData();
  }, [regionSelected]);

  const handleRegionClick = (item) => {
    // props.setUserCountry(item.value);
    setRegion((prevSelected) => {
      if (prevSelected === item.value) {
        // setTypeList(
        //   removeActive(item, typeList, 1)
        // );
        return prevSelected;
      } else if (prevSelected !== item.value) {
        // setTypeList(reorderList(item, typeList));
        return item.value;
      } else {
        return prevSelected;
      }
    });
  };

  const getStyle = (item, list) => {
    return item.value === list
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getTextStyle = (item, list) => {
    return item.value === list
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };
  const infoToggle = () => {
    setInfoActive(!infoActive);
  };

  return (
    <center style={{ overflowX: "hidden" }}>
      <Helmet>
        <title>Mass Gainer Rankings</title>
        <meta
          name="description"
          content="Discover the best value Mass Gainer with our protein and calorie value ranking system. Our rankings highlight top Mass Gainers based on protein and calorie value."
        />
        <meta
          name="keywords"
          content="best mass gainer, mass gainer rankings, weight gainer supplement, mass gainer value, high calorie mass gainer, low calorie mass gainer, top mass gainer, effective mass gainer ingredients, affordable mass gainer, workout supplements, mass gainer comparison, ingredient-based mass gainer, ingredient value mass gainer, mass gainer ingredient definitions"
        />
        <meta property="og:title" content="Mass Gainer Rankings" />
        <meta
          property="og:description"
          content="Discover the best value Mass Gainer with our protein and calorie value ranking system. Our rankings highlight top Mass Gainers based on protein and calorie value."
        />
      </Helmet>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton onClick={infoToggle}>
          <ChartSVG />
          <BackText>Ranking Info {!infoActive ? "+" : "-"}</BackText>
        </BackButton>
        <LiveIndicator />
        {/* </StyledLink>
          <BackText style={{color:"gray"}}>{rank} Place</BackText> */}
      </BackDiv>
      {infoActive && (
        <RankInfo>
          <RankText>
            Products are ranked by Total Calorie and Protein Value.
            <br />
            Formula: (Total Servings * Calorie Serving) / Price + <br />
            (Total Servings * Protein Serving) / Price
          </RankText>
        </RankInfo>
      )}
      <PodiumDivMobile style={{ paddingTop: "0px" }}>
        {!loading ? (
          finalItems.slice(0, 3).map((item, index) => {
            if (!item) return null;
            return (
              <BrandListCompact
                item={item}
                filterRank={item.currentRank}
                oldRank={item.oldRank}
                userCountry={regionSelected}
                ticker={true}
                ai={true}
                key={index}
                loading={false}
              />
            );
          })
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <Title>All Mass Gainer</Title>
      <FilterSubtitle onClick={openPopup}>
        <SvgWrapper>
          <IngredientEyeSVG />
        </SvgWrapper>
        Ranked by Highest Calories and Protein value.
      </FilterSubtitle>
      <SuggestionDiv style={{ paddingTop: "5px", marginBottom: "30px" }}>
        {/* <SuggestionText>Type:</SuggestionText> */}
        {Regions.map(
          (item) =>
            item && (
              <Suggestion
                key={item.value}
                onClick={() => handleRegionClick(item)}
                style={getStyle(item, regionSelected || "")}
              >
                <SuggestionText
                  style={getTextStyle(item, regionSelected || "")}
                >
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      <ListDiv>
        {!loading ? (
          finalItems.slice(3).map((item, index) => {
            if (!item) return null;
            return (
              <BrandListCompact
                item={item}
                filterRank={item.currentRank}
                oldRank={item.oldRank}
                userCountry={regionSelected}
                ticker={true}
                ai={true}
                key={index}
                loading={false}
              />
            );
          })
        ) : (
          <>
            <BrandListCompact filterRank={4} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={5} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={6} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={7} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={8} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={9} loading={true}></BrandListCompact>
          </>
        )}
      </ListDiv>
      <PopUp
        isOpen={isPopupOpen}
        onClose={closePopup}
        ingredients={["calories", "protein"]}
        ingredientList={props.ingredients}
      />
      <AdPopUp
        canOpen={!isPopupOpen}
        accessories={props.accessories}
      />
      {/* <StyledReactPaginate
        pageCount={totalPages}
        onPageChange={handlePageChange}
        previousLabel={"Prev"}
      /> */}
    </center>
  );
};

export default MassFilter;
