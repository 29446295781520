import React, { useState, useEffect } from "react";
import { ReactTyped } from "react-typed";
import PopUp from "../components/PopUp";
import BrandListCompact from "../components/BrandListCompact";
import {
  tieBreaker,
  searchPageGen,
  itemFilter,
  submaker,
} from "../custom/functions";
import { IngredientEyeSVG, LinkSVG } from "../custom/svg";
import podiumGlyph from "../images/podiumglyph.png";
import { LiveIndicator } from "../custom/animations";
import { Helmet } from "react-helmet-async";
import AdPopUp from "../components/AdPopUp";
import {
  BackDiv,
  BackButton,
  BackText,
  ResponseDiv,
  ResponseTitle,
  ResponseSubtitle,
  SvgWrapper,
  PodiumDivMobile,
  FilterButtonDiv,
  ShowMoreButton,
  PodiumGlyphImg,
  TabBar,
} from "../css/examplePage";

const EnergyDrinkExample = (props) => {
  const [exampleLoading, setExampleLoading] = useState(true);
  const [exampleOne, setExampleOne] = useState([]);
  const [exampleTwo, setExampleTwo] = useState([]);
  const [exampleThree, setExampleThree] = useState([]);
  const [exampleFour, setExampleFour] = useState([]);
  const [exampleFive, setExampleFive] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupIngredients, setPopupIngredients] = useState(null);
  const [popupIngredientList, setPopupIngredientList] = useState(null);

  const closePopup = () => setPopupOpen(false);

  const handlePopUp = (ingredients, ingredientList) => {
    setPopupOpen(true);
    setPopupIngredients(ingredients);
    setPopupIngredientList(ingredientList);
  };

  useEffect(() => {
    if (!props.userCountry) return;

    const fetchExampleData = (url) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.ok) {
            const data = await response.json();
            resolve(data);
          } else {
            reject(new Error(`HTTP error! status: ${response.status}`));
          }
        } catch (error) {
          reject(error);
        }
      });
    };

    const fetchAllExamples = (userCountry) => {
      let fetchOne;
      let fetchTwo;
      let fetchThree;
      let fetchFour;
      let fetchFive;

      fetchOne = fetchExampleData(
        `/api/energydrink?ingredient1=caffeine&region=` + userCountry
      );
      fetchTwo = fetchExampleData(
        `/api/energydrink?ingredient1=caffeine&exclude1=calories&region=` +
          userCountry
      );
      fetchThree = fetchExampleData(
        `/api/energydrink?ingredient1=alphagpc&region=` + userCountry
      );
      fetchFour = fetchExampleData(
        `/api/energydrink?ingredient1=caffeine&ingredient2=vitaminb6&ingredient3=vitaminb12&exclude1=sugar&region=` +
          userCountry
      );
      fetchFive = fetchExampleData(
        "/api/energydrink?ingredient1=caffeine&ingredient2=potassium&ingredient3=sodium&region=" +
          userCountry
      );

      setExampleLoading(true);

      Promise.all([
        fetchOne,
        fetchTwo,
        fetchThree,
        fetchFour,
        fetchFive,
      ])
        .then(
          ([
            dataOne,
            dataTwo,
            dataThree,
            dataFour,
            dataFive,
          ]) => {
            setExampleOne(dataOne);
            setExampleTwo(dataTwo);
            setExampleThree(dataThree);
            setExampleFour(dataFour);
            setExampleFive(dataFive);
            setExampleLoading(false);
          }
        )
        .catch((error) => {
          console.log(error);
        });
    };

    fetchAllExamples(props.userCountry);
  }, [props.userCountry]);

  return (
    <center>
      <Helmet>
        <title>Energy Drink Ranking Examples</title>
        <meta
          name="description"
          content="Discover the best value Energy Drinks with our ingredient value ranking system. Whether you’re looking for high caffeine, low sugar, or specific energy-boosting formulas, our rankings highlight top Energy Drink products based on ingredient value."
        />
        <meta
          name="keywords"
          content="Energy Drink Rankings, Top Energy Drinks, Best Energy Boosters, Natural Energy Drinks, Low-Sugar Energy Drinks, High-Caffeine Energy Drinks, Energy Drinks for Stamina, Endurance Energy Formulas, Performance Energy Drinks, Energy Drinks for Recovery, Hydration and Energy Drinks, Affordable Energy Drink Options, Energy Drinks for Workout Performance, Pre-Workout Energy Drinks, Post-Workout Energy Blends, Long-Lasting Energy Drinks, Energy Drinks for Focus, Energy Drinks for Athletes, Healthy Energy Drink Alternatives, Plant-Based Energy Drinks, Energy Drinks for Active Lifestyles, Effective Energy Boosting Formulas, Energy Drinks for Strength, Energy Drinks with Electrolytes, Energy Drinks for Sports Performance, Convenient Energy Drink Packs, Energy Drinks for Daily Energy, Sustainable Energy Drink Options, Energy Drinks for Students, Zero-Calorie Energy Drinks, Energy Drinks for Busy Professionals, All-Day Energy Drinks."
        />
        <meta property="og:title" content="Energy Drink Ranking Examples" />
        <meta
          property="og:description"
          content="Discover the best value Energy Drinks with our ingredient value ranking system. Whether you’re looking for high caffeine, low sugar, or specific energy-boosting formulas, our rankings highlight top Energy Drink products based on ingredient value."
        />
      </Helmet>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton to={"/energydrink"}>
          <LinkSVG />
          <BackText>Energy Drink Filter</BackText>
        </BackButton>
        <LiveIndicator />
      </BackDiv>

      <>
        <ResponseDiv>
          <ResponseTitle>
            <ReactTyped
              strings={["Zero Sugar Energy Drinks"]}
              typeSpeed={30}
              showCursor={false}
            />
          </ResponseTitle>
          <ResponseSubtitle
            onClick={() =>
              handlePopUp(
                ["caffeine", "vitaminb6", "vitaminb12"], // ADD SUGAR TODO
                props.ingredients
              )
            }
          >
            <SvgWrapper>
              <IngredientEyeSVG />
            </SvgWrapper>
            {submaker(
              ["caffeine", "vitaminb6", "vitaminb12"],
              [],
              props.ingredients
            )}
          </ResponseSubtitle>
        </ResponseDiv>
        <PodiumDivMobile>
          {!exampleLoading ? (
            tieBreaker(exampleFour).map(
              (item, index) =>
                item && (
                  <BrandListCompact
                    item={item}
                    filterRank={item.currentRank}
                    oldRank={item.oldRank}
                    ticker={true}
                    loading={false}
                    userCountry={props.userCountry}
                    ai={true}
                    key={index}
                    filter={exampleFour}
                    filterLink={searchPageGen(
                      "energydrink",
                      null,
                      null,
                      null,
                      null,
                      ["caffeine", "vitaminb6", "vitaminb12"],
                      ["sugar"],
                      [],
                      props.ingredients
                    )}
                    setFilter={props.setEnergyDrinkFilter}
                    setFilterLink={props.setEnergyDrinkFilterLink}
                  />
                )
            )
          ) : (
            <>
              <BrandListCompact
                filterRank={1}
                loading={true}
              ></BrandListCompact>
              <BrandListCompact
                filterRank={2}
                loading={true}
              ></BrandListCompact>
              <BrandListCompact
                filterRank={3}
                loading={true}
              ></BrandListCompact>
            </>
          )}
        </PodiumDivMobile>
        <FilterButtonDiv>
          <ShowMoreButton
            to={searchPageGen(
              "energydrink",
              null,
              null,
              null,
              null,
              ["caffeine", "vitaminb6", "vitaminb12"],
              ["sugar"],
              [],
              props.ingredients
            )}
          >
            <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
          </ShowMoreButton>
        </FilterButtonDiv>
        <TabBar />
        {props.userCountry !== "GB" && (
          <>
            <ResponseDiv>
              <ResponseTitle>
                <ReactTyped
                  strings={["Focus Energy Drinks"]}
                  typeSpeed={30}
                  showCursor={false}
                />
              </ResponseTitle>
              <ResponseSubtitle
                onClick={() => handlePopUp(["alphagpc"], props.ingredients)}
              >
                <SvgWrapper>
                  <IngredientEyeSVG />
                </SvgWrapper>
                {submaker(["alphagpc"], [], props.ingredients)}
              </ResponseSubtitle>
            </ResponseDiv>
            <PodiumDivMobile>
              {!exampleLoading ? (
                tieBreaker(exampleThree).map(
                  (item, index) =>
                    item && (
                      <BrandListCompact
                        item={item}
                        filterRank={item.currentRank}
                        oldRank={item.oldRank}
                        ticker={true}
                        loading={false}
                        userCountry={props.userCountry}
                        ai={true}
                        key={index}
                        filter={exampleThree}
                        filterLink={searchPageGen(
                          "energydrink",
                          null,
                          null,
                          null,
                          null,
                          ["alphagpc"],
                          [],
                          [],
                          props.ingredients
                        )}
                        setFilter={props.setEnergyDrinkFilter}
                        setFilterLink={props.setEnergyDrinkFilterLink}
                      />
                    )
                )
              ) : (
                <>
                  <BrandListCompact
                    filterRank={1}
                    loading={true}
                  ></BrandListCompact>
                  <BrandListCompact
                    filterRank={2}
                    loading={true}
                  ></BrandListCompact>
                  <BrandListCompact
                    filterRank={3}
                    loading={true}
                  ></BrandListCompact>
                </>
              )}
            </PodiumDivMobile>
            <FilterButtonDiv>
              <ShowMoreButton
                to={searchPageGen(
                  "energydrink",
                  null,
                  null,
                  null,
                  null,
                  ["alphagpc"],
                  [],
                  [],
                  props.ingredients
                )}
              >
                <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
              </ShowMoreButton>
            </FilterButtonDiv>
            <TabBar />
            <ResponseDiv>
              <ResponseTitle>
                <ReactTyped
                  strings={["Hydration Energy Drinks"]}
                  typeSpeed={30}
                  showCursor={false}
                />
              </ResponseTitle>
              <ResponseSubtitle
                onClick={() =>
                  handlePopUp(
                    ["caffeine", "potassium", "sodium"],
                    props.ingredients
                  )
                }
              >
                <SvgWrapper>
                  <IngredientEyeSVG />
                </SvgWrapper>
                {submaker(
                  ["caffeine", "potassium", "sodium"],
                  [],
                  props.ingredients
                )}
              </ResponseSubtitle>
            </ResponseDiv>
            <PodiumDivMobile>
              {!exampleLoading ? (
                tieBreaker(exampleFive).map(
                  (item, index) =>
                    item && (
                      <BrandListCompact
                        item={item}
                        filterRank={item.currentRank}
                        oldRank={item.oldRank}
                        ticker={true}
                        loading={false}
                        userCountry={props.userCountry}
                        ai={true}
                        key={index}
                        filter={exampleFive}
                        filterLink={searchPageGen(
                          "energydrink",
                          null,
                          null,
                          null,
                          null,
                          ["caffeine", "potassium", "sodium"],
                          [],
                          [],
                          props.ingredients
                        )}
                        setFilter={props.setEnergyDrinkFilter}
                        setFilterLink={props.setEnergyDrinkFilterLink}
                      />
                    )
                )
              ) : (
                <>
                  <BrandListCompact
                    filterRank={1}
                    loading={true}
                  ></BrandListCompact>
                  <BrandListCompact
                    filterRank={2}
                    loading={true}
                  ></BrandListCompact>
                  <BrandListCompact
                    filterRank={3}
                    loading={true}
                  ></BrandListCompact>
                </>
              )}
            </PodiumDivMobile>
            <FilterButtonDiv>
              <ShowMoreButton
                to={searchPageGen(
                  "energydrink",
                  null,
                  null,
                  null,
                  null,
                  ["caffeine", "potassium", "sodium"],
                  [],
                  [],
                  props.ingredients
                )}
              >
                <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
              </ShowMoreButton>
            </FilterButtonDiv>
            <TabBar />
            <ResponseDiv>
              <ResponseTitle>
                <ReactTyped
                  strings={["Zero Calorie Energy Drinks"]}
                  typeSpeed={30}
                  showCursor={false}
                />
              </ResponseTitle>
              <ResponseSubtitle
                onClick={() =>
                  handlePopUp(["caffeine", "calories"], props.ingredients)
                }
              >
                <SvgWrapper>
                  <IngredientEyeSVG />
                </SvgWrapper>
                {submaker(["caffeine"], [], props.ingredients)}
              </ResponseSubtitle>
            </ResponseDiv>
            <PodiumDivMobile>
              {!exampleLoading ? (
                tieBreaker(exampleTwo).map(
                  (item, index) =>
                    item && (
                      <BrandListCompact
                        item={item}
                        filterRank={item.currentRank}
                        oldRank={item.oldRank}
                        ticker={true}
                        loading={false}
                        userCountry={props.userCountry}
                        ai={true}
                        key={index}
                        filter={exampleTwo}
                        filterLink={searchPageGen(
                          "energydrink",
                          null,
                          null,
                          null,
                          null,
                          ["caffeine"],
                          ["calories"],
                          [],
                          props.ingredients
                        )}
                        setFilter={props.setEnergyDrinkFilter}
                        setFilterLink={props.setEnergyDrinkFilterLink}
                      />
                    )
                )
              ) : (
                <>
                  <BrandListCompact
                    filterRank={1}
                    loading={true}
                  ></BrandListCompact>
                  <BrandListCompact
                    filterRank={2}
                    loading={true}
                  ></BrandListCompact>
                  <BrandListCompact
                    filterRank={3}
                    loading={true}
                  ></BrandListCompact>
                </>
              )}
            </PodiumDivMobile>
            <FilterButtonDiv>
              <ShowMoreButton
                to={searchPageGen(
                  "energydrink",
                  null,
                  null,
                  null,
                  null,
                  ["caffeine"],
                  ["calories"],
                  [],
                  props.ingredients
                )}
              >
                <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
              </ShowMoreButton>
            </FilterButtonDiv>
            <TabBar />

            <ResponseDiv>
              <ResponseTitle>
                <ReactTyped
                  strings={["High Caffeine Energy Drinks"]}
                  typeSpeed={30}
                  showCursor={false}
                />
              </ResponseTitle>
              {/* <ResponseSubtitle>
                {props.userCountry === "GB"
                  ? "Ranked by Caffeine, Citrulline and Glycerol value."
                  : "Ranked by Citrulline, Glycerol and Malic Acid value."}
              </ResponseSubtitle> */}
              <ResponseSubtitle
                onClick={() => handlePopUp(["caffeine"], props.ingredients)}
              >
                <SvgWrapper>
                  <IngredientEyeSVG />
                </SvgWrapper>
                {submaker(
                  ["caffeine"],
                  [{ ingredient: "caffeine", min: 250, max: 300 }],
                  props.ingredients
                )}
              </ResponseSubtitle>
            </ResponseDiv>
            <PodiumDivMobile>
              {!exampleLoading ? (
                tieBreaker(
                  itemFilter(exampleOne, [
                    { ingredient: "caffeine", min: 250, max: 300 },
                  ])
                ).map(
                  (item, index) =>
                    item && (
                      <BrandListCompact
                        item={item}
                        filterRank={item.currentRank}
                        oldRank={item.oldRank}
                        ticker={true}
                        loading={false}
                        ai={true}
                        userCountry={props.userCountry}
                        key={index}
                        filter={exampleOne}
                        filterLink={searchPageGen(
                          "energydrink",
                          null,
                          null,
                          null,
                          null,
                          ["caffeine"],
                          [],
                          [{ ingredient: "caffeine", min: 250, max: 300 }],
                          props.ingredients
                        )}
                        setFilter={props.setEnergyDrinkFilter}
                        setFilterLink={props.setEnergyDrinkFilterLink}
                      />
                    )
                )
              ) : (
                <>
                  <BrandListCompact
                    filterRank={1}
                    loading={true}
                  ></BrandListCompact>
                  <BrandListCompact
                    filterRank={2}
                    loading={true}
                  ></BrandListCompact>
                  <BrandListCompact
                    filterRank={3}
                    loading={true}
                  ></BrandListCompact>
                </>
              )}
            </PodiumDivMobile>
            <FilterButtonDiv>
              <ShowMoreButton
                to={searchPageGen(
                  "energydrink",
                  null,
                  null,
                  null,
                  null,
                  ["caffeine"],
                  [],
                  [{ ingredient: "caffeine", min: 250, max: 300 }],
                  props.ingredients
                )}
              >
                <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
              </ShowMoreButton>
            </FilterButtonDiv>
            <TabBar />
          </>
        )}
        <ResponseDiv>
          <ResponseTitle>
            <ReactTyped
              strings={["Moderate Caffeine Energy Drinks"]}
              typeSpeed={30}
              showCursor={false}
            />
          </ResponseTitle>
          {/* <ResponseSubtitle>
                {props.userCountry === "GB"
                  ? "Ranked by Caffeine, Citrulline and Glycerol value."
                  : "Ranked by Citrulline, Glycerol and Malic Acid value."}
              </ResponseSubtitle> */}
          <ResponseSubtitle
            onClick={() => handlePopUp(["caffeine"], props.ingredients)}
          >
            <SvgWrapper>
              <IngredientEyeSVG />
            </SvgWrapper>
            {submaker(
              ["caffeine"],
              [{ ingredient: "caffeine", min: 125, max: 225 }],
              props.ingredients
            )}
          </ResponseSubtitle>
        </ResponseDiv>
        <PodiumDivMobile>
          {!exampleLoading ? (
            tieBreaker(
              itemFilter(exampleOne, [
                { ingredient: "caffeine", min: 125, max: 225 },
              ])
            ).map(
              (item, index) =>
                item && (
                  <BrandListCompact
                    item={item}
                    filterRank={item.currentRank}
                    oldRank={item.oldRank}
                    ticker={true}
                    loading={false}
                    ai={true}
                    userCountry={props.userCountry}
                    key={index}
                    filter={exampleOne}
                    filterLink={searchPageGen(
                      "energydrink",
                      null,
                      null,
                      null,
                      null,
                      ["caffeine"],
                      [],
                      [{ ingredient: "caffeine", min: 125, max: 225 }],
                      props.ingredients
                    )}
                    setFilter={props.setEnergyDrinkFilter}
                    setFilterLink={props.setEnergyDrinkFilterLink}
                  />
                )
            )
          ) : (
            <>
              <BrandListCompact
                filterRank={1}
                loading={true}
              ></BrandListCompact>
              <BrandListCompact
                filterRank={2}
                loading={true}
              ></BrandListCompact>
              <BrandListCompact
                filterRank={3}
                loading={true}
              ></BrandListCompact>
            </>
          )}
        </PodiumDivMobile>
        <FilterButtonDiv>
          <ShowMoreButton
            to={searchPageGen(
              "energydrink",
              null,
              null,
              null,
              null,
              ["caffeine"],
              [],
              [{ ingredient: "caffeine", min: 125, max: 225 }],
              props.ingredients
            )}
          >
            <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
          </ShowMoreButton>
        </FilterButtonDiv>
        <TabBar />
        <ResponseDiv>
          <ResponseTitle>
            <ReactTyped
              strings={["Low Caffeine Energy Drinks"]}
              typeSpeed={30}
              showCursor={false}
            />
          </ResponseTitle>
          {/* <ResponseSubtitle>
                {props.userCountry === "GB"
                  ? "Ranked by Caffeine, Citrulline and Glycerol value."
                  : "Ranked by Citrulline, Glycerol and Malic Acid value."}
              </ResponseSubtitle> */}
          <ResponseSubtitle
            onClick={() => handlePopUp(["caffeine"], props.ingredients)}
          >
            <SvgWrapper>
              <IngredientEyeSVG />
            </SvgWrapper>
            {submaker(
              ["caffeine"],
              [{ ingredient: "caffeine", min: 0, max: 100 }],
              props.ingredients
            )}
          </ResponseSubtitle>
        </ResponseDiv>
        <PodiumDivMobile>
          {!exampleLoading ? (
            tieBreaker(
              itemFilter(exampleOne, [
                { ingredient: "caffeine", min: 0, max: 100 },
              ])
            ).map(
              (item, index) =>
                item && (
                  <BrandListCompact
                    item={item}
                    filterRank={item.currentRank}
                    oldRank={item.oldRank}
                    ticker={true}
                    loading={false}
                    ai={true}
                    userCountry={props.userCountry}
                    key={index}
                    filter={exampleOne}
                    filterLink={searchPageGen(
                      "energydrink",
                      null,
                      null,
                      null,
                      null,
                      ["caffeine"],
                      [],
                      [{ ingredient: "caffeine", min: 0, max: 100 }],
                      props.ingredients
                    )}
                    setFilter={props.setEnergyDrinkFilter}
                    setFilterLink={props.setEnergyDrinkFilterLink}
                  />
                )
            )
          ) : (
            <>
              <BrandListCompact
                filterRank={1}
                loading={true}
              ></BrandListCompact>
              <BrandListCompact
                filterRank={2}
                loading={true}
              ></BrandListCompact>
              <BrandListCompact
                filterRank={3}
                loading={true}
              ></BrandListCompact>
            </>
          )}
        </PodiumDivMobile>
        <FilterButtonDiv>
          <ShowMoreButton
            to={searchPageGen(
              "energydrink",
              null,
              null,
              null,
              null,
              ["caffeine"],
              [],
              [{ ingredient: "caffeine", min: 0, max: 100 }],
              props.ingredients
            )}
            style={{ marginBottom: "25px" }}
          >
            <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
          </ShowMoreButton>
        </FilterButtonDiv>
      </>
      {/* <LastTabBar /> */}
      <PopUp
        isOpen={isPopupOpen}
        onClose={closePopup}
        ingredients={popupIngredients}
        ingredientList={popupIngredientList}
      />
      <AdPopUp canOpen={!isPopupOpen} accessories={props.accessories} />
    </center>
  );
};

export default EnergyDrinkExample;
