import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import BrandListCompact from "../components/BrandListCompact";
import { trackEvent } from "../custom/analytics";
import {
  filterTieBreaker,
  itemFilter,
  submaker,
  titleMakerEnergy,
} from "../custom/functions";
import { ChartSVG, IngredientEyeSVG } from "../custom/svg";
import { LiveIndicator } from "../custom/animations";
import { Regions } from "../custom/functions";
import PopUp from "../components/PopUp";
import IngredientRankPopUp from "../components/IngredientRankPopUp";
import ExcludeRankPopUp from "../components/ExcludeRankPopUp";
import BrandListPlaceholder from "../components/BrandListPlaceholder";
import { Helmet } from "react-helmet-async";
import AdPopUp from "../components/AdPopUp";
import {
  PodiumDivMobile,
  SvgWrapper,
  FilterSubtitle,
  Title,
  ListDiv,
  SuggestionDiv,
  Suggestion,
  SuggestionText,
  BackDiv,
  BackButton,
  BackText,
  RankInfo,
  RankText,
} from "../css/fetchPage";

// const Ingredients = preIngredients;
const Ingredients = [
  { value: "alphagpc", label: "Alpha GPC" },
  // { value: "betaalanine", label: "Beta Alanine" },
  { value: "betaine", label: "Betaine Anhydrous" },
  { value: "caffeine", label: "Caffeine" },
  // { value: "citrulline", label: "Citrulline" },
  // { value: "creatine", label: "Creatine Monohydrate" },
  // { value: "glycerol", label: "Glycerol" },
  // { value: "huperzinea", label: "Huperzine-A" },
  // { value: "malicacid", label: "Malic Acid" },
  // { value: "nitrosigne", label: "Nitrosigine®" },
  // { value: "himalayan", label: "Pink Himalayan Sea Salt" },
  { value: "taurine", label: "Taurine" },
  // { value: "theanine", label: "Theanine" },
  { value: "tyrosine", label: "Tyrosine" },
  { value: "vitaminc", label: "Vitamin C" },
  { value: "vitaminb6", label: "Vitamin B6" },
  { value: "vitaminb12", label: "Vitamin B12" },
];

const ExcludeIngredients = [
  { value: "addedsugars", label: "Added Sugar" },
  { value: "calories", label: "Calories" },
  { value: "sodium", label: "Sodium" },
  { value: "sugar", label: "Sugar" },
];

const SelectCategories = [
  { value: "rank", label: "Rank Ingredients" },
  { value: "exclude", label: "Exclude Ingredients" },
];

function newapiHandler(
  selectedRank,
  selectedExclude,
  userCountry,
  ingredients
) {
  let link = "/api/energydrink?";
  const params = new URLSearchParams();

  selectedRank.forEach((ingredient, index) => {
    params.append(`ingredient${index + 1}`, ingredient);
  });

  selectedExclude.forEach((exclude, index) => {
    params.append(`exclude${index + 1}`, exclude);
  });

  params.append(`region`, userCountry);

  link += params.toString();
  // console.log(link);
  return link;
}

const EnergyFetchFilter = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [advancedMode, setAdvancedMode] = useState(false);
  const [infoActive, setInfoActive] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [regionSelected, setRegion] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isRankPopupOpen, setRankPopupOpen] = useState(false);
  const [isExcludePopupOpen, setExcludePopupOpen] = useState(false);
  const [originalMax, setOriginalMax] = useState(-Infinity);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  const openRankPopup = () => setRankPopupOpen(true);
  const closeRankPopup = () => setRankPopupOpen(false);

  const openExcludePopup = () => setExcludePopupOpen(true);
  const closeExcludePopup = () => setExcludePopupOpen(false);

  const getParamArray = (paramPrefix, rangePrefix) => {
    const ingredientsArray = [];
    const rangeDataArray = [];

    for (let i = 1; i <= 10; i++) {
      const ingredient = searchParams.get(`${paramPrefix}${i}`);
      const range = searchParams.get(`${rangePrefix}${i}`);

      if (ingredient) {
        ingredientsArray.push(ingredient.toLowerCase());

        // Parse the range into min and max
        let min = null;
        let max = null;
        if (range) {
          const [parsedMin, parsedMax] = range.split("-");
          min = parsedMin ? parseInt(parsedMin, 10) : null;
          max = parsedMax ? parseInt(parsedMax, 10) : null;
        }

        // Add the correct ingredient and parsed min-max values
        rangeDataArray.push({
          ingredient: ingredient.toLowerCase(), // Correct ingredient name
          min: min,
          max: max,
          hide: min === null || max === null,
        });
      }
    }

    return { ingredientsArray, rangeDataArray };
  };

  const setParamArray = (params, paramPrefix, valuesArray) => {
    // Remove old parameters with the same prefix
    for (let i = 1; i <= 10; i++) {
      params.delete(`${paramPrefix}${i}`);
    }

    // Add new parameters
    valuesArray.forEach((value, index) => {
      if (value) {
        params.set(`${paramPrefix}${index + 1}`, value);
      }
    });
  };

  const setParamRangeArray = (
    params,
    paramPrefix,
    rangePrefix,
    ingredientsArray,
    rangeDataArray
  ) => {
    // Remove old parameters with the same prefix
    for (let i = 1; i <= 10; i++) {
      params.delete(`${paramPrefix}${i}`);
      params.delete(`${rangePrefix}${i}`);
    }

    // Add new parameters for ingredients and only add range if hide is false
    ingredientsArray.forEach((ingredient, index) => {
      // Always add the ingredient
      params.set(`${paramPrefix}${index + 1}`, ingredient);

      // Find the corresponding range for this ingredient
      const range = rangeDataArray.find((r) => r.ingredient === ingredient);

      // Add range only if hide is false and both min and max are available
      if (range && !range.hide && range.min !== null && range.max !== null) {
        const rangeValue = `${range.min}-${range.max}`;
        params.set(`${rangePrefix}${index + 1}`, rangeValue);
      }
    });
  };

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [originalItems, setOriginalItems] = useState(null);
  const [finalItems, setFinalItems] = useState([]);
  const [emptySearch, setEmptySearch] = useState(false);
  const [resetting, setResetting] = useState(false);

  const [selectedRankIngredients, setSelectedRankIngredients] = useState(() => {
    const rankIngredients = getParamArray(
      "ingredient",
      "ingredientRange"
    ).ingredientsArray;
    return rankIngredients.length > 0 && rankIngredients
      ? rankIngredients
      : ["caffeine"];
  });
  const [rangeData, setRangeData] = useState(() => {
    return getParamArray("ingredient", "ingredientRange").rangeDataArray;
  });

  // console.log(rangeData);
  const [selectedExcludeIngredients, setSelectedExcludeIngredients] = useState(
    () => {
      return getParamArray("exclude").ingredientsArray;
    }
  );
  const [ingredientList, setIngredientList] = useState(
    !advancedMode ? [...Ingredients] : [...props.ingredients]
  );
  const [excludeList, setExcludeList] = useState(
    !advancedMode ? [...ExcludeIngredients] : [...props.ingredients]
  );

  const reorderList = (activeItem, list) => {
    const filteredList = list.filter((item) => item.value !== activeItem.value);

    return [activeItem, ...filteredList];
  };

  const removeActive = (activeItem, list, selectedLength) => {
    // Remove activeItem from the list
    const filteredList = list.filter((item) => item.value !== activeItem.value);

    // Add activeItem to the end
    filteredList.push(activeItem);

    // Split the list into two parts and sort only the second half
    const firstHalf = filteredList.slice(0, selectedLength - 1);
    const secondHalf = filteredList
      .slice(selectedLength - 1)
      .sort((a, b) => a.value.localeCompare(b.value));

    // Merge the two halves back together
    return [...firstHalf, ...secondHalf];
  };

  const handleCategoryClick = (item) => {
    setSelectedCategory((prevSelected) => {
      if (item.value === prevSelected) {
        return null;
      } else if (item.value === "rank") {
        openRankPopup();
      } else if (item.value === "exclude") {
        openExcludePopup(true);
      } else {
        return item.value;
      }
    });
  };

  const handleRegionClick = (item) => {
    // props.setUserCountry(item.value);
    setRegion((prevSelected) => {
      if (prevSelected === item.value) {
        // setTypeList(
        //   removeActive(item, typeList, 1)
        // );
        return prevSelected;
      } else if (prevSelected !== item.value) {
        // setTypeList(reorderList(item, typeList));
        return item.value;
      } else {
        return prevSelected;
      }
    });
  };

  const reorderSelectedItems = (selectedItems, fullList) => {
    // Filter the list to get the selected items first
    const selectedItemsList = fullList.filter((item) =>
      selectedItems.includes(item.value)
    );
    // Filter the list to get the non-selected items
    const nonSelectedItemsList = fullList.filter(
      (item) => !selectedItems.includes(item.value)
    );
    // Concatenate the selected items at the front and non-selected items at the end
    return [...selectedItemsList, ...nonSelectedItemsList];
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        // apiHandler(ingredientOne, ingredientTwo, excludeOne, excludeTwo)
        newapiHandler(
          selectedRankIngredients,
          selectedExcludeIngredients,
          regionSelected,
          props.ingredients
        )
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      trackEvent("Filter", "Filtered: Energy Drink", "");
      if (result.length > 0) {
        setEmptySearch(false);
        // setMobilePodium([...result.slice(0, 3)]);
        // setList([...result.slice(3, result.length)]);

        const params = new URLSearchParams();
        setParamRangeArray(
          params,
          "ingredient",
          "ingredientRange",
          selectedRankIngredients,
          rangeData
        );
        setParamArray(params, "exclude", selectedExcludeIngredients);
        if (regionSelected) params.append("region", regionSelected);
        setSearchParams(params, { replace: true });
        setOriginalItems(result);
        props.setEnergyDrinkFilter([...result]);
        props.setEnergyDrinkFilterLink("/energydrink?" + params.toString());

        setIngredientList(
          reorderSelectedItems(selectedRankIngredients, ingredientList)
        );
        setExcludeList(
          reorderSelectedItems(selectedExcludeIngredients, excludeList)
        );

        if (resetting) {
          setRangeData([
            { ingredient: "caffeine", min: null, max: null, hide: true },
          ]);
          setResetting(false);
        }

        // checking if an ingredient is in advanced and showing
        selectedRankIngredients.forEach((ingredient) => {
          const exists = Ingredients.some((item) => item.value === ingredient);

          if (!exists && props.ingredients.length > 0) {
            setAdvancedMode(true);
            setIngredientList(
              reorderSelectedItems(selectedRankIngredients, [
                ...props.ingredients,
              ])
            );
          }

          return;
        });

        selectedExcludeIngredients.forEach((ingredient) => {
          const exists = excludeList.some((item) => item.value === ingredient);

          if (!exists && props.ingredients.length > 0) {
            setAdvancedMode(true);
            setExcludeList(
              reorderSelectedItems(selectedExcludeIngredients, [
                ...props.ingredients,
              ])
            );
          }

          return;
        });

        // write function to check for min and maxes for ingredients
        // create min max array {value, min, max}, etc
        // for item in min max array filter response[ingredientName] => min && respone[ingredientName] <= max
        // then send through to finalItems

        // console.log((result));
        setFinalItems(filterTieBreaker(result));
        setLoading(false);
      } else {
        setEmptySearch(true);
      }
    } catch (error) {
      setError(error); // Update the state with the error
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, [props.userCountry]);

  // console.log(finalItems);
  useEffect(() => {
    const regionParam = searchParams.get("region");

    if (regionParam || regionSelected === null) {
      setRegion(regionParam || props.userCountry);
    }
  }, [props.userCountry, searchParams]);

  useEffect(() => {
    if (!regionSelected) return;
    fetchData();
  }, [selectedRankIngredients, selectedExcludeIngredients, regionSelected]);

  // useEffect() [min, max] -> take original items and filter then run through filterTieBreaker

  // useEffect(() => {

  // }, [min, max])

  const getStyle = (item, list) => {
    return list.includes(item.value)
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getTextStyle = (item, list) => {
    return list.includes(item.value)
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  const getCategoryStyle = (item, selected) => {
    return item.value === selected
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getCategoryTextStyle = (item, selected) => {
    return item.value === selected
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  const modeToggle = () => {
    setAdvancedMode(!advancedMode);

    setIngredientList(
      reorderSelectedItems(
        selectedRankIngredients,
        advancedMode ? [...Ingredients] : [...props.ingredients]
      )
    );
    setExcludeList(
      reorderSelectedItems(
        selectedExcludeIngredients,
        advancedMode ? [...ExcludeIngredients] : [...props.ingredients]
      )
    );
    // setIngredientList(advancedMode ? [...Ingredients] : [...preIngredients]);
    // setExcludeList(advancedMode ? [...Ingredients] : [...preIngredients]);
  };

  const infoToggle = () => {
    setInfoActive(!infoActive);
  };

  const Reset = () => {
    setSelectedRankIngredients(["caffeine"]);
    setSelectedExcludeIngredients([]);
    setIngredientList(
      !advancedMode ? [...Ingredients] : [...props.ingredients]
    );
    setExcludeList(
      !advancedMode ? [...ExcludeIngredients] : [...props.ingredients]
    );
    // setRangeData([{ ingredient: "caffeine", min: 0, max: originalMax }]);
    setResetting(true);
  };

  // finding original ingredient max value
  useEffect(() => {
    if (
      !originalItems ||
      originalItems.length === 0 ||
      !selectedRankIngredients ||
      selectedRankIngredients.length === 0
    ) {
      // console.log("Data not available yet.");
      return; // Exit the effect early
    }

    let newMax = -Infinity;

    originalItems.forEach((item) => {
      const baseValue = parseFloat(
        item[selectedRankIngredients[selectedRankIngredients.length - 1]]
      );

      const ingredientTotal = item.twoscoop ? 2 * baseValue : baseValue;

      if (ingredientTotal > newMax) {
        newMax = ingredientTotal;
      }
    });

    // console.log("Setting originalMax to:", newMax);
    setOriginalMax(newMax);
  }, [originalItems, selectedRankIngredients, selectedExcludeIngredients]);

  // filters data based on rangedata
  useEffect(() => {
    // console.log("Filter Effect: ", originalItems);
    const params = new URLSearchParams();
    setParamRangeArray(
      params,
      "ingredient",
      "ingredientRange",
      selectedRankIngredients,
      rangeData
    );
    setParamArray(params, "exclude", selectedExcludeIngredients);
    if (regionSelected) params.append("region", regionSelected);
    setSearchParams(params, { replace: true });

    const filteredItems = itemFilter(originalItems, rangeData);

    setFinalItems(() => {
      if (!originalItems) return []; // Handle the case where originalItems is undefined

      return filteredItems;
    });
    // Set empty search to true if no items are filtered
    if (originalItems) {
      setEmptySearch(filteredItems.length === 0);
    }

    props.setEnergyDrinkFilter([...filteredItems]);
    props.setEnergyDrinkFilterLink("/energydrink?" + params.toString());
  }, [
    originalItems,
    rangeData,
    selectedRankIngredients,
    selectedExcludeIngredients,
  ]);

  // useEffect(() => {
  //   const themeColorMetaTag = document.querySelector('meta[name="theme-color"]');
  //   if (themeColorMetaTag) {
  //     if (isPopupOpen || isRankPopupOpen || isExcludePopupOpen) {
  //       themeColorMetaTag.setAttribute('content', '#7F7F7F'); // Set color when popup is open
  //     } else {
  //       themeColorMetaTag.setAttribute('content', 'white'); // Reset color when popup is closed
  //     }
  //   }
  // }, [isPopupOpen, isRankPopupOpen, isExcludePopupOpen]);

  return (
    <center style={{ overflowX: "hidden" }}>
      <Helmet>
        <title>Energy Drink Rankings</title>
        <meta
          name="description"
          content="Discover the best value Energy Drinks with our ingredient value ranking system. Whether you’re looking for high caffeine, low sugar, or specific energy-boosting formulas, our rankings highlight top Energy Drink products based on ingredient value."
        />
        <meta
          name="keywords"
          content="Energy Drink Rankings, Top Energy Drinks, Best Energy Boosters, Natural Energy Drinks, Low-Sugar Energy Drinks, High-Caffeine Energy Drinks, Energy Drinks for Stamina, Endurance Energy Formulas, Performance Energy Drinks, Energy Drinks for Recovery, Hydration and Energy Drinks, Affordable Energy Drink Options, Energy Drinks for Workout Performance, Pre-Workout Energy Drinks, Post-Workout Energy Blends, Long-Lasting Energy Drinks, Energy Drinks for Focus, Energy Drinks for Athletes, Healthy Energy Drink Alternatives, Plant-Based Energy Drinks, Energy Drinks for Active Lifestyles, Effective Energy Boosting Formulas, Energy Drinks for Strength, Energy Drinks with Electrolytes, Energy Drinks for Sports Performance, Convenient Energy Drink Packs, Energy Drinks for Daily Energy, Sustainable Energy Drink Options, Energy Drinks for Students, Zero-Calorie Energy Drinks, Energy Drinks for Busy Professionals, All-Day Energy Drinks."
        />
        <meta property="og:title" content="Energy Drink Rankings" />
        <meta
          property="og:description"
          content="Discover the best value Energy Drinks with our ingredient value ranking system. Whether you’re looking for high caffeine, low sugar, or specific energy-boosting formulas, our rankings highlight top Energy Drink products based on ingredient value."
        />
      </Helmet>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton onClick={infoToggle}>
          <ChartSVG />
          <BackText>Ranking Info {!infoActive ? "+" : "-"}</BackText>
        </BackButton>
        <LiveIndicator />
      </BackDiv>
      {infoActive && (
        <RankInfo>
          <RankText>
            Products are ranked by Total Selected Ingredient Value. <br />
            Formula: (Total Servings * Ingredient Serving) / Price
          </RankText>
        </RankInfo>
      )}
      <PodiumDivMobile style={{ paddingTop: "0px" }}>
        {!loading ? (
          <>
            {finalItems.slice(0, 3).map((item, index) => {
              if (!item) return null;
              return (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  userCountry={regionSelected}
                  ticker={true}
                  ai={true}
                  key={index + Date.now()}
                  loading={false}
                />
              );
            })}
            {/* Display placeholders if finalItems.length is less than 3 */}
            {finalItems.length < 3 &&
              Array.from({ length: 3 - finalItems.length }).map((_, i) => (
                <BrandListPlaceholder key={`placeholder-${i}`} index={i} />
              ))}
          </>
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true} />
            <BrandListCompact filterRank={2} loading={true} />
            <BrandListCompact filterRank={3} loading={true} />
          </>
        )}
      </PodiumDivMobile>

      <Title>
        {!emptySearch
          ? titleMakerEnergy(selectedExcludeIngredients, props.ingredients)
          : "No Search Results"}
      </Title>
      <FilterSubtitle onClick={!emptySearch ? openPopup : null}>
        {!emptySearch && (
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
        )}
        {!emptySearch
          ? submaker(selectedRankIngredients, rangeData, props.ingredients)
          : "Oops! Your search didn't return products. Please try again."}
      </FilterSubtitle>
      <SuggestionDiv
        style={{ paddingBottom: "10px" }}
      >
        {/* <SuggestionText>Type:</SuggestionText> */}
        {Regions.map(
          (item) =>
            item && (
              <Suggestion
                key={item.value}
                onClick={() => handleRegionClick(item)}
                style={getStyle(item, regionSelected || "")}
              >
                <SuggestionText
                  style={getTextStyle(item, regionSelected || "")}
                >
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      <SuggestionDiv
        style={{
          paddingTop: "5px",
          marginBottom: "30px",
        }}
      >
        {SelectCategories.map(
          (item) =>
            item &&
            !item.hide && (
              <Suggestion
                key={item.value}
                onClick={() => handleCategoryClick(item)}
                style={getCategoryStyle(item, selectedCategory)}
              >
                <SuggestionText
                  style={getCategoryTextStyle(item, selectedCategory)}
                >
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      <ListDiv>
        {!loading ? (
          finalItems.slice(3).map((item, index) => {
            if (!item) return null;
            return (
              <BrandListCompact
                item={item}
                filterRank={item.currentRank}
                oldRank={item.oldRank}
                userCountry={regionSelected}
                ticker={true}
                ai={true}
                key={index + Date.now()}
                loading={false}
              />
            );
          })
        ) : (
          <>
            <BrandListCompact filterRank={4} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={5} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={6} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={7} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={8} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={9} loading={true}></BrandListCompact>
          </>
        )}
      </ListDiv>
      <PopUp
        isOpen={isPopupOpen}
        onClose={closePopup}
        ingredients={selectedRankIngredients}
        ingredientList={props.ingredients}
      />
      <IngredientRankPopUp
        category="energydrink"
        isOpen={isRankPopupOpen}
        onClose={closeRankPopup}
        selectedRankIngredients={selectedRankIngredients}
        setSelectedRankIngredients={setSelectedRankIngredients}
        ingredientList={ingredientList}
        setIngredientList={setIngredientList}
        selectedExcludeIngredients={selectedExcludeIngredients}
        removeActive={removeActive}
        reorderList={reorderList}
        getStyle={getStyle}
        getTextStyle={getTextStyle}
        responseLength={finalItems.length}
        loading={loading}
        modeToggle={modeToggle}
        advancedMode={advancedMode}
        emptySearch={emptySearch}
        Reset={Reset}
        rangeData={rangeData}
        setRangeData={setRangeData}
        originalMax={originalMax}
        originalItems={originalItems}
        ingredients={props.ingredients}
      />
      <ExcludeRankPopUp
        isOpen={isExcludePopupOpen}
        onClose={closeExcludePopup}
        selectedRankIngredients={selectedRankIngredients}
        setSelectedRankIngredients={setSelectedRankIngredients}
        selectedExcludeIngredients={selectedExcludeIngredients}
        setSelectedExcludeIngredients={setSelectedExcludeIngredients}
        excludeList={excludeList}
        setExcludeList={setExcludeList}
        removeActive={removeActive}
        reorderList={reorderList}
        getStyle={getStyle}
        getTextStyle={getTextStyle}
        responseLength={finalItems.length}
        loading={loading}
        modeToggle={modeToggle}
        advancedMode={advancedMode}
        emptySearch={emptySearch}
        Reset={Reset}
      />
      <AdPopUp
        canOpen={!isPopupOpen && !isRankPopupOpen && !isExcludePopupOpen}
        accessories={props.accessories}
      />
      {/* <StyledReactPaginate
        pageCount={totalPages}
        onPageChange={handlePageChange}
        previousLabel={"Prev"}
      /> */}
    </center>
  );
};

export default EnergyFetchFilter;
