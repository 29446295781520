import styled from "styled-components";

export const PodiumDivMobile = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: static;
  width: 70%;
  max-width: 700px;
  gap: 10px;
  display: flex;

  padding-top: 20px;
  padding-bottom: 20px;

  @media (max-width: 760px) {
    display: flex;
    width: 95%;
  }
`;
export const SvgWrapper = styled.span`
  margin-right: 5px;
  margin-bottom: 3px;
  display: inline-flex;
  vertical-align: middle;

  @media (max-width: 760px) {
    margin-bottom: 2px;
  }
`;
export const FilterSubtitle = styled.p`
  color: gray;
  font-size: 1rem;
  font-family: San Francisco Regular;
  padding: 0px 30px;
  max-width: 60%;
  cursor: pointer;

  @media (max-width: 760px) {
    font-size: 0.9rem;
    max-width: 90%;
  }

  @media (min-width: 760px) {
    &:hover {
      text-decoration: underline;
    }
  }

  &:active {
    text-decoration: underline;
  }
`;
export const Title = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  margin: 20px 0px 0px 0px;
  width: 90%;

  @media (max-width: 900px) {
    font-size: 1.3rem;
  }
`;
export const ListDiv = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: static;
  width: 70%;
  max-width: 700px;
  gap: 10px;
  display: flex;
  padding: 0px 0px 20px 0px;

  @media (max-width: 760px) {
    display: flex;
    width: 95%;
  }
`;

export const SuggestionDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  // margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  // margin-bottom: 15px;
  cursor: grab;
  user-select: none; // Prevent text selection during drag

  &:active {
    cursor: grabbing;
  }

  overflow-x: auto; // Changed from 'scroll' to 'auto'

  @media (min-width: 760px) {
    width: 70%;
    max-width: 700px;
  }

  @media (min-width: 760px) {
    justify-content: center;
  }

  // Hiding the scrollbar for WebKit browsers
  &::-webkit-scrollbar {
    display: none;
  }

  // For Firefox 64+
  scrollbar-width: none; // Hides the scrollbar in Firefox

  // For Internet Explorer and Edge
  -ms-overflow-style: none; // Hides the scrollbar in IE and Edge
`;
export const Suggestion = styled.button`
  all: unset;
  display: block;
  background: #efefef;
  border-radius: 7px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  &:active {
    cursor: grabbing;
  }

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;

export const SuggestionText = styled.div`
  color: black;
  font-family: San Francisco Bold;
  font-size: 1rem;
  position: static;
  text-align: center;
  margin: 5px;
  white-space: nowrap;

  @media (max-width: 760px) {
    font-size: 0.9rem;
  }
`;
export const BackDiv = styled.div`
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  line-height: 14px;
  font-family: San Francisco Regular;
  padding-bottom: 10px;
  // background: gray;

  @media (max-width: 760px) {
    width: 90%;
    height: 50px;
    line-height: 12px;
    padding-bottom: 0px;
  }
`;
export const BackButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 3px;

  &:active {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;
  }
`;
export const BackText = styled.p`
  font-size: 14px;
  color: black;
  // margin: 0px;

  @media (max-width: 760px) {
    font-size: 12px;
  }
`;
export const RankInfo = styled.div`
  display: flex;
  align-items: left;
  width: 95%;
  padding-left: 10px;

  @media (max-width: 760px) {
    width: 90%;
  }
`;
export const RankText = styled.p`
  font-size: 14px;
  color: gray;
  margin-top: 0px;
  text-align: left;

  @media (max-width: 760px) {
    font-size: 12px;
  }
`;