import React, { useState, useEffect } from "react";
import { ReactTyped } from "react-typed";
import PopUp from "../components/PopUp";
import BrandListCompact from "../components/BrandListCompact";
import {
  tieBreaker,
  searchPageGen,
  subMakerProtein,
} from "../custom/functions";
import { IngredientEyeSVG, LinkSVG } from "../custom/svg";
import podiumGlyph from "../images/podiumglyph.png";
import { LiveIndicator } from "../custom/animations";
import { Helmet } from "react-helmet-async";
import AdPopUp from "../components/AdPopUp";
import {
  BackDiv,
  BackButton,
  BackText,
  ResponseDiv,
  ResponseTitle,
  ResponseSubtitle,
  SvgWrapper,
  PodiumDivMobile,
  FilterButtonDiv,
  ShowMoreButton,
  PodiumGlyphImg,
  TabBar,
} from "../css/examplePage";

const ProteinBarExample = (props) => {
  const [exampleLoading, setExampleLoading] = useState(true);
  const [exampleOne, setExampleOne] = useState([]);
  const [exampleTwo, setExampleTwo] = useState([]);
  const [exampleThree, setExampleThree] = useState([]);
  const [exampleFour, setExampleFour] = useState([]);
  const [exampleFive, setExampleFive] = useState([]);
  const [exampleSix, setExampleSix] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupIngredients, setPopupIngredients] = useState(null);
  const [popupIngredientList, setPopupIngredientList] = useState(null);

  const closePopup = () => setPopupOpen(false);

  const handlePopUp = (ingredients, ingredientList) => {
    setPopupOpen(true);
    setPopupIngredients(ingredients);
    setPopupIngredientList(ingredientList);
  };

  useEffect(() => {
    if (!props.userCountry) return;

    const fetchExampleData = (url) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.ok) {
            const data = await response.json();
            resolve(data);
          } else {
            reject(new Error(`HTTP error! status: ${response.status}`));
          }
        } catch (error) {
          reject(error);
        }
      });
    };

    const fetchAllExamples = (userCountry) => {
      let fetchOne;
      let fetchTwo;
      let fetchThree;
      let fetchFour;
      let fetchFive;
      let fetchSix;

      fetchOne = fetchExampleData(
        `/api/proteinbar?rank=lowcal&region=` + userCountry
      );
      fetchTwo = fetchExampleData(
        `/api/proteinbar?rank=highcal&region=` + userCountry
      );
      fetchThree = fetchExampleData(
        `/api/proteinbar?filter=vegan&region=` + userCountry
      );
      fetchFour = fetchExampleData(`/api/proteinbar?region=` + userCountry);
      fetchFive = fetchExampleData(
        `/api/proteinbar?filter=glutenfree&region=` + userCountry
      );
      fetchSix = fetchExampleData(
        `/api/proteinbar?type=whey&region=` + userCountry
      );

      setExampleLoading(true);

      Promise.all([
        fetchOne,
        fetchTwo,
        fetchThree,
        fetchFour,
        fetchFive,
        fetchSix,
      ])
        .then(([dataOne, dataTwo, dataThree, dataFour, dataFive, dataSix]) => {
          setExampleOne(dataOne);
          setExampleTwo(dataTwo);
          setExampleThree(dataThree);
          setExampleFour(dataFour);
          setExampleFive(dataFive);
          setExampleSix(dataSix);
          setExampleLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchAllExamples(props.userCountry);
  }, [props.userCountry]);

  return (
    <center>
      <Helmet>
        <title>Protein Bar Ranking Examples</title>
        <meta
          name="description"
          content="Discover the best value Protein Bars with our protein value ranking system. Whether you want a low calorie, gluten free, or vegan formula, our rankings highlight top Protein Bars based on protein value."
        />
        <meta
          name="keywords"
          content="best protein bars, protein bar rankings, protein bar supplement, protein bar value, high calorie protein bar, low calorie protein bar, top protein bars, effective protein bar ingredients, affordable protein bar, workout supplements, protein bar comparison, ingredient-based protein bar, ingredient value protein bar, protein bar ingredient definitions"
        />
        <meta property="og:title" content="Protein Bar Ranking Examples" />
        <meta
          property="og:description"
          content="Discover the best value Protein Bars with our protein value ranking system. Whether you want a low calorie, gluten free, or vegan formula, our rankings highlight top Protein Bars based on protein value."
        />
      </Helmet>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton to={"/proteinbar"}>
          <LinkSVG />
          <BackText>Protein Bar Filter</BackText>
        </BackButton>
        <LiveIndicator />
      </BackDiv>
      {props.userCountry !== "GB" && (
        <>
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["Whey Protein Bars"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            <ResponseSubtitle
              onClick={() => handlePopUp(["whey"], props.ingredients)}
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {subMakerProtein("")}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(exampleSix).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      userCountry={props.userCountry}
                      ai={true}
                      key={index}
                      filter={exampleOne}
                      filterLink={searchPageGen(
                        "proteinbar",
                        null,
                        null,
                        null,
                        "whey",
                        [],
                        [],
                        [],
                        props.ingredients
                      )}
                      setFilter={props.setProteinBarFilter}
                      setFilterLink={props.setProteinBarFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "proteinbar",
                null,
                null,
                null,
                "whey",
                [],
                [],
                [],
                props.ingredients
              )}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
          <TabBar />
        </>
      )}
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Low Calorie Protein Bars"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() =>
            handlePopUp(["calories", "protein"], props.ingredients)
          }
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {subMakerProtein("lowcal")}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleOne).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleOne}
                  filterLink={searchPageGen(
                    "proteinbar",
                    null,
                    "lowcal",
                    null,
                    null,
                    [],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setProteinBarFilter}
                  setFilterLink={props.setProteinBarFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "proteinbar",
            null,
            "lowcal",
            null,
            null,
            [],
            [],
            [],
            props.ingredients
          )}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["High Calorie Protein Bars"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() =>
            handlePopUp(["calories", "protein"], props.ingredients)
          }
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {subMakerProtein("highcal")}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleTwo).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleTwo}
                  filterLink={searchPageGen(
                    "proteinbar",
                    null,
                    "highcal",
                    null,
                    null,
                    [],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setProteinBarFilter}
                  setFilterLink={props.setProteinBarFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "proteinbar",
            null,
            "highcal",
            null,
            null,
            [],
            [],
            [],
            props.ingredients
          )}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Gluten Free Protein Bars"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        {/* <ResponseSubtitle>
                {props.userCountry === "GB"
                  ? "Ranked by Caffeine, Citrulline and Glycerol value."
                  : "Ranked by Citrulline, Glycerol and Malic Acid value."}
              </ResponseSubtitle> */}
        <ResponseSubtitle
          onClick={() => handlePopUp(["protein"], props.ingredients)}
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {subMakerProtein(null)}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleFive).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  ai={true}
                  userCountry={props.userCountry}
                  key={index}
                  filter={exampleFive}
                  filterLink={searchPageGen(
                    "proteinbar",
                    "glutenfree",
                    null,
                    null,
                    null,
                    [],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setProteinBarFilter}
                  setFilterLink={props.setProteinBarFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "proteinbar",
            "glutenfree",
            null,
            null,
            null,
            [],
            [],
            [],
            props.ingredients
          )}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Vegan Protein Bars"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        {/* <ResponseSubtitle>
                {props.userCountry === "GB"
                  ? "Ranked by Caffeine, Citrulline and Glycerol value."
                  : "Ranked by Citrulline, Glycerol and Malic Acid value."}
              </ResponseSubtitle> */}
        <ResponseSubtitle
          onClick={() => handlePopUp(["protein"], props.ingredients)}
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {subMakerProtein(null)}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleThree).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  ai={true}
                  userCountry={props.userCountry}
                  key={index}
                  filter={exampleThree}
                  filterLink={searchPageGen(
                    "proteinbar",
                    "vegan",
                    null,
                    null,
                    null,
                    [],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setProteinBarFilter}
                  setFilterLink={props.setProteinBarFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "proteinbar",
            "vegan",
            null,
            null,
            null,
            [],
            [],
            [],
            props.ingredients
          )}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["All Protein Bars"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() => handlePopUp(["protein"], props.ingredients)}
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {subMakerProtein(null)}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleFour).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleFour}
                  filterLink={searchPageGen(
                    "proteinbar",
                    null,
                    null,
                    null,
                    null,
                    [],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setProteinBarFilter}
                  setFilterLink={props.setProteinBarFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "proteinbar",
            null,
            null,
            null,
            null,
            [],
            [],
            [],
            props.ingredients
          )}
          style={{ marginBottom: "25px" }}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      {/* <LastTabBar /> */}
      <PopUp
        isOpen={isPopupOpen}
        onClose={closePopup}
        ingredients={popupIngredients}
        ingredientList={popupIngredientList}
      />
      <AdPopUp canOpen={!isPopupOpen} accessories={props.accessories} />
    </center>
  );
};

export default ProteinBarExample;
