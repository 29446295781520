import React, { useState, useEffect } from "react";
import { ReactTyped } from "react-typed";
import PopUp from "../components/PopUp";
import BrandListCompact from "../components/BrandListCompact";
import {
  tieBreaker,
  searchPageGen,
  itemFilter,
  submaker,
} from "../custom/functions";
import { IngredientEyeSVG, LinkSVG } from "../custom/svg";
import podiumGlyph from "../images/podiumglyph.png";
import { LiveIndicator } from "../custom/animations";
import { Helmet } from "react-helmet-async";
import AdPopUp from "../components/AdPopUp";
import FanPopUp from "../components/FanPopUp";
import {
  BackDiv,
  BackButton,
  BackText,
  ResponseDiv,
  ResponseTitle,
  ResponseSubtitle,
  SvgWrapper,
  PodiumDivMobile,
  FilterButtonDiv,
  ShowMoreButton,
  PodiumGlyphImg,
  TabBar,
} from "../css/examplePage";

const PreExample = (props) => {
  const [exampleLoading, setExampleLoading] = useState(true);
  const [exampleOne, setExampleOne] = useState([]);
  const [exampleTwo, setExampleTwo] = useState([]);
  const [exampleThree, setExampleThree] = useState([]);
  const [exampleFour, setExampleFour] = useState([]);
  const [exampleFive, setExampleFive] = useState([]);
  const [exampleSix, setExampleSix] = useState([]);
  const [exampleSeven, setExampleSeven] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupIngredients, setPopupIngredients] = useState(null);
  const [popupIngredientList, setPopupIngredientList] = useState(null);

  const closePopup = () => setPopupOpen(false);

  const handlePopUp = (ingredients, ingredientList) => {
    setPopupOpen(true);
    setPopupIngredients(ingredients);
    setPopupIngredientList(ingredientList);
  };

  useEffect(() => {
    if (!props.userCountry) return;

    const fetchExampleData = (url) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.ok) {
            const data = await response.json();
            resolve(data);
          } else {
            reject(new Error(`HTTP error! status: ${response.status}`));
          }
        } catch (error) {
          reject(error);
        }
      });
    };

    const fetchAllExamples = (userCountry) => {
      let fetchOne;
      let fetchTwo;
      let fetchThree;
      let fetchFour;
      let fetchFive;
      let fetchSix;
      let fetchSeven;

      if (userCountry === "GB") {
        fetchOne = fetchExampleData(
          `/api/preworkout?ingredient1=caffeine&ingredient2=cdpcholine&ingredient3=citrulline&region=` +
            userCountry
        );
        fetchTwo = fetchExampleData(
          `/api/preworkout?ingredient1=caffeine&ingredient2=citrulline&ingredient3=glycerol&region=` +
            userCountry
        );
        fetchThree = fetchExampleData(
          `/api/preworkout?ingredient1=citrulline&ingredient2=glycerol&exclude1=caffeine&region=` +
            userCountry
        );
        fetchSeven = fetchExampleData(
          "/api/preworkout?ingredient1=betaalanine&ingredient2=caffeine&ingredient3=citrulline&ingredient4=taurine&ingredient5=tyrosine&region=" +
            userCountry
        );
      } else {
        fetchOne = fetchExampleData(
          `/api/preworkout?ingredient1=citrulline&ingredient2=glycerol&ingredient3=malicacid&ingredient4=caffeine&ingredient5=betaine&ingredient6=huperzinea&region=` +
            userCountry
        );
        fetchTwo = fetchExampleData(
          `/api/preworkout?ingredient1=caffeine&ingredient2=yohimbinehcl&region=` +
            userCountry
        );
        fetchThree = fetchExampleData(
          `/api/preworkout?ingredient1=alphagpc&ingredient2=lionsmane&ingredient3=tyrosine&region=` +
            userCountry
        );
        fetchFour = fetchExampleData(
          `/api/preworkout?ingredient1=betaine&ingredient2=betaalanine&ingredient3=caffeine&ingredient4=citrulline&ingredient5=taurine&ingredient6=tyrosine&region=` +
            userCountry
        );
        fetchFive = fetchExampleData(
          "/api/preworkout?ingredient1=caffeine&ingredient2=citrulline&ingredient3=betaine&ingredient4=tyrosine&ingredient5=taurine&ingredient6=betaalanine&ingredient7=huperzinea&ingredient8=alphagpc&region=" +
            userCountry
        );
        fetchSix = fetchExampleData(
          "/api/preworkout?ingredient1=nitrosigne&ingredient2=citrulline&ingredient3=glycerol&exclude1=caffeine&region=" +
            userCountry
        );
        fetchSeven = fetchExampleData(
          "/api/preworkout?ingredient1=betaalanine&ingredient2=caffeine&ingredient3=citrulline&ingredient4=taurine&ingredient5=alphagpc&ingredient6=betaine&exclude1=yohimbinehcl&region=" +
            userCountry
        );
      }

      setExampleLoading(true);

      Promise.all([
        fetchOne,
        fetchTwo,
        fetchThree,
        fetchFour,
        fetchFive,
        fetchSix,
        fetchSeven,
      ])
        .then(
          ([
            dataOne,
            dataTwo,
            dataThree,
            dataFour,
            dataFive,
            dataSix,
            dataSeven,
          ]) => {
            setExampleOne(dataOne);
            setExampleTwo(dataTwo);
            setExampleThree(dataThree);
            setExampleFour(dataFour);
            setExampleFive(dataFive);
            setExampleSix(dataSix);
            setExampleSeven(dataSeven);
            setExampleLoading(false);
          }
        )
        .catch((error) => {
          console.log(error);
        });
    };

    fetchAllExamples(props.userCountry);
  }, [props.userCountry]);

  return (
    <center>
      <Helmet>
        <title>Pre-Workout Ranking Examples</title>
        <meta
          name="description"
          content="Discover the best value Pre-Workout with our ingredient value ranking system. Whether you want a high stim, low stim, or pump-focused formula, our rankings highlight top Pre-Workouts based on ingredient value."
        />
        <meta
          name="keywords"
          content="best pre-workout, pre-workout rankings, pre-workout supplement, pre-workout value, high stim pre-workout, low stim pre-workout, pump formula, nootropic pre-workout, caffeine free pre-workout, top pre-workout, effective pre-workout ingredients, affordable pre-workout, pre-workout for energy, pre-workout for pump, workout supplements, pre-workout comparison, ingredient-based pre-workout, ingredient value pre-workout, pre-workout ingredient definitions"
        />
        <meta property="og:title" content="Pre-Workout Ranking Examples" />
        <meta
          property="og:description"
          content="Discover the best value Pre-Workout with our ingredient value ranking system. Whether you want a high stim, low stim, or pump-focused formula, our rankings highlight top Pre-Workouts based on ingredient value."
        />
      </Helmet>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton to={"/preworkout?ingredient1=caffeine"}>
          <LinkSVG />
          <BackText>Pre-Workout Filter</BackText>
        </BackButton>
        <LiveIndicator />
      </BackDiv>
      {props.userCountry === "US" && (
        <>
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["Balanced Pre-Workout"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            <ResponseSubtitle
              onClick={() =>
                handlePopUp(
                  [
                    "betaalanine",
                    "betaine",
                    "caffeine",
                    "citrulline",
                    "taurine",
                    "tyrosine",
                  ],
                  props.ingredients
                )
              }
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {submaker(
                [
                  "betaalanine",
                  "betaine",
                  "caffeine",
                  "citrulline",
                  "taurine",
                  "tyrosine",
                ],
                [],
                props.ingredients
              )}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(exampleFour).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      userCountry={props.userCountry}
                      ai={true}
                      key={index}
                      filter={exampleFour}
                      filterLink={searchPageGen(
                        "preworkout",
                        null,
                        null,
                        null,
                        null,
                        [
                          "betaalanine",
                          "betaine",
                          "caffeine",
                          "citrulline",
                          "taurine",
                          "tyrosine",
                        ],
                        [],
                        [],
                        props.ingredients
                      )}
                      setFilter={props.setPreFilter}
                      setFilterLink={props.setPreFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "preworkout",
                null,
                null,
                null,
                null,
                [
                  "betaalanine",
                  "betaine",
                  "caffeine",
                  "citrulline",
                  "taurine",
                  "tyrosine",
                ],
                [],
                [],
                props.ingredients
              )}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
          <TabBar />
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["Performance Pre-Workout"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            <ResponseSubtitle
              onClick={() =>
                handlePopUp(
                  [
                    "alphagpc",
                    "betaalanine",
                    "betaine",
                    "caffeine",
                    "citrulline",
                    "huperzinea",
                    "taurine",
                    "tyrosine",
                  ],
                  props.ingredients
                )
              }
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {submaker(
                [
                  "alphagpc",
                  "betaalanine",
                  "betaine",
                  "caffeine",
                  "citrulline",
                  "huperzinea",
                  "taurine",
                  "tyrosine",
                ],
                [],
                props.ingredients
              )}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(exampleFive).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      userCountry={props.userCountry}
                      ai={true}
                      key={index}
                      filter={exampleFive}
                      filterLink={searchPageGen(
                        "preworkout",
                        null,
                        null,
                        null,
                        null,
                        [
                          "alphagpc",
                          "betaalanine",
                          "betaine",
                          "caffeine",
                          "citrulline",
                          "huperzinea",
                          "taurine",
                          "tyrosine",
                        ],
                        [],
                        [],
                        props.ingredients
                      )}
                      setFilter={props.setPreFilter}
                      setFilterLink={props.setPreFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "preworkout",
                null,
                null,
                null,
                null,
                [
                  "alphagpc",
                  "betaalanine",
                  "betaine",
                  "caffeine",
                  "citrulline",
                  "huperzinea",
                  "taurine",
                  "tyrosine",
                ],
                [],
                [],
                props.ingredients
              )}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
          <TabBar />
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["Pump Pre-Workout"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            {/* <ResponseSubtitle>
                {props.userCountry === "GB"
                  ? "Ranked by Caffeine, Citrulline and Glycerol value."
                  : "Ranked by Citrulline, Glycerol and Malic Acid value."}
              </ResponseSubtitle> */}
            <ResponseSubtitle
              onClick={() =>
                handlePopUp(
                  [
                    "betaine",
                    "caffeine",
                    "citrulline",
                    "glycerol",
                    "huperzinea",
                    "malicacid",
                  ],
                  props.ingredients
                )
              }
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {submaker(
                [
                  "betaine",
                  "caffeine",
                  "citrulline",
                  "glycerol",
                  "huperzinea",
                  "malicacid",
                ],
                [],
                props.ingredients
              )}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(exampleOne).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      ai={true}
                      userCountry={props.userCountry}
                      key={index}
                      filter={exampleOne}
                      filterLink={searchPageGen(
                        "preworkout",
                        null,
                        null,
                        null,
                        null,
                        [
                          "betaine",
                          "caffeine",
                          "citrulline",
                          "glycerol",
                          "huperzinea",
                          "malicacid",
                        ],
                        [],
                        [],
                        props.ingredients
                      )}
                      setFilter={props.setPreFilter}
                      setFilterLink={props.setPreFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "preworkout",
                null,
                null,
                null,
                null,
                [
                  "betaine",
                  "caffeine",
                  "citrulline",
                  "glycerol",
                  "huperzinea",
                  "malicacid",
                ],
                [],
                [],
                props.ingredients
              )}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
          <TabBar />
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["Caffeine Free Pump Pre-Workout"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            {/* <ResponseSubtitle>
                {props.userCountry === "GB"
                  ? "Ranked by Caffeine, Citrulline and Glycerol value."
                  : "Ranked by Citrulline, Glycerol and Malic Acid value."}
              </ResponseSubtitle> */}
            <ResponseSubtitle
              onClick={() =>
                handlePopUp(
                  ["citrulline", "glycerol", "nitrosigne"],
                  props.ingredients
                )
              }
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {submaker(
                ["citrulline", "glycerol", "nitrosigne"],
                [],
                props.ingredients
              )}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(exampleSix).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      ai={true}
                      userCountry={props.userCountry}
                      key={index}
                      filter={exampleSix}
                      filterLink={searchPageGen(
                        "preworkout",
                        null,
                        null,
                        null,
                        null,
                        ["citrulline", "glycerol", "nitrosigne"],
                        ["caffeine"],
                        [],
                        props.ingredients
                      )}
                      setFilter={props.setPreFilter}
                      setFilterLink={props.setPreFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "preworkout",
                null,
                null,
                null,
                null,
                ["citrulline", "glycerol", "nitrosigne"],
                ["caffeine"],
                [],
                props.ingredients
              )}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
          <TabBar />
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["High Stim Pre-Workout"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            {/* <ResponseSubtitle>
                {props.userCountry === "GB"
                  ? "Ranked by Caffeine, Citrulline and Glycerol value."
                  : "Ranked by Citrulline, Glycerol and Malic Acid value."}
              </ResponseSubtitle> */}
            <ResponseSubtitle
              onClick={() =>
                handlePopUp(["caffeine", "yohimbinehcl"], props.ingredients)
              }
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {submaker(
                ["caffeine", "yohimbinehcl"],
                [{ ingredient: "caffeine", min: 350, max: 575 }],
                props.ingredients
              )}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(
                itemFilter(exampleTwo, [
                  { ingredient: "caffeine", min: 350, max: 575 },
                ])
              ).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      ai={true}
                      userCountry={props.userCountry}
                      key={index}
                      filter={itemFilter(exampleTwo, [
                        { ingredient: "caffeine", min: 350, max: 575 },
                      ])}
                      filterLink={searchPageGen(
                        "preworkout",
                        null,
                        null,
                        null,
                        null,
                        ["caffeine", "yohimbinehcl"],
                        [],
                        [{ ingredient: "caffeine", min: 350, max: 575 }],
                        props.ingredients
                      )}
                      setFilter={props.setPreFilter}
                      setFilterLink={props.setPreFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "preworkout",
                null,
                null,
                null,
                null,
                ["caffeine", "yohimbinehcl"],
                [],
                [{ ingredient: "caffeine", min: 350, max: 575 }],
                props.ingredients
              )}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
          <TabBar />
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["Low Stim Pre-Workout"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            {/* <ResponseSubtitle>
                {props.userCountry === "GB"
                  ? "Ranked by Caffeine, Citrulline and Glycerol value."
                  : "Ranked by Citrulline, Glycerol and Malic Acid value."}
              </ResponseSubtitle> */}
            <ResponseSubtitle
              onClick={() =>
                handlePopUp(
                  [
                    "alphagpc",
                    "betaalanine",
                    "betaine",
                    "caffeine",
                    "citrulline",
                    "taurine",
                  ],
                  props.ingredients
                )
              }
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {submaker(
                [
                  "alphagpc",
                  "betaalanine",
                  "betaine",
                  "caffeine",
                  "citrulline",
                  "taurine",
                ],
                [{ ingredient: "caffeine", min: 0, max: 200 }],
                props.ingredients
              )}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(
                itemFilter(exampleSeven, [
                  { ingredient: "caffeine", min: 0, max: 200 },
                ])
              ).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      ai={true}
                      userCountry={props.userCountry}
                      key={index}
                      filter={itemFilter(exampleSeven, [
                        { ingredient: "caffeine", min: 0, max: 200 },
                      ])}
                      filterLink={searchPageGen(
                        "preworkout",
                        null,
                        null,
                        null,
                        null,
                        [
                          "alphagpc",
                          "betaalanine",
                          "betaine",
                          "caffeine",
                          "citrulline",
                          "taurine",
                        ],
                        ["yohimbinehcl"],
                        [{ ingredient: "caffeine", min: 0, max: 200 }],
                        props.ingredients
                      )}
                      setFilter={props.setPreFilter}
                      setFilterLink={props.setPreFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "preworkout",
                null,
                null,
                null,
                null,
                [
                  "alphagpc",
                  "betaalanine",
                  "betaine",
                  "caffeine",
                  "citrulline",
                  "taurine",
                ],
                ["yohimbinehcl"],
                [{ ingredient: "caffeine", min: 0, max: 200 }],
                props.ingredients
              )}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
          <TabBar />
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["Nootropic Pre-Workout"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            {/* <ResponseSubtitle>
                {props.userCountry === "GB"
                  ? "Ranked by Caffeine, Citrulline and Glycerol value."
                  : "Ranked by Citrulline, Glycerol and Malic Acid value."}
              </ResponseSubtitle> */}
            <ResponseSubtitle
              onClick={() =>
                handlePopUp(
                  ["alphagpc", "lionsmane", "tyrosine"],
                  props.ingredients
                )
              }
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {submaker(
                ["alphagpc", "lionsmane", "tyrosine"],
                [],
                props.ingredients
              )}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(exampleThree).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      ai={true}
                      userCountry={props.userCountry}
                      key={index}
                      filter={exampleThree}
                      filterLink={searchPageGen(
                        "preworkout",
                        null,
                        null,
                        null,
                        null,
                        ["alphagpc", "lionsmane", "tyrosine"],
                        [],
                        [],
                        props.ingredients
                      )}
                      setFilter={props.setPreFilter}
                      setFilterLink={props.setPreFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "preworkout",
                null,
                null,
                null,
                null,
                ["alphagpc", "lionsmane", "tyrosine"],
                [],
                [],
                props.ingredients
              )}
              style={{ marginBottom: "25px" }}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
        </>
      )}
      {props.userCountry === "GB" && (
        <>
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["Performance Pre-Workout"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            <ResponseSubtitle
              onClick={() =>
                handlePopUp(
                  ["caffeine", "cdpcholine", "citrulline"],
                  props.ingredients
                )
              }
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {submaker(
                ["caffeine", "cdpcholine", "citrulline"],
                [],
                props.ingredients
              )}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(exampleOne).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      userCountry={props.userCountry}
                      ai={true}
                      key={index}
                      filter={exampleOne}
                      filterLink={searchPageGen(
                        "preworkout",
                        null,
                        null,
                        null,
                        null,
                        ["caffeine", "cdpcholine", "citrulline"],
                        [],
                        [],
                        props.ingredients
                      )}
                      setFilter={props.setPreFilter}
                      setFilterLink={props.setPreFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "preworkout",
                null,
                null,
                null,
                null,
                ["caffeine", "cdpcholine", "citrulline"],
                [],
                [],
                props.ingredients
              )}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
          <TabBar />
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["Pump Pre-Workout"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            <ResponseSubtitle
              onClick={() =>
                handlePopUp(
                  ["caffeine", "citrulline", "glycerol"],
                  props.ingredients
                )
              }
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {submaker(
                ["caffeine", "citrulline", "glycerol"],
                [],
                props.ingredients
              )}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(exampleTwo).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      userCountry={props.userCountry}
                      ai={true}
                      key={index}
                      filter={exampleTwo}
                      filterLink={searchPageGen(
                        "preworkout",
                        null,
                        null,
                        null,
                        null,
                        ["caffeine", "citrulline", "glycerol"],
                        [],
                        [],
                        props.ingredients
                      )}
                      setFilter={props.setPreFilter}
                      setFilterLink={props.setPreFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "preworkout",
                null,
                null,
                null,
                null,
                ["caffeine", "citrulline", "glycerol"],
                [],
                [],
                props.ingredients
              )}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
          <TabBar />
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["High Stim Pre-Workout"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            {/* <ResponseSubtitle>
                {props.userCountry === "GB"
                  ? "Ranked by Caffeine, Citrulline and Glycerol value."
                  : "Ranked by Citrulline, Glycerol and Malic Acid value."}
              </ResponseSubtitle> */}
            <ResponseSubtitle
              onClick={() =>
                handlePopUp(
                  [
                    "betaalanine",
                    "caffeine",
                    "citrulline",
                    "taurine",
                    "tyrosine",
                  ],
                  props.ingredients
                )
              }
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {submaker(
                [
                  "betaalanine",
                  "caffeine",
                  "citrulline",
                  "taurine",
                  "tyrosine",
                ],
                [{ ingredient: "caffeine", min: 350, max: 575 }],
                props.ingredients
              )}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(
                itemFilter(exampleSeven, [
                  { ingredient: "caffeine", min: 350, max: 575 },
                ])
              ).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      ai={true}
                      userCountry={props.userCountry}
                      key={index}
                      filter={exampleSeven}
                      filterLink={searchPageGen(
                        "preworkout",
                        null,
                        null,
                        null,
                        null,
                        [
                          "betaalanine",
                          "caffeine",
                          "citrulline",
                          "taurine",
                          "tyrosine",
                        ],
                        [],
                        [{ ingredient: "caffeine", min: 350, max: 575 }],
                        props.ingredients
                      )}
                      setFilter={props.setPreFilter}
                      setFilterLink={props.setPreFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "preworkout",
                null,
                null,
                null,
                null,
                [
                  "betaalanine",
                  "caffeine",
                  "citrulline",
                  "taurine",
                  "tyrosine",
                ],
                [],
                [{ ingredient: "caffeine", min: 350, max: 575 }],
                props.ingredients
              )}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
          <TabBar />
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["Low Stim Pre-Workout"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            {/* <ResponseSubtitle>
                {props.userCountry === "GB"
                  ? "Ranked by Caffeine, Citrulline and Glycerol value."
                  : "Ranked by Citrulline, Glycerol and Malic Acid value."}
              </ResponseSubtitle> */}
            <ResponseSubtitle
              onClick={() =>
                handlePopUp(
                  [
                    "betaalanine",
                    "caffeine",
                    "citrulline",
                    "taurine",
                    "tyrosine",
                  ],
                  props.ingredients
                )
              }
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {submaker(
                [
                  "betaalanine",
                  "caffeine",
                  "citrulline",
                  "taurine",
                  "tyrosine",
                ],
                [{ ingredient: "caffeine", min: 0, max: 200 }],
                props.ingredients
              )}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(
                itemFilter(exampleSeven, [
                  { ingredient: "caffeine", min: 0, max: 200 },
                ])
              ).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      ai={true}
                      userCountry={props.userCountry}
                      key={index}
                      filter={itemFilter(exampleSeven, [
                        { ingredient: "caffeine", min: 0, max: 200 },
                      ])}
                      filterLink={searchPageGen(
                        "preworkout",
                        null,
                        null,
                        null,
                        null,
                        [
                          "betaalanine",
                          "caffeine",
                          "citrulline",
                          "taurine",
                          "tyrosine",
                        ],
                        [],
                        [{ ingredient: "caffeine", min: 0, max: 200 }],
                        props.ingredients
                      )}
                      setFilter={props.setPreFilter}
                      setFilterLink={props.setPreFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "preworkout",
                null,
                null,
                null,
                null,
                [
                  "betaalanine",
                  "caffeine",
                  "citrulline",
                  "taurine",
                  "tyrosine",
                ],
                [],
                [{ ingredient: "caffeine", min: 0, max: 200 }],
                props.ingredients
              )}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
          <TabBar />
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["Caffeine Free Pump Pre-Workout"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            <ResponseSubtitle
              onClick={() =>
                handlePopUp(["citrulline", "glycerol"], props.ingredients)
              }
            >
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {submaker(["citrulline", "glycerol"], [], props.ingredients)}
            </ResponseSubtitle>
          </ResponseDiv>
          <PodiumDivMobile>
            {!exampleLoading ? (
              tieBreaker(exampleThree).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      userCountry={props.userCountry}
                      ai={true}
                      key={index}
                      filter={exampleThree}
                      filterLink={searchPageGen(
                        "preworkout",
                        null,
                        null,
                        null,
                        null,
                        ["citrulline", "glycerol"],
                        ["caffeine"],
                        [],
                        props.ingredients
                      )}
                      setFilter={props.setPreFilter}
                      setFilterLink={props.setPreFilterLink}
                    />
                  )
              )
            ) : (
              <>
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </>
            )}
          </PodiumDivMobile>
          <FilterButtonDiv>
            <ShowMoreButton
              to={searchPageGen(
                "preworkout",
                null,
                null,
                null,
                null,
                ["citrulline", "glycerol"],
                ["caffeine"],
                [],
                props.ingredients
              )}
              style={{ marginBottom: "25px" }}
            >
              <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
            </ShowMoreButton>
          </FilterButtonDiv>
        </>
      )}
      {/* <LastTabBar /> */}
      <PopUp
        isOpen={isPopupOpen}
        onClose={closePopup}
        ingredients={popupIngredients}
        ingredientList={popupIngredientList}
      />
      {/* <AdPopUp
        canOpen={!isPopupOpen && props.userCountry === "GB"}
        accessories={props.accessories}
      /> */}
      <FanPopUp canOpen={!isPopupOpen} userCountry={props.userCountry} />
    </center>
  );
};

export default PreExample;
