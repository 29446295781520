import React, { useState, useEffect } from "react";
import { ReactTyped } from "react-typed";
import PopUp from "../components/PopUp";
import BrandListCompact from "../components/BrandListCompact";
import { tieBreaker, searchPageGen, submaker } from "../custom/functions";
import { IngredientEyeSVG, LinkSVG } from "../custom/svg";
import podiumGlyph from "../images/podiumglyph.png";
import { LiveIndicator } from "../custom/animations";
import { Helmet } from "react-helmet-async";
import AdPopUp from "../components/AdPopUp";
import {
  BackDiv,
  BackButton,
  BackText,
  ResponseDiv,
  ResponseTitle,
  ResponseSubtitle,
  SvgWrapper,
  PodiumDivMobile,
  FilterButtonDiv,
  ShowMoreButton,
  PodiumGlyphImg,
  TabBar,
} from "../css/examplePage";
import FanPopUp from "../components/FanPopUp";

const AminoExample = (props) => {
  const [exampleLoading, setExampleLoading] = useState(true);
  const [exampleOne, setExampleOne] = useState([]);
  const [exampleFour, setExampleFour] = useState([]);
  const [exampleFive, setExampleFive] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupIngredients, setPopupIngredients] = useState(null);
  const [popupIngredientList, setPopupIngredientList] = useState(null);

  const closePopup = () => setPopupOpen(false);

  const handlePopUp = (ingredients, ingredientList) => {
    setPopupOpen(true);
    setPopupIngredients(ingredients);
    setPopupIngredientList(ingredientList);
  };

  useEffect(() => {
    if (!props.userCountry) return;

    const fetchExampleData = (url) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.ok) {
            const data = await response.json();
            resolve(data);
          } else {
            reject(new Error(`HTTP error! status: ${response.status}`));
          }
        } catch (error) {
          reject(error);
        }
      });
    };

    const fetchAllExamples = (userCountry) => {
      let fetchOne;
      let fetchFour;
      let fetchFive;

      fetchOne = fetchExampleData(
        `/api/aminoacid?ingredient1=leucine&ingredient2=isoleucine&ingredient3=valine&filter=capsule&region=` +
          userCountry
      );
      fetchFour = fetchExampleData(
        `/api/aminoacid?ingredient1=leucine&ingredient2=isoleucine&ingredient3=valine&region=` +
          userCountry
      );
      fetchFive = fetchExampleData(
        "/api/aminoacid?ingredient1=histidine&ingredient2=isoleucine&ingredient3=leucine&ingredient4=lysine&ingredient5=methionine&ingredient6=phenylalanine&ingredient7=threonine&ingredient8=tryptophan&ingredient9=valine&region=" +
          userCountry
      );

      setExampleLoading(true);

      Promise.all([
        fetchOne,
        fetchFour,
        fetchFive,
      ])
        .then(
          ([
            dataOne,
            dataFour,
            dataFive,
          ]) => {
            setExampleOne(dataOne);
            setExampleFour(dataFour);
            setExampleFive(dataFive);
            setExampleLoading(false);
          }
        )
        .catch((error) => {
          console.log(error);
        });
    };

    fetchAllExamples(props.userCountry);
  }, [props.userCountry]);

  return (
    <center>
      <Helmet>
        <title>Amino Acid Ranking Examples</title>
        <meta
          name="description"
          content="Discover the best value Amino Acids with our ingredient value ranking system. Whether you want Branch Chain, Essential, or specific Amino Acids, our rankings highlight top Amino Acid products based on ingredient value."
        />
        <meta
          name="keywords"
          content="amino acids, essential amino acids, branched-chain amino acids, BCAA, EAA, best amino acid supplements, amino acids for muscle growth, amino acids for recovery, amino acid benefits, amino acid comparison, leucine, isoleucine, valine, lysine, tryptophan, methionine, histidine, threonine, phenylalanine, amino acids for energy, amino acids for endurance, amino acid metabolism, amino acid supplements, BCAA vs EAA, amino acids for workout, effective amino acid supplements, affordable BCAA, BCAA powder, EAA powder, amino acid profiles, complete amino acids, muscle repair supplements, amino acids for strength, pre-workout amino acids, post-workout recovery, amino acids for performance, amino acids and hydration"
        />
        <meta property="og:title" content="Amino Acid Ranking Examples" />
        <meta
          property="og:description"
          content="Discover the best value Amino Acids with our ingredient value ranking system. Whether you want Branch Chain, Essential, or specific Amino Acids, our rankings highlight top Amino Acid products based on ingredient value."
        />
      </Helmet>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton to={"/aminoacid"}>
          <LinkSVG />
          <BackText>Amino Acid Filter</BackText>
        </BackButton>
        <LiveIndicator />
      </BackDiv>

      <>
        <ResponseDiv>
          <ResponseTitle>
            <ReactTyped
              strings={["Branch-Chain Amino Acids"]}
              typeSpeed={30}
              showCursor={false}
            />
          </ResponseTitle>
          <ResponseSubtitle
            onClick={() =>
              handlePopUp(
                ["isoleucine", "leucine", "valine"],
                props.ingredients
              )
            }
          >
            <SvgWrapper>
              <IngredientEyeSVG />
            </SvgWrapper>
            {submaker(
              ["isoleucine", "leucine", "valine"],
              [],
              props.ingredients
            )}
          </ResponseSubtitle>
        </ResponseDiv>
        <PodiumDivMobile>
          {!exampleLoading ? (
            tieBreaker(exampleFour).map(
              (item, index) =>
                item && (
                  <BrandListCompact
                    item={item}
                    filterRank={item.currentRank}
                    oldRank={item.oldRank}
                    ticker={true}
                    loading={false}
                    userCountry={props.userCountry}
                    ai={true}
                    key={index}
                    filter={exampleFour}
                    filterLink={searchPageGen(
                      "aminoacid",
                      null,
                      null,
                      null,
                      null,
                      ["isoleucine", "leucine", "valine"],
                      [],
                      [],
                      props.ingredients
                    )}
                    setFilter={props.setAminoFilter}
                    setFilterLink={props.setAminoFilterLink}
                  />
                )
            )
          ) : (
            <>
              <BrandListCompact
                filterRank={1}
                loading={true}
              ></BrandListCompact>
              <BrandListCompact
                filterRank={2}
                loading={true}
              ></BrandListCompact>
              <BrandListCompact
                filterRank={3}
                loading={true}
              ></BrandListCompact>
            </>
          )}
        </PodiumDivMobile>
        <FilterButtonDiv>
          <ShowMoreButton
            to={searchPageGen(
              "aminoacid",
              null,
              null,
              null,
              null,
              ["isoleucine", "leucine", "valine"],
              [],
              [],
              props.ingredients
            )}
          >
            <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
          </ShowMoreButton>
        </FilterButtonDiv>
        <TabBar />
        <ResponseDiv>
          <ResponseTitle>
            <ReactTyped
              strings={["Essential Amino Acids"]}
              typeSpeed={30}
              showCursor={false}
            />
          </ResponseTitle>
          <ResponseSubtitle
            onClick={() =>
              handlePopUp(
                [
                  "histidine",
                  "isoleucine",
                  "leucine",
                  "lysine",
                  "methionine",
                  "phenylalanine",
                  "threonine",
                  "tryptophan",
                  "valine",
                ],
                props.ingredients
              )
            }
          >
            <SvgWrapper>
              <IngredientEyeSVG />
            </SvgWrapper>
            {submaker(
              [
                "histidine",
                "isoleucine",
                "leucine",
                "lysine",
                "methionine",
                "phenylalanine",
                "threonine",
                "tryptophan",
                "valine",
              ],
              [],
              props.ingredients
            )}
          </ResponseSubtitle>
        </ResponseDiv>
        <PodiumDivMobile>
          {!exampleLoading ? (
            tieBreaker(exampleFive).map(
              (item, index) =>
                item && (
                  <BrandListCompact
                    item={item}
                    filterRank={item.currentRank}
                    oldRank={item.oldRank}
                    ticker={true}
                    loading={false}
                    userCountry={props.userCountry}
                    ai={true}
                    key={index}
                    filter={exampleFive}
                    filterLink={searchPageGen(
                      "aminoacid",
                      null,
                      null,
                      null,
                      null,
                      [
                        "histidine",
                        "isoleucine",
                        "leucine",
                        "lysine",
                        "methionine",
                        "phenylalanine",
                        "threonine",
                        "tryptophan",
                        "valine",
                      ],
                      [],
                      [],
                      props.ingredients
                    )}
                    setFilter={props.setAminoFilter}
                    setFilterLink={props.setAminoFilterLink}
                  />
                )
            )
          ) : (
            <>
              <BrandListCompact
                filterRank={1}
                loading={true}
              ></BrandListCompact>
              <BrandListCompact
                filterRank={2}
                loading={true}
              ></BrandListCompact>
              <BrandListCompact
                filterRank={3}
                loading={true}
              ></BrandListCompact>
            </>
          )}
        </PodiumDivMobile>
        <FilterButtonDiv>
          <ShowMoreButton
            to={searchPageGen(
              "aminoacid",
              null,
              null,
              null,
              null,
              [
                "histidine",
                "isoleucine",
                "leucine",
                "lysine",
                "methionine",
                "phenylalanine",
                "threonine",
                "tryptophan",
                "valine",
              ],
              [],
              [],
              props.ingredients
            )}
          >
            <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
          </ShowMoreButton>
        </FilterButtonDiv>
        <TabBar />
        <ResponseDiv>
          <ResponseTitle>
            <ReactTyped
              strings={["BCAA Capsules"]}
              typeSpeed={30}
              showCursor={false}
            />
          </ResponseTitle>
          {/* <ResponseSubtitle>
                {props.userCountry === "GB"
                  ? "Ranked by Caffeine, Citrulline and Glycerol value."
                  : "Ranked by Citrulline, Glycerol and Malic Acid value."}
              </ResponseSubtitle> */}
          <ResponseSubtitle
            onClick={() =>
              handlePopUp(
                ["isoleucine", "leucine", "valine"],
                props.ingredients
              )
            }
          >
            <SvgWrapper>
              <IngredientEyeSVG />
            </SvgWrapper>
            {submaker(
              ["isoleucine", "leucine", "valine"],
              [],
              props.ingredients
            )}
          </ResponseSubtitle>
        </ResponseDiv>
        <PodiumDivMobile>
          {!exampleLoading ? (
            tieBreaker(exampleOne).map(
              (item, index) =>
                item && (
                  <BrandListCompact
                    item={item}
                    filterRank={item.currentRank}
                    oldRank={item.oldRank}
                    ticker={true}
                    loading={false}
                    ai={true}
                    userCountry={props.userCountry}
                    key={index}
                    filter={exampleOne}
                    filterLink={searchPageGen(
                      "aminoacid",
                      "capsule",
                      null,
                      null,
                      null,
                      ["isoleucine", "leucine", "valine"],
                      [],
                      [],
                      props.ingredients
                    )}
                    setFilter={props.setAminoFilter}
                    setFilterLink={props.setAminoFilterLink}
                  />
                )
            )
          ) : (
            <>
              <BrandListCompact
                filterRank={1}
                loading={true}
              ></BrandListCompact>
              <BrandListCompact
                filterRank={2}
                loading={true}
              ></BrandListCompact>
              <BrandListCompact
                filterRank={3}
                loading={true}
              ></BrandListCompact>
            </>
          )}
        </PodiumDivMobile>
        <FilterButtonDiv>
          <ShowMoreButton
            to={searchPageGen(
              "aminoacid",
              "capsule",
              null,
              null,
              null,
              ["isoleucine", "leucine", "valine"],
              [],
              [],
              props.ingredients
            )}
            style={{ marginBottom: "25px" }}
          >
            <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
          </ShowMoreButton>
        </FilterButtonDiv>
      </>
      {/* <LastTabBar /> */}
      <PopUp
        isOpen={isPopupOpen}
        onClose={closePopup}
        ingredients={popupIngredients}
        ingredientList={popupIngredientList}
      />
      <AdPopUp
        canOpen={!isPopupOpen && props.userCountry === "GB"}
        accessories={props.accessories}
      />
      <FanPopUp canOpen={!isPopupOpen} userCountry={props.userCountry} />
    </center>
  );
};

export default AminoExample;
