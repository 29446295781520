import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { LiveIndicator } from "../custom/animations";
import BrandListCompact from "../components/BrandListCompact";
import { trackEvent } from "../custom/analytics";
import { ChartSVG, IngredientEyeSVG } from "../custom/svg";
import {
  Regions,
  filterTieBreaker,
  itemFilter,
  submaker,
  titleMakerAmino,
} from "../custom/functions";
import PopUp from "../components/PopUp";
import IngredientRankPopUp from "../components/IngredientRankPopUp";
import TypePopUp from "../components/TypePopUp";
import BrandListPlaceholder from "../components/BrandListPlaceholder";
import { Helmet } from "react-helmet-async";
import AdPopUp from "../components/AdPopUp";
import {
  PodiumDivMobile,
  SvgWrapper,
  FilterSubtitle,
  Title,
  ListDiv,
  SuggestionDiv,
  Suggestion,
  SuggestionText,
  BackDiv,
  BackButton,
  BackText,
  RankInfo,
  RankText,
} from "../css/fetchPage";
import FanPopUp from "../components/FanPopUp";

const Type = [
  {
    value: "powder",
    label: "Powder",
  },
  {
    value: "capsule",
    label: "Capsules",
  },
  {
    value: "tablet",
    label: "Tablets",
  },
];

const Ingredients = [
  { value: "histidine", label: "Histidine" },
  { value: "isoleucine", label: "Isoleucine" },
  { value: "leucine", label: "Leucine" },
  { value: "lysine", label: "Lysine" },
  { value: "methionine", label: "Methionine" },
  { value: "phenylalanine", label: "Phenylalanine" },
  { value: "threonine", label: "Threonine" },
  { value: "tryptophan", label: "Tryptophan" },
  { value: "valine", label: "Valine" },
];

const SelectCategories = [
  { value: "rank", label: "Rank Ingredients" },
  { value: "type", label: "Type" },
];

function newapiHandler(selectedRank, filter, userCountry, ingredients) {
  let link = "/api/aminoacid?";
  const params = new URLSearchParams();

  selectedRank.forEach((ingredient, index) => {
    params.append(`ingredient${index + 1}`, ingredient);
  });

  if (filter) {
    params.append("filter", filter);
  }

  params.append("region", userCountry);

  link += params.toString();
  // console.log(link);
  return link;
}

const AminoFilter = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [infoActive, setInfoActive] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [regionSelected, setRegion] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isRankPopupOpen, setRankPopupOpen] = useState(false);
  const [isTypePopupOpen, setTypePopupOpen] = useState(false);
  const [originalMax, setOriginalMax] = useState(-Infinity);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  const openRankPopup = () => setRankPopupOpen(true);
  const closeRankPopup = () => setRankPopupOpen(false);

  const openTypePopup = () => setTypePopupOpen(true);
  const closeTypePopup = () => setTypePopupOpen(false);

  const getParamArray = (paramPrefix, rangePrefix) => {
    const ingredientsArray = [];
    const rangeDataArray = [];

    for (let i = 1; i <= 10; i++) {
      const ingredient = searchParams.get(`${paramPrefix}${i}`);
      const range = searchParams.get(`${rangePrefix}${i}`);

      if (ingredient) {
        ingredientsArray.push(ingredient.toLowerCase());

        // Parse the range into min and max
        let min = null;
        let max = null;
        if (range) {
          const [parsedMin, parsedMax] = range.split("-");
          min = parsedMin ? parseInt(parsedMin, 10) : null;
          max = parsedMax ? parseInt(parsedMax, 10) : null;
        }

        // Add the correct ingredient and parsed min-max values
        rangeDataArray.push({
          ingredient: ingredient.toLowerCase(), // Correct ingredient name
          min: min,
          max: max,
          hide: min === null || max === null,
        });
      }
    }

    return { ingredientsArray, rangeDataArray };
  };

  const setParamRangeArray = (
    params,
    paramPrefix,
    rangePrefix,
    ingredientsArray,
    rangeDataArray
  ) => {
    // Remove old parameters with the same prefix
    for (let i = 1; i <= 10; i++) {
      params.delete(`${paramPrefix}${i}`);
      params.delete(`${rangePrefix}${i}`);
    }

    // Add new parameters for ingredients and only add range if hide is false
    ingredientsArray.forEach((ingredient, index) => {
      // Always add the ingredient
      params.set(`${paramPrefix}${index + 1}`, ingredient);

      // Find the corresponding range for this ingredient
      const range = rangeDataArray.find((r) => r.ingredient === ingredient);

      // Add range only if hide is false and both min and max are available
      if (range && !range.hide && range.min !== null && range.max !== null) {
        const rangeValue = `${range.min}-${range.max}`;
        params.set(`${rangePrefix}${index + 1}`, rangeValue);
      }
    });
  };

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [originalItems, setOriginalItems] = useState(null);
  const [finalItems, setFinalItems] = useState([]);
  const [emptySearch, setEmptySearch] = useState(false);
  const [resetting, setResetting] = useState(false);

  const [selectedRankIngredients, setSelectedRankIngredients] = useState(() => {
    const rankIngredients = getParamArray(
      "ingredient",
      "ingredientRange"
    ).ingredientsArray;
    return rankIngredients.length > 0
      ? rankIngredients
      : ["isoleucine", "leucine", "valine"];
  });
  const [rangeData, setRangeData] = useState(() => {
    return getParamArray("ingredient", "ingredientRange").rangeDataArray;
  });
  const [selectedFilter, setSelectedFilter] = useState(
    searchParams.get("filter") || null
  );
  const [ingredientList, setIngredientList] = useState([...Ingredients]);

  const reorderList = (activeItem, list) => {
    const filteredList = list.filter((item) => item.value !== activeItem.value);

    return [activeItem, ...filteredList];
  };

  const removeActive = (activeItem, list, selectedLength) => {
    const filteredList = list.filter((item) => item.value !== activeItem.value);
    filteredList.push(activeItem);

    const firstHalf = filteredList.slice(0, selectedLength - 1);
    const secondHalf = filteredList.slice(selectedLength - 1).sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    });

    return [...firstHalf, ...secondHalf];
  };

  const handleCategoryClick = (item) => {
    setSelectedCategory((prevSelected) => {
      if (item.value === prevSelected) {
        return null;
      } else if (item.value === "rank") {
        openRankPopup();
      } else if (item.value === "type") {
        openTypePopup();
      } else {
        return item.value;
      }
    });
  };

  const handleRegionClick = (item) => {
    // props.setUserCountry(item.value);
    setRegion((prevSelected) => {
      if (prevSelected === item.value) {
        // setTypeList(
        //   removeActive(item, typeList, 1)
        // );
        return prevSelected;
      } else if (prevSelected !== item.value) {
        // setTypeList(reorderList(item, typeList));
        return item.value;
      } else {
        return prevSelected;
      }
    });
  };

  const reorderSelectedItems = (selectedItems, fullList) => {
    // Filter the list to get the selected items first
    const selectedItemsList = fullList.filter((item) =>
      selectedItems.includes(item.value)
    );
    // Filter the list to get the non-selected items
    const nonSelectedItemsList = fullList.filter(
      (item) => !selectedItems.includes(item.value)
    );
    // Concatenate the selected items at the front and non-selected items at the end
    return [...selectedItemsList, ...nonSelectedItemsList];
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        // apiHandler(ingredientOne, ingredientTwo, excludeOne, excludeTwo)
        newapiHandler(
          selectedRankIngredients,
          selectedFilter,
          regionSelected,
          props.ingredients
        )
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      trackEvent("Filter", "Filtered: Amino Acids", "");
      if (result.length > 0) {
        setEmptySearch(false);
        // setMobilePodium([...result.slice(0, 3)]);
        // setList([...result.slice(3, result.length)]);

        const params = new URLSearchParams();
        setParamRangeArray(
          params,
          "ingredient",
          "ingredientRange",
          selectedRankIngredients,
          rangeData
        );
        if (selectedFilter) params.append("filter", selectedFilter);
        if (regionSelected) params.append("region", regionSelected);

        setSearchParams(params, { replace: true });
        setOriginalItems(result);
        props.setAminoFilter([...result]);
        props.setAminoFilterLink("/aminoacid?" + params.toString());

        setIngredientList(
          reorderSelectedItems(selectedRankIngredients, ingredientList)
        );

        if (resetting) {
          setRangeData([
            { ingredient: "isoleucine", min: null, max: null, hide: true },
            { ingredient: "leucine", min: null, max: null, hide: true },
            { ingredient: "valine", min: null, max: null, hide: true },
          ]);
          setResetting(false);
        }

        // console.log(mergedItems);
        setFinalItems(filterTieBreaker(result));
        setLoading(false);
      } else {
        setEmptySearch(true);
      }
    } catch (error) {
      setError(error); // Update the state with the error
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, [props.userCountry]);

  useEffect(() => {
    if (regionSelected === null && props.userCountry) {
      setRegion(searchParams.get("region") || props.userCountry);
    }
  }, [props.userCountry, searchParams]);

  useEffect(() => {
    if (!regionSelected) return;
    // window.scroll(0, 0);
    fetchData();
    // console.log(selectedRankIngredients);
  }, [selectedRankIngredients, selectedFilter, regionSelected]);

  const getStyle = (item, list) => {
    return list.includes(item.value)
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getTextStyle = (item, list) => {
    return list.includes(item.value)
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  const getCategoryStyle = (item, selected) => {
    return item.value === selected
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getCategoryTextStyle = (item, selected) => {
    return item.value === selected
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  const getStyleType = (item, list) => {
    return item.value === list
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getTextStyleType = (item, list) => {
    return item.value === list
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  const infoToggle = () => {
    setInfoActive(!infoActive);
  };

  const Reset = () => {
    setSelectedRankIngredients(["isoleucine", "leucine", "valine"]);
    setSelectedFilter(null);
    setIngredientList([...Ingredients]);
    // setRangeData([{ ingredient: "caffeine", min: 0, max: originalMax }]);
    setResetting(true);
  };

  useEffect(() => {
    if (
      !originalItems ||
      originalItems.length === 0 ||
      !selectedRankIngredients ||
      selectedRankIngredients.length === 0
    ) {
      // console.log("Data not available yet.");
      return; // Exit the effect early
    }

    let newMax = -Infinity;

    originalItems.forEach((item) => {
      const baseValue = parseFloat(
        item[selectedRankIngredients[selectedRankIngredients.length - 1]]
      );

      // console.log(item[selectedRankIngredients[selectedRankIngredients.length - 1]]);

      const ingredientTotal = item.twoscoop ? 2 * baseValue : baseValue;

      if (ingredientTotal > newMax) {
        newMax = ingredientTotal;
      }
    });

    // console.log("Setting originalMax to:", newMax);
    setOriginalMax(newMax);
  }, [originalItems, selectedRankIngredients, selectedFilter]);

  // filters data based on rangedata
  useEffect(() => {
    // console.log("Filter Effect: ", originalItems);
    const params = new URLSearchParams();
    setParamRangeArray(
      params,
      "ingredient",
      "ingredientRange",
      selectedRankIngredients,
      rangeData
    );
    if (selectedFilter) params.append("filter", selectedFilter);
    if (regionSelected) params.append("region", regionSelected);
    setSearchParams(params, { replace: true });

    const filteredItems = itemFilter(originalItems, rangeData);

    setFinalItems(() => {
      if (!originalItems) return []; // Handle the case where originalItems is undefined

      return filteredItems;
    });
    // Set empty search to true if no items are filtered
    if (originalItems) {
      setEmptySearch(filteredItems.length === 0);
    }

    props.setAminoFilter([...filteredItems]);
    props.setAminoFilterLink("/aminoacid?" + params.toString());
  }, [originalItems, rangeData, selectedRankIngredients, selectedFilter]);

  return (
    <center style={{ overflowX: "hidden" }}>
      <Helmet>
        <title>Amino Acid Rankings</title>
        <meta
          name="description"
          content="Discover the best value Amino Acids with our ingredient value ranking system. Whether you want Branch Chain, Essential, or specific Amino Acids, our rankings highlight top Amino Acid products based on ingredient value."
        />
        <meta
          name="keywords"
          content="amino acids, essential amino acids, branched-chain amino acids, BCAA, EAA, best amino acid supplements, amino acids for muscle growth, amino acids for recovery, amino acid benefits, amino acid comparison, leucine, isoleucine, valine, lysine, tryptophan, methionine, histidine, threonine, phenylalanine, amino acids for energy, amino acids for endurance, amino acid metabolism, amino acid supplements, BCAA vs EAA, amino acids for workout, effective amino acid supplements, affordable BCAA, BCAA powder, EAA powder, amino acid profiles, complete amino acids, muscle repair supplements, amino acids for strength, pre-workout amino acids, post-workout recovery, amino acids for performance, amino acids and hydration"
        />
        <meta property="og:title" content="Amino Acid Rankings" />
        <meta
          property="og:description"
          content="Discover the best value Amino Acids with our ingredient value ranking system. Whether you want Branch Chain, Essential, or specific Amino Acids, our rankings highlight top Amino Acid products based on ingredient value."
        />
      </Helmet>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton onClick={infoToggle}>
          <ChartSVG />
          <BackText>Ranking Info {!infoActive ? "+" : "-"}</BackText>
        </BackButton>
        <LiveIndicator />
        {/* </StyledLink>
          <BackText style={{color:"gray"}}>{rank} Place</BackText> */}
      </BackDiv>
      {infoActive && (
        <RankInfo>
          <RankText>
            Products are ranked by Total Selected Ingredient Value. <br />
            Formula: (Total Servings * Ingredient Serving) / Price
          </RankText>
        </RankInfo>
      )}
      <PodiumDivMobile style={{ paddingTop: "0px" }}>
        {!loading ? (
          <>
            {finalItems.slice(0, 3).map((item, index) => {
              if (!item) return null;
              return (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  userCountry={regionSelected}
                  ticker={true}
                  ai={true}
                  key={index}
                  loading={false}
                />
              );
            })}
            {finalItems.length < 3 &&
              Array.from({ length: 3 - finalItems.length }).map((_, i) => (
                <BrandListPlaceholder key={`placeholder-${i}`} index={i} />
              ))}
          </>
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <Title>
        {!emptySearch
          ? titleMakerAmino(selectedFilter, selectedRankIngredients)
          : "No Search Results"}
      </Title>
      <FilterSubtitle onClick={!emptySearch ? openPopup : null}>
        {!emptySearch && (
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
        )}
        {!emptySearch
          ? submaker(selectedRankIngredients, rangeData, props.ingredients)
          : "Oops! Your search didn't return products. Please try again."}
      </FilterSubtitle>
      <SuggestionDiv style={{ paddingBottom: "10px" }}>
        {/* <SuggestionText>Type:</SuggestionText> */}
        {Regions.map(
          (item) =>
            item && (
              <Suggestion
                key={item.value}
                onClick={() => handleRegionClick(item)}
                style={getStyle(item, regionSelected || "")}
              >
                <SuggestionText
                  style={getTextStyle(item, regionSelected || "")}
                >
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      <SuggestionDiv
        style={{
          paddingTop: "5px",
          marginBottom: "30px",
        }}
      >
        {SelectCategories.map(
          (item) =>
            item &&
            !item.hide && (
              <Suggestion
                key={item.value}
                onClick={() => handleCategoryClick(item)}
                style={getCategoryStyle(item, selectedCategory)}
              >
                <SuggestionText
                  style={getCategoryTextStyle(item, selectedCategory)}
                >
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      <ListDiv>
        {!loading ? (
          finalItems.slice(3).map((item, index) => {
            if (!item) return null;
            return (
              <BrandListCompact
                item={item}
                filterRank={item.currentRank}
                oldRank={item.oldRank}
                userCountry={regionSelected}
                ticker={true}
                ai={true}
                key={index}
                loading={false}
              />
            );
          })
        ) : (
          <>
            <BrandListCompact filterRank={4} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={5} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={6} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={7} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={8} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={9} loading={true}></BrandListCompact>
          </>
        )}
      </ListDiv>
      <PopUp
        isOpen={isPopupOpen}
        onClose={closePopup}
        ingredients={selectedRankIngredients}
        ingredientList={props.ingredients}
      />
      <IngredientRankPopUp
        category="aminoacid"
        isOpen={isRankPopupOpen}
        onClose={closeRankPopup}
        selectedRankIngredients={selectedRankIngredients}
        setSelectedRankIngredients={setSelectedRankIngredients}
        ingredientList={ingredientList}
        setIngredientList={setIngredientList}
        selectedExcludeIngredients={[]}
        removeActive={removeActive}
        reorderList={reorderList}
        getStyle={getStyle}
        getTextStyle={getTextStyle}
        responseLength={finalItems.length}
        loading={loading}
        emptySearch={emptySearch}
        Reset={Reset}
        rangeData={rangeData}
        setRangeData={setRangeData}
        originalMax={originalMax}
        originalItems={originalItems}
        ingredients={props.ingredients}
      />
      <TypePopUp
        isOpen={isTypePopupOpen}
        onClose={closeTypePopup}
        selectedRankIngredients={[]}
        setSelectedRankIngredients={[]}
        selectedExcludeIngredients={selectedFilter}
        setSelectedExcludeIngredients={setSelectedFilter}
        excludeList={Type}
        removeActive={removeActive}
        getStyle={getStyleType}
        getTextStyle={getTextStyleType}
        responseLength={finalItems.length}
        loading={loading}
        emptySearch={emptySearch}
        Reset={Reset}
      />
      <AdPopUp
        canOpen={
          !isPopupOpen &&
          !isRankPopupOpen &&
          !isTypePopupOpen &&
          props.userCountry === "GB"
        }
        accessories={props.accessories}
      />
      <FanPopUp
        canOpen={!isPopupOpen && !isRankPopupOpen && !isTypePopupOpen}
        userCountry={props.userCountry}
      />
      {/* <StyledReactPaginate
          pageCount={totalPages}
          onPageChange={handlePageChange}
          previousLabel={"Prev"}
        /> */}
    </center>
  );
};

export default AminoFilter;
