import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { LiveIndicator } from "../custom/animations";
import BrandListCompact from "../components/BrandListCompact";
import { trackEvent } from "../custom/analytics";
import { ChartSVG, IngredientEyeSVG } from "../custom/svg";
import {
  Regions,
  filterTieBreaker,
  subMakerProtein,
  titleMakerProteinBar,
} from "../custom/functions";
import PopUp from "../components/PopUp";
import { Helmet } from "react-helmet-async";
import AdPopUp from "../components/AdPopUp";
import TypePopUp from "../components/TypePopUp";
import {
  PodiumDivMobile,
  SvgWrapper,
  FilterSubtitle,
  Title,
  ListDiv,
  SuggestionDiv,
  Suggestion,
  SuggestionText,
  BackDiv,
  BackButton,
  BackText,
  RankInfo,
  RankText,
} from "../css/fetchPage";

const Ranks = [
  {
    value: "lowcal",
    label: "Lowest Calories",
  },
  {
    value: "highcal",
    label: "Highest Calories",
  },
];

const Diets = [
  {
    value: "glutenfree",
    label: "Gluten Free",
  },
  // {
  //   value: "whey",
  //   label: "Whey",
  // },
  {
    value: "vegan",
    label: "Vegan",
  },
];

const Types = [
  {
    value: "casein-whey-blend",
    label: "Casein/Whey",
  },
  {
    value: "collagen-whey-blend",
    label: "Collagen/Whey",
  },
  {
    value: "milk",
    label: "Milk",
  },
  {
    value: "milk-whey-blend",
    label: "Milk/Whey",
  },
  {
    value: "pea",
    label: "Pea",
  },
  {
    value: "pea-milk-blend",
    label: "Pea/Milk",
  },
  {
    value: "pea-whey-blend",
    label: "Pea/Whey",
  },
  {
    value: "peanut",
    label: "Peanut",
  },
  {
    value: "rice-pea-blend",
    label: "Rice/Pea",
  },
  {
    value: "rice-seed-blend",
    label: "Rice/Seed",
  },
  {
    value: "soy",
    label: "Soy",
  },
  {
    value: "soy-pea-blend",
    label: "Soy/Pea",
  },
  {
    value: "soy-wheat-blend",
    label: "Soy/Wheat",
  },
  {
    value: "soy-whey-blend",
    label: "Soy/Whey",
  },
  {
    value: "wheat-milk-blend",
    label: "Wheat/Milk",
  },
  {
    value: "whey",
    label: "Whey",
  },
];

const SelectCategories = [
  { value: "protein", label: "Protein Type" },
  { value: "diet", label: "Diet Type" },
];

function newapiHandler(filter, rank, type, userCountry) {
  let link = "/api/proteinbar?";
  const params = new URLSearchParams();

  if (filter) {
    params.append("filter", filter);
  }

  if (rank) {
    params.append("rank", rank);
  }

  // TODO
  if (type) {
    params.append("type", type);
  }

  params.append("region", userCountry);

  link += params.toString();

  // console.log(link);

  return link;
}

const ProteinBarFilter = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [dietSelected, setDiet] = useState(searchParams.get("filter") || null);
  const [typeSelected, setType] = useState(searchParams.get("type") || null);
  const [rankSelection, setRankSelection] = useState(
    searchParams.get("rank") || null
  );
  const [regionSelected, setRegion] = useState(null);
  const [isTypePopupOpen, setTypePopupOpen] = useState(false);
  const [isDietPopupOpen, setDietPopupOpen] = useState(false);
  const [infoActive, setInfoActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState(
    titleMakerProteinBar(dietSelected, typeSelected)
  );
  const [filterSubtitle, setFilterSubtitle] = useState(
    subMakerProtein(rankSelection)
  );
  const [finalItems, setFinalItems] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  const openTypePopup = () => setTypePopupOpen(true);
  const closeTypePopup = () => setTypePopupOpen(false);

  const openDietPopup = () => setDietPopupOpen(true);
  const closeDietPopup = () => setDietPopupOpen(false);

  const [resetting, setResetting] = useState(false);
  const [emptySearch, setEmptySearch] = useState(false);

  const handleRegionClick = (item) => {
    // props.setUserCountry(item.value);
    setRegion((prevSelected) => {
      if (prevSelected === item.value) {
        // setTypeList(
        //   removeActive(item, typeList, 1)
        // );
        return prevSelected;
      } else if (prevSelected !== item.value) {
        // setTypeList(reorderList(item, typeList));
        return item.value;
      } else {
        return prevSelected;
      }
    });
  };

  const handleRankClick = (item) => {
    setRankSelection((prevSelected) => {
      if (prevSelected === item.value) {
        // setTypeList(
        //   removeActive(item, typeList, 1)
        // );
        return null;
      } else if (prevSelected !== item.value) {
        // setTypeList(reorderList(item, typeList));
        return item.value;
      } else {
        return prevSelected;
      }
    });
  };

  const handleCategoryClick = (item) => {
    setSelectedCategory((prevSelected) => {
      if (item.value === prevSelected) {
        return null;
      } else if (item.value === "protein") {
        openTypePopup();
      } else if (item.value === "diet") {
        openDietPopup();
      } else {
        return item.value;
      }
    });
  };

  const Reset = () => {
    // setSelectedRankIngredients(["potassium", "sodium"]);
    setRankSelection(null);
    setType(null);
    setDiet(null);
    // setSelectedFilter(null);
    // setIngredientList([...Ingredients]);
    // setRangeData([{ ingredient: "caffeine", min: 0, max: originalMax }]);
    setResetting(true);
  };

  const removeActive = (activeItem, list, selectedLength) => {
    const filteredList = list.filter((item) => item.value !== activeItem.value);
    filteredList.push(activeItem);

    const firstHalf = filteredList.slice(0, selectedLength - 1);
    const secondHalf = filteredList.slice(selectedLength - 1).sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    });

    return [...firstHalf, ...secondHalf];
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        newapiHandler(dietSelected, rankSelection, typeSelected, regionSelected)
      );
      if (!response.ok) {
        setLoading(true);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      trackEvent(
        "Filter",
        "Filtered: Protein Bars",
        (rankSelection ? rankSelection + " " : "") +
          (typeSelected ? typeSelected : "")
      );
      if (result.length > 0) {
        setEmptySearch(false);

        // setMobilePodium([...result.slice(0, 3)]);
        // setList([...result.slice(3, result.length)]);
        // setData([...result]);
        setTitle(titleMakerProteinBar(dietSelected, typeSelected));
        setFilterSubtitle(subMakerProtein(rankSelection));

        const params = new URLSearchParams();

        if (typeSelected) params.append("type", typeSelected);
        if (dietSelected) params.append("filter", dietSelected);
        if (rankSelection) params.append("rank", rankSelection);
        if (regionSelected) params.append("region", regionSelected);

        setSearchParams(params, { replace: true });

        props.setProteinBarFilter([...result]);
        props.setProteinBarFilterLink("/proteinbar?" + params.toString());

        if (resetting) {
          // setRangeData([
          //   { ingredient: "potassium", min: null, max: null, hide: true },
          //   { ingredient: "sodium", min: null, max: null, hide: true },
          // ]);
          setResetting(false);
        }

        // console.log(mergedItems);
        setFinalItems(filterTieBreaker(result));
        setLoading(false);
      } else {
        setEmptySearch(true);
      }
    } catch (error) {
      setError(error); // Update the state with the error
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, [props.userCountry]);

  useEffect(() => {
    const regionParam = searchParams.get("region");

    if (regionParam || regionSelected === null) {
      setRegion(regionParam || props.userCountry);
    }
  }, [props.userCountry, searchParams]);

  useEffect(() => {
    if (!regionSelected) return;
    // window.scroll(0, 0);
    fetchData();
  }, [dietSelected, typeSelected, rankSelection, regionSelected]);

  const getStyle = (item, list) => {
    return item.value === list
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getTextStyle = (item, list) => {
    return item.value === list
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };
  const getCategoryStyle = (item, selected) => {
    return item.value === selected
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getCategoryTextStyle = (item, selected) => {
    return item.value === selected
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };
  const getStyleType = (item, list) => {
    return item.value === list
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getTextStyleType = (item, list) => {
    return item.value === list
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };
  const infoToggle = () => {
    setInfoActive(!infoActive);
  };

  return (
    <center style={{ overflowX: "hidden" }}>
      <Helmet>
        <title>Protein Bar Rankings</title>
        <meta
          name="description"
          content="Discover the best value Protein Bars with our protein value ranking system. Whether you want a low calorie, gluten free, or vegan formula, our rankings highlight top Protein Bars based on protein value."
        />
        <meta
          name="keywords"
          content="best protein bars, protein bar rankings, protein bar supplement, protein bar value, high calorie protein bar, low calorie protein bar, top protein bars, effective protein bar ingredients, affordable protein bar, workout supplements, protein bar comparison, ingredient-based protein bar, ingredient value protein bar, protein bar ingredient definitions"
        />
        <meta property="og:title" content="Protein Powder Rankings" />
        <meta
          property="og:description"
          content="Discover the best value Protein Bars with our protein value ranking system. Whether you want a low calorie, gluten free, or vegan formula, our rankings highlight top Protein Bars based on protein value."
        />
      </Helmet>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton onClick={infoToggle}>
          <ChartSVG />
          <BackText>Ranking Info {!infoActive ? "+" : "-"}</BackText>
        </BackButton>
        <LiveIndicator />
        {/* </StyledLink>
          <BackText style={{color:"gray"}}>{rank} Place</BackText> */}
      </BackDiv>
      {infoActive && (
        <RankInfo>
          <RankText>
            Products are ranked by Total Protein Value.
            <br />
            Formula: (Total Servings * Protein Serving) / Price
          </RankText>
        </RankInfo>
      )}
      <PodiumDivMobile style={{ paddingTop: "0px" }}>
        {!loading ? (
          finalItems.slice(0, 3).map((item, index) => {
            if (!item) return null;
            return (
              <BrandListCompact
                item={item}
                filterRank={item.currentRank}
                oldRank={item.oldRank}
                userCountry={regionSelected}
                ticker={true}
                ai={true}
                key={index}
                loading={false}
              />
            );
          })
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <Title>{!emptySearch ? title : "No Search Results"}</Title>
      <FilterSubtitle onClick={openPopup}>
        {!emptySearch && (
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
        )}
        {!emptySearch
          ? filterSubtitle
          : "Oops! Your search didn't return products. Please try again."}
      </FilterSubtitle>
      <SuggestionDiv
        style={{ paddingBottom: "10px" }}
      >
        {/* <SuggestionText>Type:</SuggestionText> */}
        {Regions.map(
          (item) =>
            item && (
              <Suggestion
                key={item.value}
                onClick={() => handleRegionClick(item)}
                style={getStyle(item, regionSelected || "")}
              >
                <SuggestionText
                  style={getTextStyle(item, regionSelected || "")}
                >
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      <SuggestionDiv style={{ paddingTop: "5px", paddingBottom: "10px" }}>
        {/* <SuggestionText>Rank:</SuggestionText> */}
        {Ranks.map(
          (item) =>
            item && (
              <Suggestion
                key={item.value}
                onClick={() => handleRankClick(item)}
                style={getStyle(item, rankSelection)}
              >
                <SuggestionText style={getTextStyle(item, rankSelection)}>
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      <SuggestionDiv
        style={{
          paddingTop: "5px",
          marginBottom: "30px",
        }}
      >
        {SelectCategories.map(
          (item) =>
            item &&
            !item.hide && (
              <Suggestion
                key={item.value}
                onClick={() => handleCategoryClick(item)}
                style={getCategoryStyle(item, selectedCategory)}
              >
                <SuggestionText
                  style={getCategoryTextStyle(item, selectedCategory)}
                >
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      <ListDiv>
        {!loading ? (
          finalItems.slice(3).map((item, index) => {
            if (!item) return null;
            return (
              <BrandListCompact
                item={item}
                filterRank={item.currentRank}
                oldRank={item.oldRank}
                userCountry={regionSelected}
                ticker={true}
                ai={true}
                key={index}
                loading={false}
              />
            );
          })
        ) : (
          <>
            <BrandListCompact filterRank={4} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={5} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={6} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={7} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={8} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={9} loading={true}></BrandListCompact>
          </>
        )}
      </ListDiv>
      {!dietSelected ? (
        <PopUp
          isOpen={isPopupOpen}
          onClose={closePopup}
          ingredients={
            rankSelection
              ? ["calories", "protein"]
              : typeSelected === "whey"
              ? ["whey"]
              : ["protein"]
          }
          ingredientList={props.ingredients}
        />
      ) : (
        <PopUp
          isOpen={isPopupOpen}
          onClose={closePopup}
          ingredients={
            rankSelection ? ["calories", dietSelected] : [dietSelected]
          }
          ingredientList={props.ingredients}
        />
      )}
      <TypePopUp
        isOpen={isTypePopupOpen}
        onClose={closeTypePopup}
        title={"Protein Type"}
        selectedRankIngredients={[]}
        setSelectedRankIngredients={[]}
        selectedExcludeIngredients={typeSelected}
        setSelectedExcludeIngredients={setType}
        excludeList={Types}
        removeActive={removeActive}
        getStyle={getStyleType}
        getTextStyle={getTextStyleType}
        responseLength={finalItems.length}
        loading={loading}
        emptySearch={emptySearch}
        Reset={Reset}
      />
      <TypePopUp
        isOpen={isDietPopupOpen}
        onClose={closeDietPopup}
        title={"Diet Type"}
        selectedRankIngredients={[]}
        setSelectedRankIngredients={[]}
        selectedExcludeIngredients={dietSelected}
        setSelectedExcludeIngredients={setDiet}
        excludeList={Diets}
        removeActive={removeActive}
        getStyle={getStyleType}
        getTextStyle={getTextStyleType}
        responseLength={finalItems.length}
        loading={loading}
        emptySearch={emptySearch}
        Reset={Reset}
      />
      <AdPopUp canOpen={!isPopupOpen} accessories={props.accessories} />
      {/* <StyledReactPaginate
        pageCount={totalPages}
        onPageChange={handlePageChange}
        previousLabel={"Prev"}
      /> */}
    </center>
  );
};

export default ProteinBarFilter;
