import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { trackEvent } from "../custom/analytics";
import { LeftArrowSVG, PlusSVG, RightArrowSVG } from "../custom/svg";
import { CircularProgress } from "@mui/material";
import ShopNowButton from "../components/ShopNowButton";
import { compareLink } from "../custom/functions";
import { Helmet } from "react-helmet-async";
import {
  StyledLink,
  UnderlinedSpan,
  ProductDiv,
  Disclaimer,
  Table,
  SizeDiv,
  FlavorButton,
  FlavorText,
  ArrowDiv,
  RankButton,
  BackDiv,
  BackButton,
  BackText,
  TableIngredient,
  TableValue,
  TableDefinition,
  TableButtonDiv,
  TableButton,
  CompareButton,
} from "../css/productPage";
import ProductPageCard from "../components/ProductPageCard";

const NutritionTable = (props) => {
  const [selectedIngredient, setSelectedIngredient] = useState(null);

  const handleIngredientClick = (ingredient) => {
    if (ingredient === selectedIngredient) {
      return setSelectedIngredient(null);
    }
    setSelectedIngredient(ingredient);
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>
            <span>Nutrition: </span>
          </th>
          <th>
            <span>Amount: </span>
          </th>
          {/* <th>
              <span>Category Rank: </span>
            </th> */}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <span>Servings</span>
          </td>
          <td>
            <span>{props.servings}</span>
          </td>
        </tr>
        <>
          <tr>
            <TableIngredient>
              <UnderlinedSpan onClick={() => handleIngredientClick(props.type)}>
                Creatine ({props.type})
              </UnderlinedSpan>
            </TableIngredient>
            <TableValue>
              <span>{props.servingSize}g</span>
            </TableValue>
          </tr>
          {selectedIngredient && selectedIngredient === "Monohydrate" && (
            <tr>
              <td colSpan="2" style={{ border: "none" }}>
                <TableDefinition>
                  {
                    props.ingredients.find(
                      (item) => item.value === "monohydrate"
                    ).definition
                  }
                </TableDefinition>
              </td>
            </tr>
          )}
          {selectedIngredient &&
            selectedIngredient === "Micronized Monohydrate" && (
              <tr>
                <td colSpan="2" style={{ border: "none" }}>
                  <TableDefinition>
                    {
                      props.ingredients.find(
                        (item) => item.value === "micronized"
                      ).definition
                    }
                  </TableDefinition>
                </td>
              </tr>
            )}
          {selectedIngredient && selectedIngredient === "HCl" && (
            <tr>
              <td colSpan="2" style={{ border: "none" }}>
                <TableDefinition>
                  {
                    props.ingredients.find((item) => item.value === "hcl")
                      .definition
                  }
                </TableDefinition>
              </td>
            </tr>
          )}
        </>
      </tbody>
    </Table>
  );
};

const CreatineProduct = (props) => {
  let sign = "$";

  const { brand, type, flavor, servings, region, patent } = useParams();

  // converting param to boolean
  const prodPatent = patent === "true";

  const [productGroup, setProductGroup] = useState({
    category: null,
    brand: null,
    subtitle: null,
    type: null,
    region: null,
    patent: null,
  });
  const [productLoading, setProductLoading] = useState(true);
  const [productData, setProductData] = useState([]);
  let subtitle;

  if (region === "GB") {
    sign = "£";
  }

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    // console.log("here");
    if (brand && type && region) {
      setProductGroup({
        category: "creatine",
        brand: brand,
        subtitle: null,
        type: type,
        region: region,
        patent: prodPatent,
      });
    }
  }, [brand, type, region, prodPatent]);

  const constructQueryParams = (params) => {
    if (typeof params !== "object" || Array.isArray(params)) {
      throw new Error("Params should be an object with key-value pairs.");
    }

    let query = "/api/product-group?";
    for (const [key, value] of Object.entries(params)) {
      // console.log(`Processing key: ${key}, value: ${value}`); // Debugging line
      if (value !== null && value !== "") {
        query += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
      }
    }

    return query.endsWith("&") ? query.slice(0, -1) : query;
  };

  useEffect(() => {
    if (
      productGroup.category &&
      productGroup.brand &&
      productGroup.type &&
      productGroup.region
    ) {
      setProductLoading(true);
      fetch(constructQueryParams(productGroup))
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setProductData(data);
          setProductLoading(false);
        })
        .catch((error) => {
          console.error("Error caught:", error);
          setProductLoading(false);
        });
    }
  }, [productGroup]);

  if (productLoading) {
    return (
      <center style={{ paddingTop: "150px" }}>
        <CircularProgress color="inherit" />
      </center>
    );
  }

  const prod = productData.find(
    (obj) =>
      obj.brand === brand &&
      obj.type === type &&
      obj.flavor === flavor &&
      obj.servings === servings &&
      obj.patent === prodPatent
  );

  if (!prod) {
    return (
      <center style={{ paddingTop: "150px" }}>
        <CircularProgress color="inherit" />
      </center>
    );
  }

  // const flavors = productData.filter(
  //   (obj) =>
  //     obj.brand === brand &&
  //     obj.type === type &&
  //     obj.servings === servings &&
  //     obj.stock === true
  // );

  const sizes = productData.filter(
    (obj) =>
      obj.brand === brand &&
      obj.type === type &&
      obj.flavor === flavor &&
      obj.stock === true &&
      obj.patent === prodPatent
  );
  const sizeCount = [
    ...new Set(
      productData.filter((obj) => obj.stock === true).map((obj) => obj.servings)
    ),
  ].length;

  let backColor = "";
  let textColor = "";
  const rank =
    props.creatineFilter.findIndex(
      (i) =>
        i.brand === prod.brand &&
        i.type === prod.type &&
        i.patent === prodPatent
    ) + 1;

  if (prod.type === "Micronized Monohydrate") {
    subtitle = "Micronized Creatine Monohydrate";
  } else {
    subtitle = "Creatine " + prod.type;
  }

  if (rank === 1) {
    backColor = "#d4af37";
    textColor = "white";
  } else if (rank === 2) {
    backColor = "#C0C0C0";
    textColor = "white";
  } else if (rank === 3) {
    backColor = "#CD7F32";
    textColor = "white";
  } else {
    backColor = "#efefef";
    textColor = "black";
  }

  // green: #00a650
  // yreen: #cadb2a
  // red: #ed1b24

  const getStyle = (bool) => {
    return bool
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getTextStyle = (bool) => {
    return bool
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  const getPrev = () => {
    if (rank === 0 || rank === 1) {
      return null;
    }

    const link =
      "/creatine/" +
      props.creatineFilter[rank - 2].brand +
      "/" +
      props.creatineFilter[rank - 2].type +
      "/" +
      props.creatineFilter[rank - 2].flavor +
      "/" +
      props.creatineFilter[rank - 2].servings +
      "/" +
      props.creatineFilter[rank - 2].region +
      "/" +
      props.creatineFilter[rank - 2].patent;

    return link;
  };

  const getNext = () => {
    if (rank === 0 || rank === props.creatineFilter.length) {
      return null;
    }

    const link =
      "/creatine/" +
      props.creatineFilter[rank].brand +
      "/" +
      props.creatineFilter[rank].type +
      "/" +
      props.creatineFilter[rank].flavor +
      "/" +
      props.creatineFilter[rank].servings +
      "/" +
      props.creatineFilter[rank].region +
      "/" +
      props.creatineFilter[rank].patent;

    return link;
  };

  return (
    <center>
      <Helmet>
        <title>{prod.brand + " - " + subtitle}</title>
        <meta
          name="description"
          content={
            "View or compare " +
            prod.brand +
            "'s " +
            subtitle +
            " to it's competitors."
          }
        />
        <meta
          name="keywords"
          content="best creatine, creatine rankings, creatine supplement, creatine value, creatine monohydrate, creatine hydrochloride, creatine hcl, micronized creatine, top creatine, effective creatine, affordable creatine, workout supplements, creatine comparison, ingredient-based creatine, value creatine, creatine definition"
        />
        <meta property="og:title" content={prod.brand + " - " + subtitle} />
        <meta
          property="og:description"
          content={
            "View or compare " +
            prod.brand +
            "'s " +
            subtitle +
            " to it's competitors."
          }
        />
      </Helmet>
      {rank > 0 && (
        <BackDiv>
          <StyledLink to={props.creatineFilterLink} method="get">
            <BackButton type="submit">
              <LeftArrowSVG />
              <BackText>Full Ranking Page</BackText>
            </BackButton>
          </StyledLink>
          {/* <BackText style={{color:"gray"}}>{rank} Place</BackText> */}
        </BackDiv>
      )}
      <ProductDiv backActive={rank > 0}>
        <ProductPageCard prod={prod} color={backColor} />
        {sizeCount > 1 && (
          <SizeDiv>
            {sizes.map(
              (item) =>
                item && (
                  <StyledLink
                    to={
                      "/creatine/" +
                      item.brand +
                      "/" +
                      item.type +
                      "/" +
                      item.flavor +
                      "/" +
                      item.servings +
                      "/" +
                      item.region +
                      "/" +
                      item.patent
                    }
                    method="get"
                    key={item.brand + " " + item.flavor + " " + item.servings}
                  >
                    <FlavorButton
                      type="submit"
                      style={getStyle(item.servings === prod.servings)}
                    >
                      <FlavorText
                        style={getTextStyle(item.servings === prod.servings)}
                      >
                        {item.servings} Servings
                      </FlavorText>
                    </FlavorButton>
                  </StyledLink>
                )
            )}
          </SizeDiv>
        )}
        <ArrowDiv>
          <StyledLink
            onClick={() => rank - 1 > 0 && setProductLoading(true)}
            to={getPrev()}
            method="get"
          >
            <RankButton type="submit">
              <LeftArrowSVG color={rank - 1 > 0 ? "black" : "white"} />
            </RankButton>
          </StyledLink>
          <Disclaimer style={{ marginTop: "5px" }}>
            {prod.shoplink &&
              !prod.shoplink.includes("amzn") &&
              !prod.shoplink.includes("amazon") &&
              !prod.shoplink.includes("lvnta") &&
              "*If price isn't accurate at checkout, use code SUPPHEAD."}
            {prod.shoplink &&
              !prod.shoplink.includes("amzn") &&
              !prod.shoplink.includes("amazon") &&
              !prod.shoplink.includes("lvnta") && <br />}
            These rankings are based on Nutritional statistics alone. <br />{" "}
            They have no consideration for flavor of product.
          </Disclaimer>
          <StyledLink
            onClick={() =>
              rank < props.creatineFilter.length && setProductLoading(true)
            }
            to={getNext()}
            method="get"
          >
            <RankButton type="submit">
              <RightArrowSVG
                color={rank === props.creatineFilter.length ? "white" : "black"}
              />
            </RankButton>
          </StyledLink>
        </ArrowDiv>
        <div style={{ padding: "10px 0px", width: "100%" }}>
          <TableButtonDiv>
            <TableButton>
              <PlusSVG />
              <CompareButton to={compareLink(prod)}>Compare</CompareButton>
            </TableButton>
          </TableButtonDiv>
          <NutritionTable
            servingSize={prod.servingsize}
            servings={prod.servings}
            type={prod.type}
            creapure={prod.creapure}
            pharmapure={prod.pharmapure}
            concret={prod.concret}
            creasyn={prod.creasyn}
            ingredients={props.ingredients}
          />
        </div>
        <Disclaimer>
          These statements have not been evaluated by the Food and Drug
          Administration. These products are not intended to diagnose, treat,
          cure or prevent any disease.
        </Disclaimer>
        {prod.shoplink && (
          <ShopNowButton
            color={backColor === "#efefef" ? "#2e2e2e" : backColor}
            textColor={"white"}
            price={sign + prod.price}
            shoplink={prod.shoplink}
            navBarOpen={props.navBarOpen}
          />
        )}
      </ProductDiv>
    </center>
  );
};

export default CreatineProduct;
