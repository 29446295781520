import React from "react";
import { useParams } from "react-router-dom";
import { trackEvent } from "../custom/analytics";
import { compareLink } from "../custom/functions";
import { RightArrowSVG, LeftArrowSVG, PlusSVG } from "../custom/svg";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import ShopNowButton from "../components/ShopNowButton";
import { Helmet } from "react-helmet-async";
import {
  StyledLink,
  UnderlinedSpan,
  ProductDiv,
  Disclaimer,
  Table,
  SizeDiv,
  FlavorDiv,
  FlavorButton,
  FlavorText,
  ArrowDiv,
  RankButton,
  BackDiv,
  BackButton,
  BackText,
  TableIngredient,
  TableValue,
  TableDefinition,
  TableButtonDiv,
  TableButton,
  ScoopButton,
  CompareButton,
} from "../css/productPage";
import ProductPageCard from "../components/ProductPageCard";

const NutritionTable = (props) => {
  const [selectedIngredient, setSelectedIngredient] = useState(null);

  const handleIngredientClick = (ingredient) => {
    if (ingredient === selectedIngredient) {
      return setSelectedIngredient(null);
    }
    setSelectedIngredient(ingredient);
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>
            <span>Nutrition: </span>
          </th>
          <th>
            <span>Amount: </span>
          </th>
          {/* <th>
                <span>Category Rank: </span>
              </th> */}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <span>Servings</span>
          </td>
          <td>
            <span>{props.prod.servings}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>Serving Size</span>
          </td>
          <td>
            <span>
              {props.prod.servingsize}
              {props.prod.servingsize === "1" ? " can" : " can"}
            </span>
          </td>
        </tr>
        {props.ingredients.map((ingredient) => {
          const { value, label, unit, definition } = ingredient;
          if (props.prod[value] !== null && props.prod[value] !== "0") {
            return (
              <React.Fragment key={value}>
                <tr key={value}>
                  <TableIngredient>
                    {definition ? (
                      <UnderlinedSpan
                        onClick={() => handleIngredientClick(ingredient)}
                      >
                        {label}
                      </UnderlinedSpan>
                    ) : (
                      <span>{label}</span>
                    )}
                  </TableIngredient>
                  <TableValue>
                    <span>
                      {props.prod[value]}
                      {unit}
                    </span>
                  </TableValue>
                </tr>
                {selectedIngredient && selectedIngredient.value === value && (
                  <tr>
                    <td colSpan="2" style={{ border: "none" }}>
                      <TableDefinition>{definition}</TableDefinition>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          }
          return null;
        })}
      </tbody>
    </Table>
  );
};

const EnergyDrinkProduct = (props) => {
  let sign = "$";

  const { brand, subtitle, flavor, servings, region } = useParams();
  const [productGroup, setProductGroup] = useState({
    category: null,
    brand: null,
    subtitle: null,
    type: null,
    region: null,
  });
  const [productLoading, setProductLoading] = useState(true);
  const [productData, setProductData] = useState([]);

  if (region === "GB") {
    sign = "£";
  }

  // console.log(preIngredients.length)

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (brand && subtitle && region) {
      setProductGroup({
        category: "energydrink",
        brand: brand,
        subtitle: subtitle,
        type: null,
        region: region,
      });
    }
  }, [brand, subtitle, region]);

  const constructQueryParams = (params) => {
    if (typeof params !== "object" || Array.isArray(params)) {
      throw new Error("Params should be an object with key-value pairs.");
    }

    let query = "/api/product-group?";
    for (const [key, value] of Object.entries(params)) {
      // console.log(`Processing key: ${key}, value: ${value}`); // Debugging line
      if (value !== null && value !== "") {
        query += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
      }
    }

    return query.endsWith("&") ? query.slice(0, -1) : query;
  };

  useEffect(() => {
    if (
      productGroup.category &&
      productGroup.brand &&
      productGroup.subtitle &&
      productGroup.region
    ) {
      setProductLoading(true);
      fetch(constructQueryParams(productGroup))
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setProductData(data);
          setProductLoading(false);
        })
        .catch((error) => {
          console.error("Error caught:", error);
          setProductLoading(false);
        });
    }
  }, [productGroup]);

  if (productLoading) {
    return (
      <center style={{ paddingTop: "150px" }}>
        <CircularProgress color="inherit" />
      </center>
    );
  }

  const prod = productData.find(
    (obj) =>
      obj.brand === brand &&
      obj.subtitle === subtitle &&
      obj.flavor === flavor &&
      obj.servings === servings
  );

  if (!prod) {
    return (
      <center style={{ paddingTop: "150px" }}>
        <CircularProgress color="inherit" />
      </center>
    );
  }

  const flavors = productData.filter(
    (obj) =>
      obj.brand === brand &&
      obj.subtitle === subtitle &&
      obj.servings === servings &&
      obj.stock === true
  );
  const sizes = productData.filter(
    (obj) =>
      obj.brand === brand &&
      obj.subtitle === subtitle &&
      obj.flavor === flavor &&
      obj.stock === true
  );
  const sizeCount = [
    ...new Set(
      productData
        .filter(
          (obj) =>
            obj.stock === true
        )
        .map((obj) => obj.servings)
    ),
  ].length;

  let backColor = "";
  let textColor = "";
  let previousValueRank = null;
  let adjustedRank = 1;
  let rank = 0;

  const originalIndex = props.energyDrinkFilter.findIndex(
    (i) => i.brand === prod.brand && i.subtitle === prod.subtitle
  );

  for (let i = 0; i <= originalIndex; i++) {
    if (
      previousValueRank !== null &&
      props.energyDrinkFilter[i].value_rank === previousValueRank
    ) {
      adjustedRank--;
    }
    if (i === originalIndex) {
      rank = adjustedRank;
    }
    previousValueRank = props.energyDrinkFilter[i].value_rank;
    adjustedRank++;
  }

  if (rank === 1) {
    backColor = "#d4af37";
    textColor = "white";
  } else if (rank === 2) {
    backColor = "#C0C0C0";
    textColor = "white";
  } else if (rank === 3) {
    backColor = "#CD7F32";
    textColor = "white";
  } else {
    backColor = "#efefef";
    textColor = "black";
  }

  // green: #00a650
  // yreen: #cadb2a
  // red: #ed1b24

  const getStyle = (bool) => {
    return bool
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getTextStyle = (bool) => {
    return bool
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  const getPrev = () => {
    if (originalIndex <= 0) {
      return null;
    }

    const prevProduct = props.energyDrinkFilter[originalIndex - 1];
    const link =
      "/energydrink/" +
      prevProduct.brand +
      "/" +
      prevProduct.subtitle +
      "/" +
      prevProduct.flavor +
      "/" +
      prevProduct.servings +
      "/" +
      prevProduct.region;

    return link;
  };

  const getNext = () => {
    if (originalIndex >= props.energyDrinkFilter.length - 1) {
      return null;
    }

    const nextProduct = props.energyDrinkFilter[originalIndex + 1];
    const link =
      "/energydrink/" +
      nextProduct.brand +
      "/" +
      nextProduct.subtitle +
      "/" +
      nextProduct.flavor +
      "/" +
      nextProduct.servings +
      "/" +
      nextProduct.region;

    return link;
  };

  // console.log(props.preFilter)

  return (
    <center>
      <Helmet>
        <title>{prod.brand + " - " + prod.subtitle}</title>
        <meta
          name="description"
          content={
            "View or compare " +
            prod.brand +
            "'s " +
            prod.subtitle +
            " to it's competitors."
          }
        />
        <meta
          name="keywords"
          content="best energy drink, energy drink rankings, energy drink supplement, energy drink value, high stim energy drink, low stim energy drink, pump formula, nootropic energy drink, caffeine free energy drink, top energy drink, effective energy drink ingredients, affordable energy drink, energy drink for energy, energy drink for pump, workout supplements, energy drink comparison, ingredient-based energy drink, ingredient value energy drink, energy drink filter, energy drink ingredient definitions"
        />
        <meta
          property="og:title"
          content={prod.brand + " - " + prod.subtitle}
        />
        <meta
          property="og:description"
          content={
            "View or compare " +
            prod.brand +
            "'s " +
            prod.subtitle +
            " to it's competitors."
          }
        />
      </Helmet>
      {rank > 0 && (
        <BackDiv>
          <StyledLink to={props.energyDrinkFilterLink} method="get">
            <BackButton type="submit">
              <LeftArrowSVG />
              <BackText>Full Ranking Page</BackText>
            </BackButton>
          </StyledLink>
          {/* <BackText style={{color:"gray"}}>{rank} Place</BackText> */}
        </BackDiv>
      )}
      <ProductDiv backActive={rank > 0}>
        <ProductPageCard prod={prod} color={backColor} />
        {sizeCount > 1 && (
          <SizeDiv>
          {sizes.map(
            (item) =>
              item && (
                <StyledLink
                  to={
                    "/energydrink/" +
                    item.brand +
                    "/" +
                    item.subtitle +
                    "/" +
                    item.flavor +
                    "/" +
                    item.servings +
                    "/" +
                    item.region
                  }
                  method="get"
                  key={item.servings}
                >
                  <FlavorButton
                    type="submit"
                    style={getStyle(item.servings === prod.servings)}
                  >
                    <FlavorText
                      style={getTextStyle(item.servings === prod.servings)}
                    >
                      {item.twoscoop
                        ? parseFloat(item.servings) / 2 + "/" + item.servings
                        : item.servings}{" "}
                      Servings
                    </FlavorText>
                  </FlavorButton>
                </StyledLink>
              )
          )}
        </SizeDiv>
        )}
        {/* <SubTitle>{subtitle}</SubTitle> */}
        <FlavorDiv>
            {flavors.map(
              (item) =>
                item && (
                  <StyledLink
                    to={
                      "/energydrink/" +
                      item.brand +
                      "/" +
                      item.subtitle +
                      "/" +
                      item.flavor +
                      "/" +
                      item.servings +
                      "/" +
                      item.region
                    }
                    method="get"
                    key={item.flavor}
                  >
                    <FlavorButton
                      type="submit"
                      style={getStyle(item.flavor === prod.flavor)}
                    >
                      <FlavorText
                        style={getTextStyle(item.flavor === prod.flavor)}
                      >
                        {item.flavor}
                      </FlavorText>
                    </FlavorButton>
                  </StyledLink>
                )
            )}
          </FlavorDiv>
        <ArrowDiv>
          <StyledLink
            onClick={() => originalIndex > 0 && setProductLoading(true)}
            to={getPrev()}
            method="get"
          >
            <RankButton type="submit">
              <LeftArrowSVG color={originalIndex > 0 ? "black" : "white"} />
            </RankButton>
          </StyledLink>
          <Disclaimer style={{ marginTop: "5px" }}>
            {prod.shoplink &&
              !prod.shoplink.includes("amzn") &&
              !prod.shoplink.includes("amazon") &&
              !prod.shoplink.includes("lvnta") &&
              "*If price isn't accurate at checkout, use code SUPPHEAD."}
            {prod.shoplink &&
              !prod.shoplink.includes("amzn") &&
              !prod.shoplink.includes("amazon") &&
              !prod.shoplink.includes("lvnta") && <br />}
            These rankings are based on Nutritional statistics alone. <br />{" "}
            They have no consideration for flavor of product.
          </Disclaimer>
          <StyledLink
            onClick={() =>
              originalIndex + 1 < props.energyDrinkFilter.length &&
              setProductLoading(true)
            }
            to={getNext()}
            method="get"
          >
            <RankButton type="submit">
              <RightArrowSVG
                color={
                  originalIndex + 1 === props.energyDrinkFilter.length
                    ? "white"
                    : "black"
                }
              />
            </RankButton>
          </StyledLink>
        </ArrowDiv>
        <div style={{ padding: "10px 0px", width: "100%" }}>
          <TableButtonDiv>
            <TableButton>
              <PlusSVG />
              <CompareButton to={compareLink(prod)}>Compare</CompareButton>
            </TableButton>
          </TableButtonDiv>
          <NutritionTable prod={prod} ingredients={props.ingredients} />
        </div>
        <Disclaimer>
          These statements have not been evaluated by the Food and Drug
          Administration. These products are not intended to diagnose, treat,
          cure or prevent any disease.
        </Disclaimer>
        {prod.shoplink && (
          <ShopNowButton
            color={backColor === "#efefef" ? "#2e2e2e" : backColor}
            textColor={"white"}
            price={sign + prod.price}
            shoplink={prod.shoplink}
            navBarOpen={props.navBarOpen}
          />
        )}
      </ProductDiv>
    </center>
  );
};

export default EnergyDrinkProduct;
