import React, { useState, useEffect } from "react";
import styled from "styled-components";
import EmailInput from "../components/EmailInput";
import PasswordInput from "../components/PasswordInput";
import Cookies from "js-cookie";
import SignUpInput from "../components/SignUpInput";
import { useNavigate } from "react-router-dom";
import { trackEvent } from "../custom/analytics";

const LoginDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // background: gray;
`;
const AccountTitle = styled.p`
  font-size: 1.2rem;
  font-family: San Francisco Bold;
  margin-bottom: 0px;
  padding: 0px;
  margin-top: 2rem;
  // text-align: left;
`;
const AccountSubtitle = styled.p`
  font-family: San Francisco Regular;
  // padding-top: 1rem;
  font-size: 16px;
  color: gray;
  margin: 10px 0px;
  padding: 0px;
`;
const LoginTitle = styled.p`
  font-size: 1.5rem;
  font-family: San Francisco Bold;
  margin-bottom: 0px;
  padding: 0px;
  margin-top: 2.5rem;
  // text-align: left;
`;
const LoginSubtitle = styled.button`
  all: unset;
  font-family: San Francisco Regular;
  padding-top: 1rem;
  color: gray;
  margin: 10px 0px;
  padding: 0px;
  text-decoration: underline;
  cursor: pointer;
`;

const LoginButton = styled.button`
  font-family: San Francisco Bold;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 10px 20px;
  font-size: 16px;
  width 120px;
  height: 40px;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  background-color: #efefef;
  transition-duration: 0.25s;
  color: black;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;

const ResponseText = styled.p`
  font-family: San Francisco Bold;
  font-size: 1rem;
  padding: 20px;
`;

const InputErrorText = styled.p`
  font-family: San Francisco Regular;
  font-size: 0.8rem;
  padding: 5px 10%;
  color: gray;
`;

const TabBar = styled.div`
  display: block;
  height: 3px;
  width: 30%;
  border-radius: 50px;
  background-color: #efefef;
  margin-top: 20px;
  margin-bottom: 0px;

  @media (max-width: 760px) {
    width: 85%;
  }
`;

const AccountStatus = styled.p`
  font-family: San Francisco Bold;
  font-size: 14px;
  margin: 5px 0px 0px 0px;
`;

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

const Account = (props) => {
  const [signUp, setSignUp] = useState(false);
  const [login, setLogin] = useState(!props.isLoggedIn);
  const [reset, setReset] = useState(false);
  const [verified, setVerified] = useState(props.isVerified);
  const [account, setAccount] = useState(props.userEmail);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loginSuccess, setLoginSuccess] = useState("");
  const [signUpError, setSignUpError] = useState("");
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [emailResendSuccess, setEmailResendSuccess] = useState(false);
  const [emailResendError, setEmailResendError] = useState(false);
  const [fnameError, setFnameError] = useState("");
  const [lnameError, setLnameError] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isFirstValid, setFirstValid] = useState(false);
  const [isLastValid, setLastValid] = useState(false);
  const [isCodeValid, setCodeValid] = useState(false);
  const [resetSend, setResetSend] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  const [resetError, setResetError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // check if user is logged in
    // console.log("isLoggedin: " + props.isLoggedIn);
    // console.log("verified: " + props.isVerified);
    // console.log("email: " + props.userEmail);
    // console.log("fname: " + props.usefname);

    // if (!props.isLoggedIn) {
    //   handleLogout();
    // }

    setLogin(!props.isLoggedIn);
    setVerified(props.isVerified);
    setFname(props.userfname);
    setAccount(props.userEmail);
  }, [props.isLoggedIn, props.isVerified, props.userEmail, props.userfname]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError("");
    setIsEmailValid(validateEmail(event.target.value));
    setSignUpSuccess(false);
    setLoginSuccess(false);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError("");
    setIsPasswordValid(validatePassword(event.target.value));
    setSignUpSuccess(false);
    setLoginSuccess(false);
  };

  const handleFirstChange = (event) => {
    setFname(event.target.value);
    setFnameError("");
    setFirstValid(validateFirst(event.target.value));
    setSignUpSuccess(false);
    setLoginSuccess(false);
  };

  const handleLastChange = (event) => {
    setLname(event.target.value);
    setLnameError("");
    setLastValid(validateLast(event.target.value));
    setSignUpSuccess(false);
    setLoginSuccess(false);
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value);
    setCodeError("");
    setCodeValid(validateCode(event.target.value));
    setSignUpSuccess(false);
    setLoginSuccess(false);
  };

  const validateEmail = (email) => {
    const regex = new RegExp(
      '^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|' +
        '(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])' +
        "|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$"
    );
    return regex.test(email);
  };

  const validatePassword = (password) => {
    const regex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?\":{}|<>\\[\\]\\\\;'/`~_+=-])(?=.{8,})"
    );
    return regex.test(password);
  };

  const validateFirst = (first) => {
    const regex = new RegExp("^[a-zA-Z]{1,49}$");
    return regex.test(first);
  };

  const validateLast = (last) => {
    const regex = new RegExp("^[a-zA-Z]{1,49}$");
    return regex.test(last);
  };

  const validateCode = (code) => {
    const regex = new RegExp("^[0-9a-f]{6}$");
    return regex.test(code);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    let isValid = true;

    if (!validateEmail(email)) {
      setEmailError("Email must have '@' and '.com'.");
      isValid = false;
    }

    if (!validatePassword(password)) {
      setPasswordError(
        "Password must have one lowercase, one uppercase, one number, one special character, and be 8 or more characters."
      );
      isValid = false;
    }

    // if (!validateFirst(fname)) {
    //   setFnameError("Invalid First Name");
    //   isValid = false;
    // }

    // if (!validateLast(fname)) {
    //   setFnameError("Invalid First Name");
    //   isValid = false;
    // }

    if (isValid) {
      // Handle login logic here
      // console.log("Email:", email);
      // console.log("Password:", password);
      // Example: Send data to the server
      // If login fails, set the login error message
      // setLoginError("Incorrect email or password");

      try {
        const response = await fetch("/api/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          trackEvent("User", "Login")
          // Handle successful response
          setLoginSuccess(true);
          setVerified(data.verified);
          props.setVerified(data.verified);
          props.setUserEmail(data.email);
          props.setUserfname(data.fname);
          props.setLoggedIn(true);
          // setSignUp(false);
          setLogin(false);
          setReset(false);
          setAccount(true);
          setFname("");
          setLname("");
          setEmail("");
          setPassword("");
          setEmailError("");
          setPasswordError("");
          setLoginError("");
          setFnameError("");
          setLnameError("");
          setIsEmailValid(false);
          setIsPasswordValid(false);
          setFirstValid(false);
          setLastValid(false);
          setResetSuccess(false);

          if (data.verified) {
            await timeout(250);
            navigate("/");
          }
        } else {
          // Handle server errors
          const errorData = await response.json();

          setLoginError(errorData.error || "Login failed");
          setLoginSuccess(false);
        }
      } catch (error) {
        console.error("Error during login:", error);
        setLoginError("An unexpected error occurred");
        setLoginSuccess(false);
      }
    }
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    let isValid = true;

    if (!validateEmail(email)) {
      setEmailError("Email must have '@' and '.com'.");
      isValid = false;
    }

    if (!validatePassword(password)) {
      setPasswordError(
        "Password must have one lowercase, one uppercase, one number, one special character, and be 8 or more characters."
      );
      isValid = false;
    }

    if (!validateFirst(fname)) {
      setFnameError("First name must have letters only.");
      isValid = false;
    }

    if (!validateLast(lname)) {
      setLnameError("Last name must have letters only.");
      isValid = false;
    }

    if (isValid) {
      // Handle login logic here
      // console.log("Email:", email);
      // console.log("Password:", password);

      // Example: Send data to the server
      // If login fails, set the login error message
      // setLoginError("Incorrect email or password");
      if (isValid) {
        try {
          const response = await fetch("/api/signup", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: email,
              password: password,
              fname: fname,
              lname: lname,
            }),
          });

          if (response.ok) {
            const data = await response.json();
            trackEvent("User", "Sign Up")
            // Handle successful response
            handleLogin(event);
            setSignUpSuccess(true);
            setSignUp(false);
            setLogin(true);
            setReset(false);
            setFname("");
            setLname("");
            setEmail("");
            setPassword("");
            setEmailError("");
            setPasswordError("");
            setSignUpError("");
            setLoginError("");
            setFnameError("");
            setLnameError("");
            setIsEmailValid(false);
            setIsPasswordValid(false);
            setFirstValid(false);
            setLastValid(false);
            setResetSuccess(false);
          } else {
            // Handle server errors
            const errorData = await response.json();

            setSignUpError(errorData.error || "Sign up failed");
            setSignUpSuccess(false);
          }
        } catch (error) {
          console.error("Error during sign up:", error);
          setSignUpError("An unexpected error occurred");
          setSignUpSuccess(false);
        }
      }
    }
  };

  const handleVerification = async (event) => {
    event.preventDefault();
    let isValid = true;

    if (!validateCode(code)) {
      setCodeError(
        "Verification code must be exactly 6 characters long and contain only lowercase letters (a-f) and numbers (0-9)."
      );
      isValid = false;
    }

    if (isValid) {
      // Handle login logic here
      // console.log("Email:", email);
      // console.log("Password:", password);

      // Example: Send data to the server
      // If login fails, set the login error message
      // setLoginError("Incorrect email or password");
      try {
        const response = await fetch("/api/verify", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: account,
            code: code,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          trackEvent("User", "Verification")
          // Handle successful response
          setVerificationSuccess(true);
          props.setVerified(true);
          setEmailResendSuccess(false);
          setSignUp(false);
          setVerified(true);
          setCode("");
          setCodeError("");
          setCodeValid(false);
          await timeout(250);
          navigate("/");
        } else {
          // Handle server errors
          const errorData = await response.json();

          setCodeError(errorData.error || "Verification failed");
          setVerificationSuccess(false);
        }
      } catch (error) {
        console.error("Error during verification:", error);
        setCodeError("An unexpected error occurred");
        setVerificationSuccess(false);
      }
    }
  };

  const handleResend = async (event) => {
    event.preventDefault();
    let isValid = true;

    if (account) {
      // setCodeError(
      //   "Verification code must be exactly 6 characters long and contain only lowercase letters (a-f) and numbers (0-9)."
      // );
      isValid = false;
    }

    if (isValid) {
      // Handle login logic here
      // console.log("Email:", email);
      // console.log("Password:", password);

      // Example: Send data to the server
      // If login fails, set the login error message
      // setLoginError("Incorrect email or password");
      try {
        const response = await fetch("/api/verify/resend", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: account,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          // Handle successful response
          setEmailResendSuccess(true);
          setEmailResendError("");
        } else {
          // Handle server errors
          const errorData = await response.json();

          setEmailResendError(
            errorData.error || "Verification email resend failed"
          );
          setEmailResendSuccess(false);
        }
      } catch (error) {
        console.error("Error during verification email resend:", error);
        setEmailResendError("An unexpected error occurred");
        setEmailResendSuccess(false);
      }
    }
  };

  const handleResetSend = async (event) => {
    event.preventDefault();
    let isValid = true;

    if (!props.isLoggedIn && !validateEmail(email)) {
      setEmailError("Email must have '@' and '.com'.");
      isValid = false;
    }

    if (isValid) {
      // Handle login logic here
      // console.log("Email:", email);
      // console.log("Password:", password);

      // Example: Send data to the server
      // If login fails, set the login error message
      // setLoginError("Incorrect email or password");
      try {
        const response = await fetch("/api/reset/send", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: props.isLoggedIn ? props.userEmail : email,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          // Handle successful response
          setResetSend(true);
          setResetError("");
        } else {
          // Handle server errors
          const errorData = await response.json();

          setResetError(
            errorData.error || "Password reset email resend failed"
          );
          setResetSend(false);
        }
      } catch (error) {
        console.error("Error during password reset resend:", error);
        setResetError("An unexpected error occurred");
        setResetSend(false);
      }
    }
  };

  const handleReset = async (event) => {
    event.preventDefault();
    let isValid = true;

    if (!validateCode(code)) {
      setCodeError(
        "Verification code must be exactly 6 characters long and contain only lowercase letters (a-f) and numbers (0-9)."
      );
      isValid = false;
    }

    if (!validatePassword(password)) {
      setPasswordError(
        "Password must have one lowercase, one uppercase, one number, one special character, and be 8 or more characters."
      );
      isValid = false;
    }

    if (isValid) {
      // Handle login logic here
      // console.log("Email:", email);
      // console.log("Password:", password);

      // Example: Send data to the server
      // If login fails, set the login error message
      // setLoginError("Incorrect email or password");
      try {
        const response = await fetch("/api/reset", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: props.isLoggedIn ? props.userEmail : email,
            code: code,
            newPassword: password,
          }),
        }); // UPDATE AFTER THIS

        if (response.ok) {
          const data = await response.json();
          trackEvent("User", "Password Reset")
          // Handle successful response
          setEmail("");
          setCode("");
          setPassword("");
          setResetError("");
          if (!props.isLoggedIn) {
            setLogin(true);
          }
          setResetSend(false);
          setReset(false);
          setResetSuccess(true);
          setIsEmailValid(false);
          setIsPasswordValid(false);
          setCodeValid(false);

          // if logged in just clear some data and go to account page
          // if not logged in go to login page. auto login?
        } else {
          // Handle server errors
          const errorData = await response.json();

          setResetError(errorData.error || "Password reset failed");
        }
      } catch (error) {
        console.error("Error during password reset:", error);
        setResetError("An unexpected error occurred");
        setResetSend(false);
      }
    }
  };

  const handleSwitchLogin = () => {
    setSignUp(false);
    setLogin(true);
    setReset(false);
    setFname("");
    setLname("");
    setEmail("");
    setPassword("");
    setEmailError("");
    setPasswordError("");
    setFnameError("");
    setLnameError("");
    setLoginError("");
    setSignUpError("");
    setFirstValid(false);
    setLastValid(false);
    setIsEmailValid(false);
    setIsPasswordValid(false);
    setLoginSuccess(false);
    setSignUpSuccess(false);
  };

  const handleSwitchSignUp = () => {
    setSignUp(true);
    setLogin(false);
    setReset(false);
    setFname("");
    setLname("");
    setEmail("");
    setPassword("");
    setEmailError("");
    setPasswordError("");
    setFnameError("");
    setLnameError("");
    setLoginError("");
    setSignUpError("");
    setFirstValid(false);
    setLastValid(false);
    setIsEmailValid(false);
    setIsPasswordValid(false);
    setLoginSuccess(false);
    setSignUpSuccess(false);
  };

  const handleSwitchReset = () => {
    setSignUp(false);
    setLogin(false);
    setReset(true);
    setFname("");
    setLname("");
    setEmail("");
    setPassword("");
    setEmailError("");
    setPasswordError("");
    setFnameError("");
    setLnameError("");
    setLoginError("");
    setSignUpError("");
    setFirstValid(false);
    setLastValid(false);
    setIsEmailValid(false);
    setIsPasswordValid(false);
    setLoginSuccess(false);
    setSignUpSuccess(false);
  };

  const handleLogout = async () => {
    try {
      const response = await fetch("/api/logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        trackEvent("User", "Logout")
        // Handle successful response
        // console.log(data)
      } else {
        // Handle server errors
        const errorData = await response.json();

        console.log(errorData.error || "Logout failed");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }

    props.setLoggedIn(false);
    props.setVerified(false);
    setSignUp(false);
    setLogin(true);
    setReset(false);
    setAccount(false);
    setVerified(false);
    setFname("");
    setLname("");
    setEmail("");
    setPassword("");
    setEmailError("");
    setPasswordError("");
    setFnameError("");
    setLnameError("");
    setLoginError("");
    setSignUpError("");
    setFirstValid(false);
    setLastValid(false);
    setIsEmailValid(false);
    setIsPasswordValid(false);
    setLoginSuccess(false);
    setSignUpSuccess(false);
  };

  return (
    <center>
      <LoginDiv>
        {login && !loginSuccess && (
          <>
            <LoginTitle>Login</LoginTitle>
            <LoginSubtitle onClick={handleSwitchSignUp}>
              Don't have an account?
            </LoginSubtitle>
            <form onSubmit={handleLogin} noValidate>
              <EmailInput
                value={email}
                onChange={handleEmailChange}
                isValid={isEmailValid}
              />
              {emailError && <InputErrorText>{emailError}</InputErrorText>}
              <PasswordInput
                value={password}
                onChange={handlePasswordChange}
                isValid={isPasswordValid}
                placeholder={"Password"}
                autoComplete={"current-password"}
              />
              {passwordError && (
                <InputErrorText>{passwordError}</InputErrorText>
              )}
              <LoginButton type="submit">Login</LoginButton>
              {loginError && (
                <ResponseText style={{ color: "#f04949" }}>
                  {loginError}
                </ResponseText>
              )}
              {resetSuccess && (
                <ResponseText style={{ color: "#00a650" }}>
                  Password Successfully Reset!
                </ResponseText>
              )}
            </form>{" "}
            <LoginSubtitle
              style={{ fontSize: "14px" }}
              onClick={handleSwitchReset}
            >
              Reset Password
            </LoginSubtitle>
          </>
        )}
        {signUp && (
          <>
            <LoginTitle>Sign Up</LoginTitle>
            <LoginSubtitle onClick={handleSwitchLogin}>
              Back to Login
            </LoginSubtitle>
            <form onSubmit={handleSignUp} noValidate>
              <SignUpInput
                value={fname}
                onChange={handleFirstChange}
                isValid={isFirstValid}
                placeholder={"First name"}
                autoComplete={"given-name"}
              />
              {fnameError && <InputErrorText>{fnameError}</InputErrorText>}
              <SignUpInput
                value={lname}
                onChange={handleLastChange}
                isValid={isLastValid}
                placeholder={"Last name"}
                autoComplete={"family-name"}
              />
              {lnameError && <InputErrorText>{lnameError}</InputErrorText>}
              <EmailInput
                value={email}
                onChange={handleEmailChange}
                isValid={isEmailValid}
              />
              {emailError && <InputErrorText>{emailError}</InputErrorText>}
              <PasswordInput
                value={password}
                onChange={handlePasswordChange}
                isValid={isPasswordValid}
                placeholder={"Password"}
                autoComplete={"new-password"}
              />
              {passwordError && (
                <InputErrorText>{passwordError}</InputErrorText>
              )}
              <LoginButton type="submit">Sign Up</LoginButton>
              {signUpError && (
                <ResponseText style={{ color: "#f04949" }}>
                  {signUpError}
                </ResponseText>
              )}
            </form>{" "}
          </>
        )}
        {reset && (
          <>
            <LoginTitle>Reset Password</LoginTitle>
            <LoginSubtitle onClick={handleSwitchLogin}>
              Back to Login
            </LoginSubtitle>
            {!resetSend && (
              <form onSubmit={handleResetSend} noValidate>
                <EmailInput
                  value={email}
                  onChange={handleEmailChange}
                  isValid={isEmailValid}
                />
                {emailError && <InputErrorText>{emailError}</InputErrorText>}
                <LoginButton type="submit">Reset</LoginButton>
                {resetSend && (
                  <ResponseText style={{ color: "#00a650" }}>
                    Password Reset email sent! (Check Junk)
                  </ResponseText>
                )}
                {resetError && (
                  <ResponseText style={{ color: "#f04949" }}>
                    {resetError}
                  </ResponseText>
                )}
              </form>
            )}
            {resetSend && (
              <form onSubmit={handleReset} noValidate>
                <PasswordInput
                  value={code}
                  onChange={handleCodeChange}
                  isValid={isCodeValid}
                  placeholder={"Code"}
                />
                <PasswordInput
                  value={password}
                  onChange={handlePasswordChange}
                  isValid={isPasswordValid}
                  placeholder={"New Password"}
                  autoComplete={"new-password"}
                />
                {passwordError && (
                  <InputErrorText>{passwordError}</InputErrorText>
                )}
                <LoginButton type="submit">Reset</LoginButton>
                {resetError && (
                  <ResponseText style={{ color: "#f04949" }}>
                    {resetError}
                  </ResponseText>
                )}
              </form>
            )}
          </>
        )}
        {props.isLoggedIn && (
          <>
            <LoginTitle>Manage Account</LoginTitle>
            <AccountSubtitle>
              Hello, {props.userfname} ({props.userEmail})
            </AccountSubtitle>
            {verified ? (
              <AccountStatus style={{ color: "#00a650" }}>
                Verified
              </AccountStatus>
            ) : (
              <AccountStatus style={{ color: "#f04949" }}>
                Unverified
              </AccountStatus>
            )}

            {!verified && (
              <>
                {" "}
                <TabBar />
                <AccountTitle>Verify Account</AccountTitle>
                <AccountSubtitle style={{ fontSize: "14px" }}>
                  Required for SUPPHEAD Advanced Search
                </AccountSubtitle>
                <LoginSubtitle
                  style={{ margin: "0px", fontSize: "14px" }}
                  onClick={handleResend}
                >
                  Resend Email
                </LoginSubtitle>
                <form onSubmit={handleVerification} noValidate>
                  <PasswordInput
                    value={code}
                    onChange={handleCodeChange}
                    isValid={isCodeValid}
                    placeholder={"Code"}
                  />
                  <LoginButton type="submit">Verify</LoginButton>
                  {emailResendError && (
                    <ResponseText style={{ color: "#f04949" }}>
                      {emailResendError}
                    </ResponseText>
                  )}
                  {(emailResendSuccess || signUpSuccess) && account && (
                    <>
                      <ResponseText
                        style={{ color: "#00a650", fontSize: "1rem" }}
                      >
                        Verification Email Sent! (Check Junk)
                      </ResponseText>
                    </>
                  )}
                </form>{" "}
              </>
            )}

            <TabBar />
            <AccountTitle>Reset Password</AccountTitle>
            {resetSuccess && (
              <ResponseText style={{ color: "#00a650", padding: "0px" }}>
                Password Successfully Reset!
              </ResponseText>
            )}
            {resetSend && (
              <ResponseText style={{ color: "#00a650", padding: "0px" }}>
                Password Reset email sent! (Check Junk)
              </ResponseText>
            )}
            {!resetSend && (
              <form onSubmit={handleResetSend} noValidate>
                <LoginButton type="submit">Reset</LoginButton>
              </form>
            )}
            {resetSend && (
              <form onSubmit={handleReset} noValidate>
                <PasswordInput
                  value={code}
                  onChange={handleCodeChange}
                  isValid={isCodeValid}
                  placeholder={"Code"}
                />
                <PasswordInput
                  value={password}
                  onChange={handlePasswordChange}
                  isValid={isPasswordValid}
                  placeholder={"New Password"}
                  autoComplete={"new-password"}
                />
                {passwordError && (
                  <InputErrorText>{passwordError}</InputErrorText>
                )}
                <LoginButton type="submit">Reset</LoginButton>
                {resetError && (
                  <ResponseText style={{ color: "#f04949" }}>
                    {resetError}
                  </ResponseText>
                )}
              </form>
            )}
            <TabBar />
            <form
              style={{ paddingBottom: "20px" }}
              onSubmit={handleLogout}
              noValidate
            >
              <LoginButton type="submit">Logout</LoginButton>
            </form>
          </>
        )}
        {signUpSuccess && !account && (
          <ResponseText style={{ color: "#00a650" }}>
            Sign Up Success!
          </ResponseText>
        )}
        {loginSuccess && !account && (
          <>
            <ResponseText style={{ color: "#00a650", fontSize: "1rem" }}>
              Login Success! Redirecting...
            </ResponseText>
          </>
        )}
        {verificationSuccess && account && (
          <>
            <ResponseText style={{ color: "#00a650", fontSize: "1rem" }}>
              Verification Success! Redirecting...
            </ResponseText>
          </>
        )}
        {/* <ResponseText>Cookie: {getLoginCookie()}</ResponseText> */}
      </LoginDiv>
    </center>
  );
};

export default Account;
