import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Article from "../pages/Article";
import { GradientAnimation } from "../custom/animations";
import defaultproduct from "../images/defaultproduct.png";
import { ExternalSVG } from "../custom/svg";

const ProductCompareBackground = styled(Link)`
  display: block;
  text-decoration: none;
  color: black;
  width: 95%;
  height: 100px;
  border-radius: 15px;
  //   padding-top: 5px;
  position: static;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  background: linear-gradient(
    135deg,
    #efefef 0%,
    #efefef 45%,
    white 50%,
    #efefef 55%,
    #efefef 100%
  );
  background-size: 400% 400%;
  //   animation: ${GradientAnimation} 10s ease infinite;
  cursor: pointer;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 10px gray;
    }
  }
`;
const ProductCompareFront = styled.div`
  height: 90px;
  background-color: white;
  border-radius: 10px;
  position: static;
  margin: 5px;
`;
const ProductCompareOuterDiv = styled.div`
  display: flex;
  flex-direction: row;
  //   background: gray;
`;
const ProductCompareTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90px;
  width: 67%;
  //   margin-left: 0px;
  //   background: yellow;
`;
const CartDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ProductCompareBrand = styled.p`
  font-family: San Francisco Bold;
  font-size: 1rem;
  padding-left: 10px;
  line-height: 1.3rem;
  text-align: left;
  margin: 0px 0px;
  margin-bottom: 3px;
  line-height: 1.3rem;
`;
const ProductCompareFlavor = styled.p`
  font-family: San Francisco Regular;
  font-size: 0.9rem;
  text-align: left;
  color: gray;
  padding-left: 10px;
  margin: 0px 0px;
`;
const ProductImgDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  height: 90px;
  // background: yellow;
`;
const TestProductImgDiv = styled.div`
  display: flex;
  justify-content: center;
  // align-items: center;
  margin: 10px auto;
  width: 60px;
  //   background: yellow;
`;
const ProductImg = styled.img`
  display: block;
  height: 60px;
`;

const ArticleCard = ({ article }) => {
  return (
    <ProductCompareBackground to={"/article/" + article.id}>
      <ProductCompareFront>
        <ProductCompareOuterDiv>
          <ProductImgDiv>
            <TestProductImgDiv>
              <ProductImg
                src={article.imglink || defaultproduct}
                alt={'"' + article.title + '" article thumbnail'}
              />
            </TestProductImgDiv>
            {/* <ProductImgTwoDiv>
                <ProductImg src={itemTwo ? itemTwo.imglink : defaultproduct} />
              </ProductImgTwoDiv> */}
          </ProductImgDiv>
          <ProductCompareTitleDiv>
            <div>
              <ProductCompareBrand>{article.title}</ProductCompareBrand>
              <ProductCompareFlavor>{article.subtitle}</ProductCompareFlavor>
            </div>
            {/* <ProductCompareVS>vs</ProductCompareVS>
              <div style={{ marginBottom: "13px" }}>
                <ProductCompareBrand>
                  {itemTwo ? itemTwo.brand : "Product Two"}
                </ProductCompareBrand>
                <ProductCompareFlavor>
                  {itemTwo ? compareSubmaker(itemTwo) : "Select from search."}
                </ProductCompareFlavor>
              </div> */}
          </ProductCompareTitleDiv>
          {/* {item && (
              <CartDiv>
                <ExternalSVG color={"black"} />
              </CartDiv>
            )} */}
        </ProductCompareOuterDiv>
      </ProductCompareFront>
    </ProductCompareBackground>
  );
};

export default ArticleCard;
